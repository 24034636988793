import React, { useEffect } from 'react'
import { CheckPicker, Tooltip, Whisper } from 'rsuite'
import SpinnerIcon from '@rsuite/icons/legacy/Spinner'

export const TabDropdown = ({
  name = 'Formula',
  options = [],
  onChange,
  newTabData,
  onClose,
  onRemove,
}) => {
  const [items, setItems] = React.useState([])
  const [selected, setSelected] = React.useState([])
  const [removed, setRemoved] = React.useState([])
  const [selectedFormulas, setSelectedFormuls] = React.useState([])

  useEffect(() => {
    setItems(options)
  }, [options])

  const handleClose = () => {
    var allFormulaHerbs = []
    var allFormulas = []
    var selectedHerbIds = []
    selected.map((formulaId) => {
      const thisFormula = options.find((item) => item.id == formulaId)
      if (thisFormula) {
        var allHerbs = thisFormula.all_herbs
        if (allHerbs != null && allHerbs.length != 0) {
          allHerbs.map((ing) => {
            selectedHerbIds.push(ing.herb_id)
            ing['formula_id'] = formulaId
            ing['formula_name'] = thisFormula.name
            allFormulaHerbs.push(ing)
          })
        }
        allFormulas.push(thisFormula)
      }
    })
    if (allFormulas.length != 0) {
      //onClose(selected, allFormulaHerbs)
      onClose(allFormulas, allFormulaHerbs)
    }
    ///////////////
    var allRemovedFormulaHerbs = []
    var removedHerbIds = []
    removed.map((formulaId2) => {
      const thisFormula = options.find((item) => item.id == formulaId2)
      if (thisFormula && allFormulas.includes(formulaId2) == false) {
        var allHerbs = thisFormula.all_herbs
        if (allHerbs != null && allHerbs.length != 0) {
          allHerbs.map((ing) => {
            console.log('ing', ing)
            removedHerbIds.push(ing.herb_id)
            ing['formula_id'] = thisFormula.id
            ing['formula_name'] = thisFormula.name
            allRemovedFormulaHerbs.push(ing)
          })
        }
      }
    })
    if (allRemovedFormulaHerbs.length != 0) {
      //console.log('removeFormulaIngredients', selected, allRemovedFormulaHerbs)
      onRemove(selected, allRemovedFormulaHerbs)
      setRemoved([])
    }
  }

  const disableItems = []
  const datat = Array.isArray(options)
    ? options.map((item) => ({
        label: (
          <>
            {item.name}{' '}
            {item.total_herb != 0 && (
              <span title={item.total_herb + ' Ingredients'}>
                ( {item.total_herb} Ingredients )
              </span>
            )}
          </>
        ),
        value: item.id,
        name: item.name,
      }))
    : []
  const sortBySelected = (a, b) => {
    const isASelected = selected.includes(a.id)
    const isBSelected = selected.includes(b.id)

    if (isASelected && !isBSelected) {
      return -1
    }
    if (!isASelected && isBSelected) {
      return 1
    }
    return a.id - b.id // Maintain the original order for non-selected users
  }
  var data = [...datat].sort(sortBySelected)

  const updateData = () => {
    if (items.length === 0) {
      setItems(data)
    }
  }
  const renderMenu = (menu) => {
    if (options.length == 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          No {name ? name : 'Formulas'}
        </p>
      )
    }
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> loading...
        </p>
      )
    }
    return menu
  }

  function checkArray(oldArray, newArray) {
    // Find new values (present in newArray but not in oldArray)
    const newValues = newArray.filter((value) => !oldArray.includes(value))

    // Find removed values (present in oldArray but not in newArray)
    const removedValues = oldArray.filter((value) => !newArray.includes(value))

    return {
      newValues,
      removedValues,
    }
  }

  return (
    <>
      {/* <pre>{JSON.stringify(removed, null, 2)}</pre> */}
      <CheckPicker
        placement='auto'
        placeholder={name}
        className='col-12 ingredientTabCheckbox'
        data={data}
        //onOpen={updateData}
        //onSearch={updateData}
        renderMenu={renderMenu}
        value={selected}
        onChange={(e, extra) => {
          const { newValues, removedValues } = checkArray(selected, e)
          setSelected(e)
          //console.log('CheckPicker', newValues, removedValues)
          if (newValues.length !== 0) {
            newTabData(newValues)

            //onChange(e, extra, options, true)
          } else {
            //onChange(e, extra, options, false)
            setRemoved((prevRemoved) => [...prevRemoved, removedValues])
            //removed.push(removedValues)
            // let finalArr = []
            // if (removedValues.length != 0) {
            //   finalArr = removedValues.every((value) => e.includes(value))
            // }
            // console.log('removeFinalArr', finalArr, removedValues)
            // if (removedValues) {
            //   setRemoved((prevRemoved) => [...prevRemoved, ...removedValues])
            // }

            // let finalRemove = removedValues.every((value) => e.includes(value))
            // if (finalRemove != false) {
            //   let temp = [...finalArr, ...finalRemove]
            //   setRemoved(temp)
            //   console.log('removeFinalArr', temp)
            // } else {
            //   setRemoved(finalArr)
            //   console.log('removeFinalArr', finalArr)
            // }
          }
        }}
        cleanable={false}
        onClose={handleClose}
      />
    </>
  )
}
