import React, { useEffect, useState } from 'react'
import { SelectPicker, InputPicker, Loader } from 'rsuite'
import memoize from 'memoize-one'

const FixedLoader = () => (
  <Loader
    content='Loading...'
    style={{
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: '0',
      background: '#fff',
      width: '100%',
      padding: '6px 0',
    }}
  />
)

export default function IngredientSelect({
  formulaIngredients = [],
  thisNameType = '',
  rowData = [],
  column = '  ',
  onUpdate,
  is_duplicate,
}) {
  const [data, setData] = useState([]) //formulaIngredients.length != 0 ? formulaIngredients :
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = React.useState('')
  const [value, setValue] = React.useState('')

  useEffect(() => {
    setLoading(false)
    setData(formulaIngredients)
    setValue(rowData.ingredient_id)
  }, [formulaIngredients])

  // Custom Sort Array
  function customSort(search = '') {
    if (search) {
      search = search.toLowerCase()
      setSearchText(search)
      const startsWithSearch = []
      const otherItems = []

      formulaIngredients.forEach((user) => {
        if (user.label.toLowerCase().startsWith(search)) {
          startsWithSearch.push(user)
        } else {
          otherItems.push(user)
        }
      })

      startsWithSearch.sort((a, b) => a.label.localeCompare(b.label))
      otherItems.sort((a, b) => a.label.localeCompare(b.label))

      const sortedUsers = [...startsWithSearch, ...otherItems]
      //console.log('ifsearch', sortedUsers)
      setData(sortedUsers)
      //console.log(sortedUsers)
      return sortedUsers
    } else {
      // console.log("test1234",data.length)
      setSearchText('')
      // If no search term, sort the data alphabetically by label
      let sortedUsers = ''
      if (formulaIngredients.length > 0) {
        sortedUsers = [...formulaIngredients]
        sortedUsers.sort((a, b) => a.label.localeCompare(b.label))
        setData(sortedUsers)
        // console.log("sortedUsers")
        return sortedUsers
      }
    }
  }

  useEffect(() => {
    //console.log('searchText', searchText, data.length)
  }, [data])

  const renderMenu = (menu) => {
    return (
      <>
        {menu}
        {loading && <FixedLoader />}
      </>
    )
  }
  const onItemsRendered = ({ visibleStopIndex }) => {
    if (visibleStopIndex >= data.length - 1) {
      setLoading(false)
      setData(formulaIngredients)
    }
  }

  const defaultValue =
    formulaIngredients?.find(function (option) {
      return option.value == rowData.ingredient_id
    }) || []
  // console.log(rowData.ingredient_id,'ingredient121', formulaIngredients,rowData.ingredient_id)

  const name = defaultValue.length != 0 ? defaultValue.display_name : ''

  return (
    <>
      <SelectPicker
        //label={rowData.is_duplicate == true ? 'Duplicate' : ''}
        label={is_duplicate == true ? 'Duplicate' : ''}
        size='xl'
        placement='auto'
        placeholder={rowData.ingredient_id != '' ? name : 'Choose..'}
        //placeholder='Choose...'
        data={data}
        onSearch={(string, e) => {
          return customSort(string)
        }}
        onChange={(e) => {
          onUpdate(e, rowData.id, column)
        }}
        //renderMenu={renderMenu}
        // listProps={{
        //   onItemsRendered,
        // }}
        block
        defaultValue={rowData.ingredient_id}
        renderValue={(value, item) => {
          //console.log("renderValue",{value,item})
          if (value && item) {
            return item.display_name
          }
        }}
        value={String(rowData.ingredient_id)}
        style={{ width: '100%' }}
      />

      {/* <select
        className='form-control'
        style={{ width: '100%' }}
        value={rowData.ingredient_id}
        onChange={(e) => {
          var value = e.target.value
          onUpdate(value, rowData.id, column)
        }}
      >
        <option value=''>Choose Ingredient</option>
        {Object.entries(data).map(([key, i]) => (
          <option
            key={key}
            value={i.value}
            selected={rowData.ingredient_id === i.value}
          >
            {i.label}
          </option>
        ))}
      </select> */}
    </>
  )
}
