import React, { useEffect, useState } from 'react'
import { Input } from 'rsuite'
import he from 'he'

const decodeEntities = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.documentElement.textContent
}
function FormulaBuilderDispensaryInstructionSection({
  formulaBuilderId,
  data,
  display_name,
  formulaDeatils,
  onInsUpdate,
  ApplyFormValidation,
  thisFormData,
}) {
  const [DispensaryInstructions, setDispensaryInstructions] = useState('')
  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({})
  var requirements = [
    {
      column: 'FORMULAS_DISPENSARY_INSTRUCTIONS',
      name: 'Dispensary Instructions',
      is_required: 0,
      key: 'FORMULAS_DISPENSARY_INSTRUCTIONS',
      allow_zero: false,
    },
  ]
  useEffect(() => {
    if (formulaDeatils && formulaDeatils.id) {
      try {
        var dispensary_instructions = formulaDeatils.dispensary_instructions
          ? decodeEntities(formulaDeatils.dispensary_instructions)
          : ''
        setDispensaryInstructions(dispensary_instructions)
      } catch (error) {
        //console.error('Error decoding URI component:', error)
        var dispensary_instructions = formulaDeatils.dispensary_instructions
          ? formulaDeatils.dispensary_instructions
          : ''
        //dispensary_instructions.replace() // & -> &amp;

        setDispensaryInstructions(dispensary_instructions)
      }
    }
  }, [formulaBuilderId, formulaDeatils])
  useEffect(() => {
    //console.log(DispensaryInstructions)
    onInsUpdate(DispensaryInstructions)
    formValidation()
  }, [DispensaryInstructions])

  const formValidation = () => {
    //console.log('formValidation-witouttag')
    formValue['FORMULAS_DISPENSARY_INSTRUCTIONS'] = DispensaryInstructions

    var Final_array = []
    Object.values(requirements).forEach((field) => {
      const ikey = field.key
      const temp = {
        ...field,
        error: '',
        value: formValue[ikey],
      }
      Final_array.push(temp)
    })

    //console.log('Final_array', Final_array)
    thisFormData(Final_array, 'FORMULAS_DISPENSARY_INSTRUCTIONS')
  }

  return (
    <div className='FormulaBuilderDispensaryInstructionSection'>
      <h2 className='title mt-5'>{display_name}</h2>
      <br />

      <div className='row'>
        {/* Main Section */}
        <div className='col-8'>
          {/* <textarea
            rows='10'
            value={DispensaryInstructions}
            className='col-12 form-control'
            onChange={(e) => setDispensaryInstructions(e.target.value)}
            style={{ maxHeight: '15rem' }}
          ></textarea> */}
          <Input
            as='textarea'
            rows={6}
            //placeholder='Textarea'
            value={String(DispensaryInstructions)}
            className='col-12'
            onChange={(e) => {
              //onInsUpdate(e)
              setDispensaryInstructions(e)
            }}
            style={{ maxHeight: '15rem' }}
            //dangerouslySetInnerHTML={{__html: DispensaryInstructions}}
          />
          {/*<div dangerouslySetInnerHTML={{__html: DispensaryInstructions}}/>*/}
        </div>
        <div className='col-4'></div>
      </div>
    </div>
  )
}

export default FormulaBuilderDispensaryInstructionSection
