import React, { useEffect, useState } from 'react'
import {
  Tooltip,
  Whisper,
  Panel,
  Placeholder,
  Row,
  Col,
  Divider,
  Form,
} from 'rsuite'

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text
  }
  return text.slice(0, maxLength) + '...' // Add ellipsis (...) to indicate truncated text
}
const capitalizeFirstLetter = (inputString) => {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1)
}
export default function FormulaBuilderServicesSection({
  formulaBuilderId,
  data,
  currency,
  onServicesSectionChange,
  formulaDeatils,
  display_name,
  ApplyFormValidation,
  thisFormData,
  selectedAddOnServices,
  price,
}) {
  const [serviceArr, setServiceArr] = useState([])
  const [addOnServices, setaddOnServices] = useState([])
  const [selected, setSelected] = useState([])

  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({})
  var requirements = [
    {
      column: 'addon_services',
      name: display_name,
      is_required: 0,
      key: 'addon_services',
      allow_zero: false,
    },
  ]
  useEffect(() => {
    var servicesData = data.addOnServices ? JSON.parse(data.addOnServices) : []
    // Cateory Grouping
    // Flatten the array and convert it to an array of objects
    const flatData = servicesData.flatMap((item) => {
      const key = Object.keys(item)[0]
      return [{ ...item[key], category: key }]
    })

    // Group the data by the 'category' field
    const groupedData = flatData.reduce((acc, item) => {
      const { category, ...rest } = item
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(rest)
      return acc
    }, {})
    // Update the state with the grouped data
    setServiceArr(groupedData)
    // Simple Array
    //setServiceArr(servicesData)
    // ####################################
    //setTimeout(() => {
    if (formulaDeatils && formulaDeatils.id) {
      if (formulaDeatils.add_on_services_details) {
        const selectedArr = formulaDeatils.add_on_services_details
          .split(',')
          .map(String)

        setSelected(selectedArr)
        //console.log('selectedArr', selectedArr)
        servicesData?.map((value, k) => {
          Object.entries(value)?.map(([key, value1]) => {
            if (selectedArr.includes(value1.id)) {
              var checked = true
              setaddOnServices((prev) =>
                checked
                  ? [...prev, value1]
                  : prev.filter((val) => val != value1)
              )
            }
          })
        })
      }
    }
    //}, 500)
  }, [formulaBuilderId, formulaDeatils])

  const handleChange = (event, value1) => {
    const { checked, value } = event.currentTarget
    //console.log('handleChange', checked, value, value1)
    setaddOnServices((prev) =>
      checked ? [...prev, value1] : prev.filter((val) => val !== value1)
    )

    setSelected((prev) =>
      checked ? [...prev, value1.id] : prev.filter((val) => val !== value1.id)
    )
    formValidation()
  }

  useEffect(() => {
    // Remove blank values and duplicates
    const newArray = [...new Set(selected.filter((value) => value))]
    onServicesSectionChange(newArray)
    formValidation()
  }, [addOnServices, selected, price])
  // console.log('serviceArr', selected, addOnServices, serviceArr)

  //FORMULAS_ADD_ON_SERVICES
  // useEffect(() => {
  //   formValidation()
  // }, [addOnServices])

  useEffect(() => {
    if (ApplyFormValidation == true) {
      //formValidation()
    }
  }, [ApplyFormValidation])

  const formValidation = () => {
    var servicesDataArr = data.addOnServices
      ? JSON.parse(data.addOnServices)
      : []
    const servicesData = servicesDataArr.flatMap((item) => {
      const key = Object.keys(item)[0]
      return [{ ...item[key], category: key }]
    })
    var allow_services = []
    servicesData?.map((i, k) => {
      if (selected.includes(i.id)) {
        const addon_price = Number(i?.price)
        const addon_tax = Number(i?.tax)
        var tax = (Number(price) * addon_tax) / 100
        //allow_services.push({id:i.id,name:i.name,price:addon_price,tax:tax})
        allow_services.push({
          ...i,
          price: addon_price,
          tax_price: tax,
          ingredient_price: price,
        })
      }
    })
    selectedAddOnServices(allow_services)
    formValue['addons'] = allow_services
    const temp = {
      ...requirements,
      value: formValue['addons'],
      error: formError['addons'],
    }
    thisFormData(temp, 'FORMULAS_ADD_ON_SERVICES')
  }

  if (serviceArr) {
    return (
      <>
        <div className='FormulaBuilderServicesSection'>
          {<h2 className='title mt-5'>{display_name.display_name}</h2>}
          {/* <pre>{JSON.stringify([selected, typeof selected], null, 2)}</pre> */}
          <Row className='col-8'>
            {Object.entries(serviceArr).map(([category, services]) => (
              <div className='col-12' key={category}>
                <h4 className='title' style={{ marginLeft: '5px' }}>
                  <Whisper
                    speaker={
                      <Tooltip>
                        {category && category !== 0 ? category : ''}
                      </Tooltip>
                    }
                  >
                    <u style={{ fontSize: '1rem' }}>
                      {category && category != 0
                        ? truncateText(capitalizeFirstLetter(category), 110)
                        : ''}
                    </u>
                  </Whisper>
                </h4>
                <Row style={{ marginBottom: '1rem' }}>
                  {services.map((value1) => {
                    const headerText = (
                      <div>
                        <strong>{value1?.name}</strong>
                        <span className=''>
                          <input
                            type='checkbox'
                            value={value1.id}
                            checked={selected.includes(value1.id)}
                            name={value1.name}
                            onChange={(e) => handleChange(e, value1)}
                            style={{ float: 'right' }}
                          />
                        </span>
                      </div>
                    )

                    const truncatedDesText = truncateText(
                      value1.description,
                      125
                    )
                    const price = parseFloat(value1?.price) || 0
                    const tax = parseFloat(value1?.tax) || 0

                    // value1?.description && value1.description.length > 80
                    //   ? value1.description.substring(0, 90) + '...'
                    //   : value1.description

                    return (
                      <Col md={8} sm={8} key={value1.id}>
                        <Panel
                          bordered
                          header={headerText}
                          style={{
                            marginTop: '5px',
                            marginBottom: '5px',
                            height: '9.5rem',
                          }}
                        >
                          <small className='text-right'>
                            {currency}
                            {price.toPrecision(4)} + {tax.toPrecision(2)}% of
                            Formula
                          </small>
                          <Divider style={{ margin: '0px' }} />
                          <Whisper
                            followCursor
                            speaker={<Tooltip>{value1.description}</Tooltip>}
                          >
                            <small>{truncatedDesText}</small>
                          </Whisper>
                        </Panel>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            ))}
          </Row>
          <Row className='col-8'></Row>
        </div>
      </>
    )
  }
}
