import {
    PERCENTAGE_ISSUE_FAILED,
    PERCENTAGE_ISSUE_SUCCESS,
    PERCENTAGE_ISSUE_REQUEST,
} from "./percentageIssue.state";


export const percentageRequest = (user) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: PERCENTAGE_ISSUE_REQUEST,
                payload: []
            });
            dispatch({
                type: PERCENTAGE_ISSUE_SUCCESS,
                payload: user
            });
        }
        catch (err) {
            dispatch({
                type: PERCENTAGE_ISSUE_FAILED,
                payload: []
            });
        }
    }
}