import styled from 'styled-components'
import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef,
  useCallback,
} from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './FormulaBuilder.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Loader,
  Placeholder,
  Message,
  Button,
  Notification,
  useToaster,
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaExclamationTriangle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  ApiFormulaBuilderDetails,
  ApiFormulaBuilderDosagesDetails,
  ApiFormulaBuilderList,
  ApiVerifyPrac,
  ApiFormulaBulderIngredientLists,
  ApiFormulaDetails,
  ApiSaveFormulaDetails,
  ApiFormulaExtraData,
} from '../../services/api/api_formula_builder'
import FormulaBuilderGeneralSection from './FormulaBuilderGeneralSection/FormulaBuilderGeneralSection'
import FormulaBuilderOptionsSection from './FormulaBuilderOptionsSection/FormulaBuilderOptionsSection'
import FormulaBuilderDosagesSection from './FormulaBuilderDosagesSection/FormulaBuilderDosagesSection'
//import FormulaBuilderIngredientsSection from './sections/FormulaBuilderIngredientsSection'
import { FormulaBuilderIngredientsSection } from './FormulaBuilderIngredientsSection/FormulaBuilderIngredientsSection'
// import FormulaBuilderIngredientConvert from './sections/FormulaBuilderIngredientConvert'
import FormulaBuilderPreprationInstructionsSection from './FormulaBuilderPreprationInstructionsSection/FormulaBuilderPreprationInstructionsSection'
// import { FormulaBuilderWithoutTagPreprationInstructionsSection } from './FormulaBuilderWithoutTagPreprationInstructionsSection/FormulaBuilderWithoutTagPreprationInstructionsSection.1';
import { FormulaBuilderWithoutTagPreprationInstructionsSection } from './FormulaBuilderWithoutTagPreprationInstructionsSection/FormulaBuilderWithoutTagPreprationInstructionsSection'
import FormulaBuilderServicesSection from './FormulaBuilderServicesSection/FormulaBuilderServicesSection'
import FormulaBuilderMarkupSection from './FormulaBuilderMarkupSection/FormulaBuilderMarkupSection'
import FormulaBuilderPriceSection from './FormulaBuilderPriceSection/FormulaBuilderPriceSection'
import FormulaBuilderSummarySection from './FormulaBuilderSummarySection/FormulaBuilderSummarySection'

import FormulaBuilderRefillSection from './FormulaBuilderRefillSection/FormulaBuilderRefillSection'
import FormulaBuilderDispensaryInstructions from './FormulaBuilderDispensaryInstructionSection/FormulaBuilderDispensaryInstructionSection'

import { FormualIngredientTable } from './helpers/IngredientTableNew'

import { FormulaSaveButton } from './helpers/FormulaSaveButton'
import { APIConfig } from '../../apiConfig'
const { BASEURL } = APIConfig

//import { paperClasses } from '@mui/material'

const JSONView = ({ form }) => (
  <div className='row' style={{ marginBottom: 10 }}>
    <div class='json-tree-wrapper rs-panel'>
      <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
        <p class='rs-panel-title'>formValue</p>
      </div>
      <div role='region' class='rs-panel-body'>
        <pre>{JSON.stringify(form, null, 2)}</pre>
      </div>
    </div>
  </div>
)

const FormulaBuilder = () => {
  const { id, prac, formula = '', is_order = false } = useParams()
  console.log('URLparams', { id, prac, formula, is_order })
  // formula detials
  const [formulaId, setformulaId] = useState(formula)
  const [formulaDeatils, setformulaDeatils] = useState([])
  //const [abc, setAbc] = useState('')
  const { summaryReducer } = useSelector((response) => response)

  const [formulaSummaryData, setSummaryData] = useState('')
  const [formulaOptionCheck, setFormulaOption] = useState('')
  const [formulaTotalQty, setFormulaTotalQty] = useState('')
  const [formulaDosagesSetting, setformulaDosagesSetting] = useState([])

  const [AllowEnterQty, setAllowEnterQty] = useState(false)
  const [AllowRawQty, setAllowRawQty] = useState(false)

  // Callback function to receive data from the child
  const handleSummaryData = (summarydata, allow_enter, allow_raw) => {
    setSummaryData(summarydata)
    setAllowEnterQty(allow_enter)
    setAllowRawQty(allow_raw)
  }

  const handleFormulaOption = (data) => {
    setFormulaOption(data)
  }

  //const formulaBuilderId = id
  const [formulaBuilderId, setformulaBuilderId] = useState(id)

  const [pracId, setPracId] = useState(prac)
  const [validPrac, setValidPrac] = useState(false)
  const [pracDetails, setPracDetails] = useState([])
  const [pracFormulaSetting, setPracformula_setting] = useState([])

  const [prevFormulaBuilderId, setprevFormulaBuilderId] = useState('')
  const [curentformulaBuilderId, setCurrentformulaBuilderId] = useState('')
  const [allIngredientsResponse, setAllIngredientsResponse] = useState([])

  const navigate = useNavigate()
  const toaster = useToaster()
  const [formulaError, setformulaError] = useState(false)
  const [formulaErrorMsg, setformulaErrorMsg] = useState(null)
  const [formulaSectionErrors, setFormulaSectionErrors] = useState([])
  const [concentrates, setConcentrates] = useState([])

  const [autoSaveAfter, setAutoSaveAfter] = useState(10000) // 60000 milliseconds = 60 seconds
  const [formulaFormData, setformulaFormData] = useState({}) // All section data with validation error

  const [data, setData] = useState(null)
  const [formulaList, setformulaList] = useState([]) // get active formulaList according to Admin && Prac Setting

  const [prevdata, setPrevData] = useState(null)
  const [prevFormulaBuilderType, setprevFormulaBuilderType] = useState('')
  const [prevFormulaBuilderIngredients, setprevFormulaBuilderIngredients] =
    useState([])
  const [loading, setLoading] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)

  const [currency, setCurrency] = useState('$')
  const [nameTypeArr, setNameTypeArr] = useState([])
  const [measurmentConversions, setMeasurmentConversions] = useState([])

  // section wise data
  const [formulaSections, setformulaSections] = useState([])
  const [formulaGeneralSections, setformulaGeneralSections] = useState([])

  //const general_section_data = JSON.parse(data.general_section)
  //gernal section input data consts
  const [formulaBuilderType, setformulaBuilderType] = useState(formulaBuilderId)
  const [formulaBuilderName, setformulaBuilderName] = useState(null)
  const [formulaBuilderDecoction, setformulaBuilderDecoction] = useState(0)
  const [formulaBuilderPatient, setformulaBuilderPatient] = useState(0)
  const [formulaBuilderDosageMode, setformulaBuilderDosageMode] = useState(0)
  const [formulaBuilderDosageModeType, setformulaBuilderDosageModeType] =
    useState(null)
  const [formulaBuilderDosageModeKey, setformulaBuilderDosageModeKey] =
    useState(null)
  const [formulaBuilderShareFormula, setformulaBuilderShareFormula] =
    useState(0)
  const [formulaBuilderPractice, setformulaBuilderPractice] = useState(0)
  const [formulaBuilderSupervisor, setformulaBuilderSupervisor] = useState(0)
  const [formulaBuilderTemplate, setformulaBuilderTemplate] = useState(0)
  // option section input data consts
  const [is_capsules, set_is_capsules] = useState(0) //data.is_capsules ? data.is_capsules : 0
  const [is_tablets, set_is_tablets] = useState(0) //data.is_tablets ? data.is_tablets : 0
  const [is_softgels, set_is_softgels] = useState(0) //data.is_softgels ? data.is_softgels : 0

  const [defaultMeasuremt, setdefaultMeasuremt] = useState(0)
  const [defaultMeasuremtName, setdefaultMeasuremtName] = useState('')
  const [allow_multiple_measurements, setallow_multiple_measurements] =
    useState(false)
  const [measurementOptions, setmeasurementOptions] = useState([])
  const [ingredient_selection, setingredient_selection] = useState([])

  const [unitName, setUnitName] = useState('')
  const [totalQty, setTotalQty] = useState(0)

  const [totalQtyOptions, setTotalQtyOptions] = useState([])
  const [activeDosages, setActiveDosages] = useState('') // if capsule/tablets/softgel active then get Dosage Inst. according to this
  const [activeOptionDosagesData, setActiveOptionDosagesData] = useState([])
  const [isCapsuleSelected, setIsCapsuleSelected] = useState(0)
  const [capsuleSizeSelected, setCapsuleSizeSelected] = useState(0)
  const [capsuleQty, setCapsuleQty] = useState(0)
  const [numberBags, setNumberBags] = useState(0)
  const [numberDays, setNumberDays] = useState(0)

  const [minBasedQty, setMinBasedQty] = useState(0)
  const [maxBasedQty, setMaxBasedQty] = useState(0)

  // Price section input data consts
  const [priceArr, setPriceArr] = useState([])
  const [price, setPrice] = useState(0)
  // Markup section input data consts
  const [allowMarkup, setallowMarkup] = useState(0)
  const [markup_type, setmarkup_type] = useState('') // flat,percentage
  const [markup_amount, setmarkup_amount] = useState(0)
  // Dosages Section
  const [dosageSettings, setdosageSettings] = useState([])
  const [usePresetDosageInstructions, setUsePresetDosageInstructions] =
    useState(false)
  const [dosagesData, setdosagesData] = useState([])
  const [dosagesText, setdosagesText] = useState('')
  const [allowOwnDosagesInstructions, setAllowOwnDosagesInstructions] =
    useState(true)
  const [ownDosagesInstructions, setOwnDosagesInstructions] = useState('')

  const [automaticallyCalculateDosages, setAutomaticallyCalculateDosages] =
    useState(false)
  const [
    allowUserToChangeAutomaticallyCalculateDosages,
    setAllowUserToChangeAutomaticallyCalculateDosages,
  ] = useState(false)

  // ingredient Section
  const [formulaIngredients, setformulaIngredients] = useState([])
  const [formulaIngredientsIdArr, setformulaIngredientsIdArr] = useState([])
  const [nameTypes, setNameTypes] = useState([])
  const [ingredientNamePostFix, setingredientNamePostFix] = useState([])
  const [ingredients, setIngredients] = useState([])
  const [ingrediuentsTags, setIngrediuentsTags] = useState([])

  const [seperatorText, setSeperatorText] = useState('')
  const [seperator, setSeperatorSign] = useState(' ')
  const [ingredient_decimal, setingredient_decimal] = useState([])
  // Preparation Instructions
  const [herbTags, setHerbTags] = useState([])
  const [blankHerbTags, setBlankHerbTags] = useState([])
  // Addon Servies
  const [addOnServices, setaddOnServices] = useState([])
  const [selectedAddonServices, setSelectedAddonServices] = useState([])
  //Price
  const [totalPrice, setTotalPrice] = useState(0)
  const [ingredientTotal, setIngredientsTotal] = useState(0)
  const [markupTotal, setMarkupTotal] = useState(0)
  const [addOnServiesTotal, setAddOnServiesTotal] = useState(0)
  const [subtotalPrice, setSubTotalPrice] = useState(0)
  const [feesTotal, setFeesTotal] = useState(0)
  const [capsulefeesTotal, setcapsulefeesTotal] = useState(0)

  // Refill
  const [refill_no, setrefill_no] = useState('')
  const [refill_frequency, setrefill_frequency] = useState('') // flat,percentage
  const [refill_date, setrefill_date] = useState('')

  // Dispensary Instructions
  const [DispensaryInstructions, setDispensaryInstructions] = useState('')
  //const id = useParams.match.params.id
  //console.log(formulaBuilderId, props)
  const [formData, setFormData] = useState([])
  const [ApplyFormValidation, setApplyFormValidation] = useState(0)

  const [dosageConverting, setDosageConverting] = useState(false)
  const [formulaConverting, setFormulaConverting] = useState(false)
  const [formulaConvertingTo, setFormulaConvertingTo] = useState(null)

  const [prevFormulaBuilderDosageMode, setPrevFormulaBuilderDosageMode] =
    useState(null)
  const [prevFormulaBuilderDosageModeKey, setPrevFormulaBuilderDosageModeKey] =
    useState(null)

  useEffect(() => {
    // console.log('urlPrams', id, prac, formula)
    getFormualDetails()
  }, [formulaId])

  // useEffect(() => {
  //   if(!formulaBuilderPatient || formulaBuilderPatient==0 || formulaBuilderPatient==null ||formulaBuilderPatient==''){
  //     setformulaBuilderPatient(patient)
  //   }
  //   console.log('patientInfo',[patient,formulaBuilderPatient])
  // }, [patient]);

  useEffect(() => {
    //handleDosagesModeType()
    getApiDosagesData()
  }, [activeDosages])
  useEffect(() => {
    findPrac()
    if (formulaBuilderId) {
      //if (curentformulaBuilderId != formulaBuilderId) {
      const formulaDetails = getData()
      //console.log(formulaDetails)
      formulaDetails.then((response) => {
        //console.log('formulaDetails -' + formulaBuilderId, formulaDetails)

        //if (!response?.id) {
        if (response && response.id == 'undefined') {
          setData(null)
          setformulaError(true)
          setformulaErrorMsg('Current Formula Builder Type is not available.')
        } else {
          setLoading(false)
          setCurrentformulaBuilderId(response.id)
          const finalSectionsData = response.sections
            ? JSON.parse(response.sections)
            : []
          const tagsOfIngrediuents =
            response.tagsOfIngrediuents && response.tagsOfIngrediuents != null
              ? JSON.parse(response.tagsOfIngrediuents)
              : []

          navigate(`/${pracId}/${formulaBuilderId}/${formula}/${is_order}`)

          setData(response)
          var save_formula_after = response
            ? parseInt(response.save_formula_after)
            : 60
          setAutoSaveAfter(save_formula_after * 1000)

          setSeperatorText(response ? response.default_seperator : '')
          var seperator = updateseperatorSign(
            response ? response.default_seperator : ''
          )
          var ingredient_selection_type = response
            ? response.ingredient_selection_type
            : ''

          //setformulaIngredients(response ? response.formula_ingredients : []) // simple ingredient list
          getFormulaIngredientList(seperator, ingredient_selection_type)
          //console.log('MainformulaIngredients', response.formula_ingredients)
          //console.log('finalData', finalData)
          setformulaSections(finalSectionsData)
          setIngrediuentsTags(tagsOfIngrediuents)
          setallowMarkup(response.markup || 0)

          updatePracSetting(response.name)
          // dosages variables
          //set_is_capsules(data.is_capsules ? data.is_capsules : 0)
          //set_is_tablets(data.is_tablets ? data.is_tablets : 0)
          //set_is_softgels(data.is_softgels ? data.is_softgels : 0)
          handleDosagesModeType()
          getApiDosagesData() //21 july
        }
      })
      setAllIngredientsResponse([])
    } else {
      findFormulaBuildes()
    }

    //}
  }, [formulaBuilderId])

  useEffect(() => {
    // Set up an interval to call the function every 30 seconds
    const intervalId = setInterval(() => {
      // console.log('auto synced in every ' + 30000 / 1000 + ' seconds')
      if (data != null && formulaId != '' && formulaId != undefined) {
        //handleSubmit('', true)
        handleSaveFormula()
      }
    }, 30000) // 30,000 milliseconds = 30 seconds
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const findPrac = () => {
    if (validPrac == false && pracId) {
      const pracDetails = getPrac()
      pracDetails.then((response) => {
        //console.log('pracDetails', response)
        if (response?.id) {
          //alert('validPrac updated')
          setValidPrac(true)
          findFormulaBuildes()
          //pracDetails = response
          setPracDetails(response)
        } else {
          //setValidPrac(false) // important
        }
      })
    }
  }

  const updatePracSetting = (name) => {
    // var prac_formula_setting =
    //   pracDetails && pracDetails.formula_setting
    //     ? JSON.parse(pracDetails.formula_setting)
    //     : []
    // //console.log('prac_formula_setting', pracDetails, prac_formula_setting)
    // var current_formual_prac_setting =
    //   prac_formula_setting && prac_formula_setting[name]
    //     ? prac_formula_setting[name]
    //     : []
    // console.log('current_formual_prac_setting', current_formual_prac_setting)
    // setPracformula_setting(current_formual_prac_setting)
    // if (current_formual_prac_setting?.id) {
    //   if (current_formual_prac_setting && numberDays == 0) {
    //     var thisVal = current_formual_prac_setting.default_days
    //       ? current_formual_prac_setting.default_days
    //       : 0
    //     //alert([thisVal, name])
    //     setNumberDays(thisVal)
    //   }
    // }
  }

  const findFormulaBuildes = () => {
    var formula_list = getFormulaBuilderList()
    formula_list.then((response) => {
      //console.log('formula_list', response)
      setformulaList(response)
      //console.log('findFormulaBuildes', formulaBuilderId, validPrac)
      if (!formulaBuilderId || formulaBuilderId == undefined) {
        Object.values(response).map((i, k) => {
          if (k == 0) {
            setformulaBuilderId(i.id)
            //console.log('set active formula', i.id)
          }
        })
      }
    })
  }

  const getData = async () => {
    setLoading(true)
    // console.log('get formula builder ' + formulaBuilderId)
    // track prevs Builder Type Data
    if (formulaBuilderId != prevFormulaBuilderId) {
      setPrevData(data ? data : '')
      setprevFormulaBuilderType(data ? data.display_name : '')
      setprevFormulaBuilderIngredients(data ? data.formula_ingredients : [])
      setprevFormulaBuilderId(formulaBuilderId)
    }
    const response = await ApiFormulaBuilderDetails({
      formulaBuilderId,
      pracId,
    })
    const finalData = await response

    return finalData
  }

  const getFormualDetails = async () => {
    const response = await ApiFormulaDetails({
      formulaBuilderId,
      pracId,
      formulaId,
      is_order,
    })
    const finalData = await response
    setformulaDeatils(finalData ? finalData : [])
    console.log('formulaDeatils', finalData)
    if (
      finalData &&
      finalData.formula_builder_id != formulaBuilderId &&
      finalData.formula_builder_id != 0
    ) {
      setformulaBuilderId(finalData.formula_builder_id)

      // console.log('change to ' + finalData.formula_builder_id)
    }
  }
  //console.log(formulaBuilderDosageMode, 'modeee')
  const getApiDosagesData = async () => {
    var formulaDosagesType = activeDosages
      ? activeDosages
      : formulaBuilderDosageModeType
    var ingredient_selection_type = data?.ingredient_selection_type
    if (formulaBuilderId && formulaDosagesType) {
      //console.log('getDosagesData', finalDoseType, formulaBuilderDosageModeType)
      //console.log('getDosages', formulaDosagesType)
      try {
        const response = await ApiFormulaBuilderDosagesDetails({
          formulaBuilderId,
          formulaDosagesType,
          pracId,
          ingredient_selection_type,
        })
        const finalData = await response

        setdosageSettings(finalData)

        Object.entries(finalData).forEach(([key, value]) => {
          if (value.key_value === 'DOSAGE_INPUT_NUMBER_OF_DAYS') {
            handleNumberDaysChange(value.value)
          }
        })
      } catch (error) {
        // console.log('An getApiDosagesData error occurred:', error.message)
      }

      // var finalData
      // response
      //   .then((data) => {
      //     finalData = data
      //     setdosageSettings(finalData)

      //     Object.values(finalData).map((i, k) => {
      //       if (i.key_value === 'DOSAGE_INPUT_NUMBER_OF_DAYS') {
      //         handleNumberDaysChange(i.default_value)
      //       }
      //     })
      //   })
      //   .catch((error) => {
      //     // Handle the error
      //   })
    }
  }

  const getFormulaBuilderList = async () => {
    getFormulaExtraData()
    const response = await ApiFormulaBuilderList({ pracId })
    return response
  }
  const getFormulaIngredientList = async (
    seperator,
    ingredient_selection_type,
    allow_ingredients = []
  ) => {
    const allowed_ingredients = allow_ingredients
      ? allow_ingredients.join(',')
      : ''
    let response = ''
    if (allIngredientsResponse.length === 0) {
      setLoadingTable(true)
      response = await ApiFormulaBulderIngredientLists({
        formulaBuilderId,
        pracId,
        ingredient_selection_type,
        allowed_ingredients,
      })
      // console.log(allowed_ingredients,response?.ingredients?.length,"ingredients")
      if (allowed_ingredients) {
        // console.log(allowed_ingredients,response,"ingredients22222")
        setAllIngredientsResponse(response)
        setLoadingTable(false)
      }
    } else {
      const ingrediantsArray = allIngredientsResponse
      // Create a shallow copy of the array
      const originalIngredients = [...ingrediantsArray.ingredients]

      // Filter the array based on the condition
      const filteredIngrediantsArray = originalIngredients.filter((item) => {
        if (allow_ingredients.indexOf(item.ingredient_id.toString()) !== -1)
          return item
      })

      // Create a new object with the shallow copy of the array
      const newIngredientsArray = {
        ...ingrediantsArray,
        ingredients: filteredIngrediantsArray,
        total_ingredients: filteredIngrediantsArray.length,
      }
      // console.log(allowed_ingredients,allIngredientsResponse,"ingredients22222",newIngredientsArray)
      response = newIngredientsArray
    }

    if (response) {
      const {
        ingredients,
        fields,
        names,
        fieldname,
        ingredient_decimal,
        filter,
      } = response

      // Update state with the received data
      setNameTypes(names)
      setingredientNamePostFix(fields)
      setformulaIngredients(ingredients)
      setingredient_decimal(ingredient_decimal)

      // console.log(response, 'response')
      // console.log(allIngredientsResponse, '123')
      // console.log(formulaIngredientsIdArr, '456')
      // setformulaIngredientsIdArr(IngredientsIdArr)
      // setformulaIngredients(IngredientOptionArr)

      // Update formula ingredient list with the received data
      updateFormulaIngredientList(
        ingredients,
        fields,
        fieldname,
        seperator,
        names,
        ingredient_decimal,
        filter
      )
    }
    //console.log('ingredientList', response.ingredients)
    // var ingredients = response ? response.ingredients : []
    // var extra = response ? response.fields : []
    // var namesType = response ? response.names : []
    // var fieldname = response ? response.fieldname : []
    // var ingredient_decimal = response ? response.ingredient_decimal : []
    // //namesType.push('all_names')
    // setNameTypes(namesType)
    // setingredientNamePostFix(extra)
    // setformulaIngredients(ingredients)
    // updateFormulaIngredientList(
    //   ingredients,
    //   extra,
    //   fieldname,
    //   seperator,
    //   namesType,
    //   ingredient_decimal
    // )
    //return response
  }

  const getFormulaExtraData = async () => {
    const response = await ApiFormulaExtraData({
      formulaBuilderId,
      pracId,
    })
    if (response) {
      const { measurment_conversions, name_types } = response
      // Update state with the received data
      setNameTypeArr(name_types ? JSON.parse(name_types) : [])
      setMeasurmentConversions(
        measurment_conversions ? JSON.parse(measurment_conversions) : []
      )
    }
  }
  const getPrac = async () => {
    const response = await ApiVerifyPrac({ pracId })
    return response
  }

  const updateseperatorSign = (seperator) => {
    let separatorSign = '' // Default value

    // Check if the selected separator matches any of the given options
    switch (seperator) {
      case 'dot':
        separatorSign = ' ● '
        break
      case 'rounddot':
        separatorSign = ' ⦿ '
        break
      case 'comma':
        separatorSign = ' , '
        break
      case 'pipeline':
        separatorSign = ' | '
        break
      case 'hyphen':
        separatorSign = ' - '
        break
      case 'forwardSlash':
        separatorSign = ' / '
        break
      case 'star':
        separatorSign = ' * '
        break
      case 'greater':
        separatorSign = ' < '
        break
      case 'smaller':
        separatorSign = ' > '
        break
      // You can add more cases here if needed
      default:
        // If none of the options match, set an empty string.
        separatorSign = ' '
    }

    // Update the state
    setSeperatorSign(separatorSign)

    // Return the selected separator sign
    return separatorSign
  }

  const updateFormulaIngredientList = (
    formulaIngredients,
    extra,
    FieldsName,
    seperator,
    namesType,
    ingredient_decimal,
    filter
  ) => {
    var IngredientOptionArr = []
    var IngredientsIdArr = []
    IngredientOptionArr['formula_dosage_column_all_names'] = []
    IngredientOptionArr['name_type'] = namesType
    IngredientOptionArr['all_ingredients'] = formulaIngredients
    IngredientOptionArr['filter'] = filter

    if (
      formulaIngredients &&
      formulaIngredients.length != 0 &&
      formulaIngredients != null
    ) {
      var yesNoFields = new Set([
        'toxic',
        'external_use_only',
        'aristolic_acid',
        'contains_cholesterol',
        'gmo_free',
        'contains_mustard',
        'contains_sulphites',
        'contains_sesame',
        'contains_nuts',
        'madeInaPeanutFreeFacility',
        'caution_with_pregnancy',
        'organic',
        'kosher',
        'wildcrafted',
        'contains_gluten',
        'contains_corn',
        'contains_egg',
        'contains_animal_products',
        'contains_wheat',
        'contains_fish',
        'contains_soy',
        'contains_artificial_sweeters',
        'contains_peanuts',
        'contains_milk',
        'contains_shellfish',
        'contains_treenuts',
        'vegan',
      ])

      namesType.map((name) => {
        //var name = 'name'
        var key = 'formula_dosage_column_' + name
        var ingredientOtherNameOptions = []
        var ingredientOptions = formulaIngredients.map((item, kk) => {
          if (item.product_type == 'herbs') {
            if (item[name] != '') {
              var labelValue = item[name]
              var ing_name = item[name]
              var display_name = item[name]
            } else {
              // var labelValue = item['default_herb_type']
              //   ? item['default_herb_type']
              //   : null
              // var ing_name = labelValue
              // var display_name = labelValue
            }
            if (labelValue) {
              var other_names_arr = []
              labelValue += seperator + item.ing_name
              var other_names = item['other_names'] ? item['other_names'] : ''
              //console.log('other_names', other_names)
              if (other_names && other_names != '' && other_names != null) {
                var other_names_array = other_names
                  ? JSON.parse(other_names)
                  : []
                if (other_names_array && other_names_array.length != 0) {
                  other_names_array.forEach((obj) => {
                    var thisNameType = obj.name_type
                    if (thisNameType == name && obj.name) {
                      other_names_arr.push(obj.name)
                    }
                  })
                }
              }
              var extra_name_data = ''
              if (extra.length != 0) {
                extra.map((i) => {
                  if (i == 'ratio,ratio2') {
                    if (
                      item.ratio !== undefined &&
                      item.ratio2 !== undefined &&
                      item.ratio != '' &&
                      item.ratio2 != ''
                    ) {
                      labelValue += seperator + item.ratio + ':' + item.ratio2
                      extra_name_data +=
                        seperator + item.ratio + ':' + item.ratio2
                    }
                  } else if (
                    item[i] != '' &&
                    item[i] != null &&
                    item[i] !== undefined
                  ) {
                    if (yesNoFields.has(i)) {
                      labelValue += seperator + FieldsName[i]
                      extra_name_data += seperator + FieldsName[i]
                    } else {
                      labelValue += seperator + item[i]
                      extra_name_data += seperator + item[i]
                    }
                  }
                })
              }
              var process_type = ''
              if (
                item['process_type'] != '' &&
                item['process_type'] != null &&
                item['process_type'] !== undefined
              ) {
                display_name += seperator + item['process_type']
                process_type += seperator + item['process_type']
              }

              IngredientsIdArr.push(item.id)
              // add other name in options
              if (other_names_arr.length != 0) {
                other_names_arr.forEach((obj) => {
                  var this_other_name = obj
                  if (
                    this_other_name &&
                    this_other_name != '' &&
                    this_other_name != null
                  ) {
                    var slug = this_other_name
                      .toLowerCase()
                      .replace(/\s+/g, '_')
                    ingredientOtherNameOptions.push({
                      ing_name: this_other_name,
                      label: this_other_name + extra_name_data,
                      display_name: this_other_name + process_type,
                      value: item.id,
                      key: name + '_' + item.id + '_' + slug,
                      herb_type: item.ing_key,
                      ing_id: item.ingredient_id,
                    })
                  }
                })
              }
              return {
                ing_name: ing_name,
                label: labelValue,
                display_name: display_name,
                value: item.id,
                key: name + '_' + item.id + '_' + kk,
                herb_type: item.ing_key,
                ing_id: item.ingredient_id,
              }
            }
            //console.log('product_type', item.product_type)
          }
        })
        if (
          ingredientOtherNameOptions &&
          ingredientOtherNameOptions.length != 0
        ) {
          ingredientOptions = [
            ...ingredientOptions,
            ...ingredientOtherNameOptions,
          ]
        }
        console.log(
          'ingredientOtherNameOptions',
          name,
          ingredientOtherNameOptions
        )
        ingredientOptions.sort((a, b) => a.label.localeCompare(b.label))
        // Filter out null and undefined elements from the array
        // Filter out null and undefined elements from the array
        const filteredArray = ingredientOptions.filter(
          (item) => item !== null && item !== undefined
        )
        var mergedItems = [
          ...IngredientOptionArr['formula_dosage_column_all_names'],
          ...filteredArray,
        ]
        mergedItems.sort((a, b) => a.label.localeCompare(b.label))
        IngredientOptionArr['formula_dosage_column_all_names'] = mergedItems
        IngredientOptionArr[key] = filteredArray
      })
      // IngredientOptionArr['formula_dosage_column_all_names'] =
      //   IngredientOptionArr['formula_dosage_column_name']
      ///////////////////////////////////////////////////////////
      var IngredientsIdArr = Array.from(new Set(IngredientsIdArr))
      setformulaIngredientsIdArr(IngredientsIdArr)
      //setformulaIngredients(IngredientOptionArr)
      ///////////////////////////////////////////////////////////
      // console.log('IngredientOptionArr', IngredientOptionArr, IngredientsIdArr)
    } else {
      setformulaIngredientsIdArr([])
      // setformulaIngredients([])
    }
    console.log('IngredientOptionArr', IngredientOptionArr)
    setformulaIngredients(IngredientOptionArr)
  }

  useEffect(() => {
    getApiDosagesData()
  }, [formulaBuilderDosageModeType, formulaBuilderDosageMode])

  const handleDosagesModeType = (id = 0) => {
    // /alert(id)
    var types = data ? JSON.parse(data.dosage_section) : []
    //alert(formulaBuilderDosageModeType+'---formulaBuilderDosageModeType')

    if (id != null && id != 0 && formulaBuilderDosageModeType != null) {
      Object.values(types).map((i, k) => {
        if (id == i.id) {
          setformulaBuilderDosageMode(i.id)
          setformulaBuilderDosageModeType(i.value)
          setformulaBuilderDosageModeKey(i.key_value)
          setformulaDosagesSetting(i)
          // setNumberDays(i.no_of_days);
          //getApiDosagesData()
        }
      })
    } else if (
      formulaBuilderDosageModeType == null ||
      formulaBuilderDosageModeType == '' ||
      formulaBuilderDosageModeType == '0' ||
      formulaBuilderDosageMode == null ||
      formulaBuilderDosageMode == '' ||
      formulaBuilderDosageMode == 0 ||
      id == '' ||
      id == 'null' ||
      id == 0
    ) {
      Object.values(types).map((i, k) => {
        //console.log('defaultDosagesHandler', k, i)
        if (k == 0) {
          setformulaBuilderDosageMode(i.id)
          setformulaBuilderDosageModeType(i.value)
          setformulaBuilderDosageModeKey(i.key_value)
          setformulaDosagesSetting(i)
          setformulaBuilderDosageMode(i.id)
          // setNumberDays(i.no_of_days);
          //getApiDosagesData()
        }
      })
    } else {
      Object.values(types).map((i, k) => {
        //console.log('defaultDosagesHandler', k, i)
        if (k == 0) {
          setformulaBuilderDosageMode(i.id)
          setformulaBuilderDosageModeType(i.value)
          setformulaBuilderDosageModeKey(i.key_value)
          setformulaDosagesSetting(i)
          setformulaBuilderDosageMode(i.id)
          // setNumberDays(i.no_of_days);
          //getApiDosagesData()
        }
      })
    }
  }
  // OnChange Ingredient Selection, Ingredient list according to thse setting
  useEffect(() => {
    // Update formula ingredient list with the received data
    var seperator = updateseperatorSign(data ? data.default_seperator : '')
    var ingredient_selection_type = data ? data.ingredient_selection_type : ''

    getFormulaIngredientList(
      seperator,
      ingredient_selection_type,
      ingredient_selection
    )
  }, [ingredient_selection])
  // ##############################################
  // General Section data handle
  const handleGeneralSectionChange = (
    newFormulaBuilderType,
    newFormulaBuilderName,
    newFormulaBuilderDecoction,
    newFormulaBuilderPatient,
    newFormulaBuilderDosageMode,
    newFormulaBuilderShareFormula,
    newFormulaBuilderPractice,
    newFormulaBuilderSupervisor,
    newFormulaBuilderTemplate,
    newFormulaConverting,
    newFormulaConvertingTo
  ) => {
    setformulaBuilderId(newFormulaBuilderType) // Update the formulaBuilderId when the select value changes
    setformulaBuilderDosageMode(newFormulaBuilderDosageMode)
    if (newFormulaBuilderDosageMode != formulaBuilderDosageMode) {
      setPrevFormulaBuilderDosageMode(formulaBuilderDosageMode)
      setPrevFormulaBuilderDosageModeKey(formulaBuilderDosageModeKey)
    }
    handleDosagesModeType(newFormulaBuilderDosageMode) // convert dosagemode id to vale like per,daily amd bag
    setformulaBuilderName(newFormulaBuilderName)
    setformulaBuilderDecoction(newFormulaBuilderDecoction)
    setformulaBuilderPatient(newFormulaBuilderPatient)
    setformulaBuilderShareFormula(newFormulaBuilderShareFormula)
    setformulaBuilderPractice(newFormulaBuilderPractice)
    setformulaBuilderSupervisor(newFormulaBuilderSupervisor)
    setformulaBuilderTemplate(newFormulaBuilderTemplate)
    setFormulaConverting(newFormulaConverting)
    setFormulaConvertingTo(newFormulaConvertingTo)
  }
  // Option Section data handle
  const handleOptionSectionChanges = (
    //newdefaultMeasuremt,
    //newdefaultMeasuremtName,
    newtotalQty,
    // newactiveDosages,
    newisCapsuleSelected,
    newcapsuleSizeSelected,
    newMinBasedQty,
    newMaxBasedQty
    // newnumberDays,
    // newnumberBags
  ) => {
    //console.log('=====newactiveDosages', newcapsuleSizeSelected)
    //setdefaultMeasuremt(newdefaultMeasuremt)
    //setdefaultMeasuremtName(newdefaultMeasuremtName)
    setTotalQty(newtotalQty)
    // setActiveDosages(newactiveDosages)
    setIsCapsuleSelected(newisCapsuleSelected)
    setCapsuleSizeSelected(newcapsuleSizeSelected)
    setMinBasedQty(newMinBasedQty)
    setMaxBasedQty(newMaxBasedQty)
    // setCapsuleQty(newcapsuleQty)
    //getApiDosagesData()
    // setNumberBags(newnumberBags)
    // setNumberDays(newnumberDays)
    // console.log(newnumberDays,'ssnewnumberDays')
  }
  const handleIngredientSelection = (new_ingredient_selection) => {
    setingredient_selection(new_ingredient_selection)
  }
  const updateDefaultMeasuremt = (
    newdefaultMeasuremt,
    newdefaultMeasuremtName,
    newmeasurementOptions,
    newallow_multiple_measurements
  ) => {
    setdefaultMeasuremt(newdefaultMeasuremt)
    setdefaultMeasuremtName(newdefaultMeasuremtName)
    setmeasurementOptions(newmeasurementOptions)
    setallow_multiple_measurements(newallow_multiple_measurements)
  }
  // Dosages Section data handle
  const HandleDosagesSectionChanges = (
    newusePresetDosageInstructions,
    newdosagesData,
    newallowOwnDosagesInstructions,
    newownDosagesInstructions,
    newdosagesText,
    newautomaticallyCalculateDosages,
    newallowUserToChangeAutomaticallyCalculateDosages
  ) => {
    setUsePresetDosageInstructions(newusePresetDosageInstructions)
    setdosagesData(newdosagesData)
    setdosagesText(newdosagesText)
    setAllowOwnDosagesInstructions(newallowOwnDosagesInstructions)
    setOwnDosagesInstructions(newownDosagesInstructions)
    setAutomaticallyCalculateDosages(newautomaticallyCalculateDosages)
    setAllowUserToChangeAutomaticallyCalculateDosages(
      newallowUserToChangeAutomaticallyCalculateDosages
    )
  }
  // ingredient section data handle
  const handleIngredientSectionChanges = (newIngredients) => {
    // console.log('handleIngredientSectionChanges-Main33', newIngredients)
    if (newIngredients && newIngredients.length != 0) {
      setIngredients(newIngredients)
      console.log('newIngredients', newIngredients)
      const finalSubTotalArr = newIngredients.map(
        (v) => parseFloat(v.formula_dosage_column_subtotal) || 0
      )
      const finalSubTotalSum = finalSubTotalArr.reduce(
        (accumulator, currentValue) =>
          parseFloat(accumulator) + parseFloat(currentValue),
        0
      )
      setPriceArr(finalSubTotalArr)
      setPrice(finalSubTotalSum)
    }

    // const finalSubTotalArr = newIngredients.map(
    //   (v) => parseFloat(v.formula_dosage_column_subtotal) || 0
    // )
    // setPriceArr(finalSubTotalArr) // update ingredient Price array update
    // const finalSubTotalSum = finalSubTotalArr.reduce(
    //   (accumulator, currentValue) =>
    //     parseFloat(accumulator) + parseFloat(currentValue),
    //   0
    // )
    // setPrice(finalSubTotalSum) // update ingredient price update
    //console.log('finalPriceArray', priceArr, price)
  }
  // Addon servies
  const handleServiesSectionChanges = (newServies) => {
    setaddOnServices(newServies)
    //console.log('handleServiesSectionChanges', newServies)
  }
  const handleAddOnServicesPrice = (newSelected) => {
    setSelectedAddonServices(newSelected)
  }
  // Markup Section data handle
  const handleMarkupSectionChanges = (
    newallowMarkup,
    newmarkup_type,
    newmarkup_amount
  ) => {
    setallowMarkup(newallowMarkup)
    setmarkup_type(newmarkup_type)
    setmarkup_amount(newmarkup_amount)
  }
  // Price Section data handle
  const handlePriceSectionChanges = (
    newingredientTotal,
    newaddOnServiesTotal,
    newsubtotalPrice,
    newmarkupTotal,
    newtotalPrice,
    newfeesTotal,
    newcapsulefeesTotal
  ) => {
    setIngredientsTotal(newingredientTotal)
    setAddOnServiesTotal(newaddOnServiesTotal)
    setSubTotalPrice(newsubtotalPrice)
    setMarkupTotal(newmarkupTotal)
    setTotalPrice(newtotalPrice)
    setFeesTotal(newfeesTotal)
    setcapsulefeesTotal(newcapsulefeesTotal)
  }
  const HandlePreprationInstructionsSectionChanges = (newherbTags) => {
    setHerbTags(newherbTags)
  }
  const HandleBlankPreprationInstructionsSectionChanges = (newData) => {
    //    console.log('blank_preparation_instructions_save',newData)
    setBlankHerbTags(newData)
  }
  //Refill section data handle
  const handleRefillSectionChange = (
    newrefill_no,
    newrefill_frequency,
    newrefill_date
  ) => {
    setrefill_no(newrefill_no)
    setrefill_frequency(newrefill_frequency)
    setrefill_date(newrefill_date)
  }
  // Ingredicnt Converter handle
  // const onIngredientConvertSectionChange = (
  //   newformulaBuilderId,
  //   newprevFormulaBuilderId,
  //   canConvert,
  //   newIngredients
  // ) => {
  //   if (canConvert == 1) {
  //   } else {
  //     setformulaBuilderId(newprevFormulaBuilderId)
  //   }
  // }

  const handleNumberDaysChange = (numberDays) => {
    if (numberDays != undefined) {
      //console.log('update numberDays', numberDays)
      setNumberDays(numberDays)
      Object.values(dosageSettings).map((array, key) => {
        if (array.key_value === 'DOSAGE_INPUT_NUMBER_OF_DAYS') {
          array.value = numberDays
        }
      })
      //setdosageSettings(dosageSettings)
    }
  }
  const handleNumberBagsChange = (newnumberBags) => {
    if (newnumberBags != undefined) {
      setNumberBags(newnumberBags)
    }
  }

  const handleActiveDosageChange = (newActiveDosages) => {
    setActiveDosages(newActiveDosages)
    //console.log('newActiveDosages', newActiveDosages)
  }
  const handleActiveOptionDosagesData = (array) => {
    setActiveOptionDosagesData(array)
  }
  //console.log('formulaFormData', formulaFormData)

  const handletotalQty = (data) => {
    if (
      //formulaDosagesSetting.conversion_type != 'do_not_convert' &&
      formulaDosagesSetting.weight_volume == '1'
    ) {
      setFormulaTotalQty(data)
    }
  }

  const handleDispensaryInstructions = (value) => {
    ///alert(value)
    setDispensaryInstructions(value)
  }

  // function to update form data state
  const handleFormData = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }
  //############################################
  const handleSectionFormData = (newData, index) => {
    //console.log('sectionVaidation', index, newData)
    if (index == 'FORMULAS_OPTIONS') {
      //CheckFormlaBundleweight(newData)
    }
    setformulaFormData((prevData) => {
      return {
        ...prevData,
        [index]: newData,
      }
    })
  }
  const getFormData = async () => {
    var IngredientArr = ingredients.filter(
      (v) => v.ingredient_id !== null && v.ingredient_id !== ''
    )

    var ServicesArr = addOnServices
    // .filter((v) => v.id !== null && v.id !== '')
    // .map((v) => v.id)
    console.log('selectedAddonServices', selectedAddonServices)
    var formArr = {
      id: formulaId,
      product_type: 'custom_formula',
      saved_formula: 1,
      formula_builder_id: formulaBuilderId,
      herb_type_common: data.name,
      //# General Section ############################
      name: formulaBuilderName,
      is_decocation: formulaBuilderDecoction,
      patient_id: formulaBuilderPatient,
      formula_t: formulaBuilderDosageMode, //formulaBuilderDosageModeType,
      formula_state: formulaBuilderShareFormula,
      practice: formulaBuilderPractice, //##
      supervisor: formulaBuilderSupervisor, //##
      template: formulaBuilderTemplate, //##
      //# Formula Options section #####################
      measurement: defaultMeasuremt, //##
      dosage_quantity: numberBags, //##
      dosage_noof_days: numberDays,
      //base_ingredient: data?.is_base_ingredient, // ##
      // is_capsules: '0',
      // is_tablet: '0',
      // is_softgels: '0',
      min_base_qty: minBasedQty, //##
      max_base_qty: maxBasedQty, //##
      set_totalweight_formula: totalQty,
      // # Dosages Section #################################
      do_not_use_on_label: usePresetDosageInstructions,
      dosage_input: dosagesData, //##
      dosage_input_preview: dosagesText, //##
      allow_additional_info_formula: allowOwnDosagesInstructions, //##
      additional_info_formula: ownDosagesInstructions,
      //additional_info_formula:
      // # Ingredient Section ##############################
      ingredients: IngredientArr,
      // # Pre Section ##############################
      preparation_instructions: herbTags,
      blank_preparation_instructions: blankHerbTags,
      // # Add On Services #################################
      add_on_services_details: ServicesArr, //addOnServices,
      selected_add_on_services: selectedAddonServices,
      // # Markup #################################
      allow_markup: data.markup,
      markup_type: markup_type,
      markup_amount: markup_amount,
      // # Price #################################
      ingredient_price: ingredientTotal,
      add_on_services_price: addOnServiesTotal,
      subtotal: subtotalPrice,
      markup_price: markupTotal,
      fees: feesTotal,
      capsule_fees: capsulefeesTotal,
      total_price: totalPrice,
      // # Refill  #################################
      refill_days: refill_no,
      refill_frequency: refill_frequency,
      refill_end_date: refill_date,
      // ...other properties

      dispensary_instructions: DispensaryInstructions,
      extra_data: formulaFormData,
    }
    if (is_order == 'true') {
      formArr['order_id'] = formulaDeatils?.order_id
      formArr['product_id'] = formulaDeatils?.order_id
      formArr['id'] = formulaDeatils?.id
    }

    if (activeDosages == 'capsules') {
      formArr['is_capsule'] = '1'
      formArr['is_tablet'] = '0'
      formArr['is_softgel'] = '0'
      formArr['capsule_size'] = capsuleSizeSelected
      formArr['selected_capsule_details'] = activeOptionDosagesData
    } else if (activeDosages == 'tablets') {
      formArr['is_tablet'] = '1'
      formArr['is_capsule'] = '0'
      formArr['is_softgel'] = '0'
      formArr['capsule_size'] = capsuleSizeSelected
      formArr['selected_capsule_details'] = activeOptionDosagesData
    } else if (activeDosages == 'softgels') {
      formArr['is_capsule'] = '0'
      formArr['is_tablet'] = '0'
      formArr['is_softgel'] = '1'
      formArr['capsule_size'] = capsuleSizeSelected
      formArr['selected_capsule_details'] = activeOptionDosagesData
    } else if (activeDosages == '') {
      formArr['is_capsule'] = '0'
      formArr['is_tablet'] = '0'
      formArr['is_softgel'] = '0'
      formArr['base_ingredient'] = capsuleSizeSelected
      formArr['selected_capsule_details'] = activeOptionDosagesData
    } else {
      formArr['is_capsule'] = '0'
      formArr['is_tablet'] = '0'
      formArr['is_softgel'] = '0'
      formArr['capsule_size'] = ''
      formArr['base_ingredient'] = capsuleSizeSelected
      formArr['selected_capsule_details'] = activeOptionDosagesData
    }
    //console.log('blank_preparation_instructions', blankHerbTags)
    return formArr
  }

  const formValidation = async () => {
    //await handleSectionFormData()
    const formData = getFormData()
    var errors = []
    var sectionError = []
    console.log('formData', formData, formulaFormData)
    Object.values(formulaFormData).forEach((fields, key) => {
      //console.log('fields', fields, key)
      //var sectionError = []
      Object.values(fields).map(function (item, index) {
        var error = item?.error
        //console.log('item', item)
        if (error != '' && error != null && error != undefined) {
          if (typeof error === 'string') {
            sectionError.push({ name: item.key, error: error })
            errors.push(error)
          } else {
            Object.values(error).forEach((i) => {
              if (i != '' && i != null && i != undefined) {
                sectionError.push({ name: item.key, error: i })
                errors.push(i)
              }
            })
          }
        }
      })

      /*if (sectionError.length != 0) {
        errors[key] = sectionError
      }*/
      //console.log('sectionError', key, sectionError)
    })
    console.log('sectionError', sectionError)
    return errors
  }

  // function to handle form submission
  const handleSubmitM = async (event = '', auto = false) => {
    if (event != '') {
      event.preventDefault()
    }
    if (!auto) {
      setApplyFormValidation(1)
      console.log('validation start')

      const desiredOrder = [
        'This field is required',
        'Please add min one ingredient',
        "Formula Weight/Volume can't be empty",
        // ... other messages in the desired order
      ]
      var validate = await formValidation()
      const validateArr = Array.from(new Set(validate))
      console.log('validateArr', validateArr)
      if (validateArr.length !== 0 && auto == false) {
        validateArr.map((msg) => {
          toaster.push(
            <Notification type='error' header={msg}></Notification>,
            {
              placement: 'topEnd',
              duration: 5000,
            }
          )
          return false
        })

        return false
      }
      if (CheckFormulaIngredientPrice(ingredients) == false) {
        toaster.push(
          <Notification
            type='error'
            header={'Ingredient Price could not be zero.'}
          ></Notification>,
          {
            placement: 'topEnd',
            duration: 5000,
          }
        )
        return false
      }
      var FORMULAS_OPTIONS_DATA =
        formulaFormData && formulaFormData['FORMULAS_OPTIONS']
          ? formulaFormData['FORMULAS_OPTIONS']
          : []
      //console.log('FORMULAS_OPTIONS_DATA',FORMULAS_OPTIONS_DATA,formulaFormData)
      var bundleWeightVolume = await CheckFormlaBundleweight(
        FORMULAS_OPTIONS_DATA
      )
      if (bundleWeightVolume && bundleWeightVolume.length != 0) {
        bundleWeightVolume.map((i) => {
          toaster.push(
            <Notification type='error' header={i.msg}></Notification>,
            {
              placement: 'topEnd',
              duration: 5000,
            }
          )
          return false
        })

        return false
      }
      console.log('validation end')
    }

    //console.log('validate', validate)

    const formData = await getFormData()

    if (!formulaId || !data.id) {
      toaster.push(
        <Notification
          type='error'
          header='Formula Id is missing.'
        ></Notification>,
        {
          placement: 'topEnd',
          duration: 5000,
        }
      )
      return false
    }
    console.log('formulaFormData', formulaFormData)
    console.log('submitForm', formData)
    //return false
    //return false
    const response = await ApiSaveFormulaDetails({
      formulaBuilderId,
      pracId,
      formulaId,
      formData,
      is_order,
    })
    const finalData = await response
    console.log('save form dat responce', finalData, {
      formulaBuilderId,
      pracId,
      formulaId,
      formData,
    })

    var position = auto == true ? 'topEnd' : 'bottomCenter'
    const msg = auto ? 'Synced' : 'Formula Saved Successfully'
    toaster.push(<Notification type='success' header={msg}></Notification>, {
      placement: position,
      duration: 5000,
    })
    if (!auto) {
      if (is_order == 'true') {
        // redirect to Order Details
        var url =
          BASEURL +
          'admin/control.php?Page=order&action=editorder&section=editorder&oid=' +
          formulaDeatils?.order_id
        openURL(url, '_parent')
      } else {
        var url = BASEURL + '?page=formula-review-new&' + 'id=' + formulaId
        openURL(url, '_parent')
      }
    }

    //   // code to submit form data to API endpoint
  }
  const handleSubmit = async (auto = false, url = '') => {
    //    if (event != '') {
    //      event.preventDefault()
    //    }
    if (!auto) {
      setApplyFormValidation(1)
      console.log('validation start')

      const desiredOrder = [
        'This field is required',
        'Please add min one ingredient',
        "Formula Weight/Volume can't be empty",
        // ... other messages in the desired order
      ]
      var validate = await formValidation()
      const validateArr = Array.from(new Set(validate))
      console.log('validateArr', validateArr)
      if (validateArr.length !== 0 && auto == false) {
        validateArr.map((msg) => {
          toaster.push(
            <Notification type='error' header={msg}></Notification>,
            {
              placement: 'topEnd',
              duration: 5000,
            }
          )
          return false
        })

        return false
      }
      var checkIng = await CheckFormulaIngredientPrice(ingredients)
      console.log('checkIng', checkIng)
      if (checkIng == false) {
        toaster.push(
          <Notification
            type='error'
            header={'Ingredient Price could not be zero.'}
          ></Notification>,
          {
            placement: 'topEnd',
            duration: 5000,
          }
        )
        return false
      }
      var FORMULAS_OPTIONS_DATA =
        formulaFormData && formulaFormData['FORMULAS_OPTIONS']
          ? formulaFormData['FORMULAS_OPTIONS']
          : []
      //console.log('FORMULAS_OPTIONS_DATA',FORMULAS_OPTIONS_DATA,formulaFormData)
      var bundleWeightVolume = await CheckFormlaBundleweight(
        FORMULAS_OPTIONS_DATA
      )
      if (bundleWeightVolume && bundleWeightVolume.length != 0) {
        bundleWeightVolume.map((i) => {
          toaster.push(
            <Notification type='error' header={i.msg}></Notification>,
            {
              placement: 'topEnd',
              duration: 5000,
            }
          )
          return false
        })

        return false
      }
      console.log('validation end')
    }

    //console.log('validate', validate)

    const formData = await getFormData()

    if (!formulaId || !data.id) {
      toaster.push(
        <Notification
          type='error'
          header='Formula Id is missing.'
        ></Notification>,
        {
          placement: 'topEnd',
          duration: 5000,
        }
      )
      return false
    }
    console.log('formulaFormData', formulaFormData)
    console.log('submitForm', formData)

    const response = await ApiSaveFormulaDetails({
      formulaBuilderId,
      pracId,
      formulaId,
      formData,
      is_order,
    })
    const finalData = await response
    console.log('save form dat responce', finalData, {
      formulaBuilderId,
      pracId,
      formulaId,
      formData,
    })

    var position = auto == true ? 'topEnd' : 'bottomCenter'
    const msg = auto ? 'Synced' : 'Formula Saved Successfully'
    toaster.push(<Notification type='success' header={msg}></Notification>, {
      placement: position,
      duration: 5000,
    })
    return true
    /*if (!auto) {
      if (is_order == 'true') {
        // redirect to Order Details
        var url =
          BASEURL +
          'admin/control.php?Page=order&action=editorder&section=editorder&oid=' +
          formulaDeatils?.order_id
        openURL(url, '_parent')
      } else {
        var url = BASEURL + '?page=formula-review-new&' + 'id=' + formulaId
        openURL(url, '_parent')
      }
    }*/

    //   // code to submit form data to API endpoint
  }
  const openURL = (url, target = '_blank', features = '') => {
    window.open(url, target, features)
    //console.log('openURL', url)
  }
  useEffect(() => {
    if (formulaConverting == true && formulaConvertingTo != formulaBuilderId) {
      buildNewFormula()
    }
  }, [formulaConverting, formulaConvertingTo])
  const buildNewFormula = async () => {
    //    await handleSubmit('', true, false)
    //    var url =
    //      BASEURL +
    //      '?page=formula_new&' +
    //      'builder_id=' +
    //      formulaConvertingTo +
    //      '&convet_to=' +
    //      formulaId +
    //      '&section=new'
    //    console.log('redirectToConvertedFrmula', url)
    //    openURL(url, '_parent')
    handleSaveFormula('convert')
  }
  useEffect(() => {
    IngredientformValidation()
  }, [ingredients])
  useEffect(() => {
    UpdateFormulaBuilderSetting()
  }, [data])
  const IngredientformValidation = () => {
    const filteredIngredients = ingredients.filter((ingredient) => {
      return (
        ingredient.ingredient_id != null &&
        ingredient.ingredient_id != '' &&
        ingredient.ingredient_id != undefined
      )
    })
    const totalQtyReceived = filteredIngredients.reduce(
      (accumulator, ingredient) => {
        // Convert formula_dosage_column_qty_received to a number using parseFloat
        const qtyReceived = parseFloat(
          ingredient.formula_dosage_column_qty_received
        )
        // Check if qtyReceived is a valid number
        if (!isNaN(qtyReceived)) {
          // Add the valid qtyReceived to the accumulator
          return accumulator + qtyReceived
        }
        // If formula_dosage_column_qty_received is not a valid number, return the current accumulator
        return accumulator
      },
      0
    )
    var requirements = [
      {
        column: 'ingredients',
        name: 'Ingredients',
        is_required: 0,
        key: 'ingredients',
        allow_zero: false,
      },
      {
        column: 'ingredients_count',
        name: 'Ingredients Count',
        is_required: 1,
        key: 'ingredients_count',
        allow_zero: false,
      },
      {
        column: 'ingredients_qty',
        name: 'Ingredients Total Qty',
        is_required: 1,
        key: 'ingredients_qty',
        allow_zero: false,
      },
      {
        column: 'ingredients_herb_tags',
        name: 'Ingredients Prepration Ins. Tags',
        is_required: 0,
        key: 'ingredients_herb_tags',
        allow_zero: false,
      },
      {
        column: 'ingredient_selection_type',
        name: 'Ingredients Selection Type',
        is_required: 0,
        key: 'ingredient_selection_type',
        allow_zero: false,
      },
      {
        column: 'allow_multiple_measurement_types_in_same_formula',
        name: 'allow_multiple_measurement_types_in_same_formula',
        is_required: 0,
        key: 'allow_multiple_measurement_types_in_same_formula',
        allow_zero: false,
      },
    ]
    var formError = {
      ingredients: '',
      ingredients_count: '',
      ingredients_qty: '',
    }
    var formValue = {
      ingredients: filteredIngredients,
      ingredients_count: filteredIngredients.length,
      ingredients_qty: totalQtyReceived,
      ingredients_herb_tags: ingrediuentsTags ? ingrediuentsTags : [],
      allow_multiple_measurement_types_in_same_formula:
        allow_multiple_measurements,
      ingredient_selection_type: data?.ingredient_selection_type,
    }

    var Final_array = []
    if (requirements) {
      Object.values(requirements).map((i, key) => {
        if (i.is_required == 1) {
          if (i.allow_zero == true) {
            if (
              String(formValue[i.key]).trim() == '' ||
              formValue[i.key] == null ||
              formValue[i.key] == undefined
            ) {
              if (i.key == 'ingredients_count') {
                formError[i.key] = 'Please add min one ingredient'
              } else if (i.key == 'ingredients_qty') {
                formError[i.key] = 'Formual Weight/Volume can`t be emplty'
              } else {
                formError[i.key] = 'This field is required'
              }
            } else {
              formError[i.key] = ''
            }
          } else {
            if (
              String(formValue[i.key]).trim() == '' ||
              formValue[i.key] == null ||
              formValue[i.key] == 0 ||
              formValue[i.key] == undefined
            ) {
              if (i.key == 'ingredients_count') {
                formError[i.key] = 'Please add min one ingredient'
              } else if (i.key == 'ingredients_qty') {
                formError[i.key] = 'Formual Weight/Volume can`t be emplty'
              } else {
                formError[i.key] = 'This field is required'
              }
            } else {
              formError[i.key] = ''
            }
          }
        }
        var temp = {
          ...i,
          error: formError[i.key],
          value: formValue[i.key],
        }
        Final_array.push(temp)
      })
    }
    handleSectionFormData(Final_array, 'FORMULAS_FORMULA_INGREDIENTS')
  }
  const CheckFormlaBundleweight = async (array) => {
    var error = []
    var ingredient_selection_type = data.ingredient_selection_type
    var measurementList =
      data && data.measurment_type_list ? data.measurment_type_list : []
    var active_dosages = ''
    var active_dosages_arr = ''
    var min_qty = 0
    var max_qty = 0
    var weight_volume = 0
    if (array.length != 0) {
      array.map((item) => {
        if (item.column == 'active_option_dosages') {
          active_dosages = item.value
        } else if (item.column == 'active_option_dosages_array') {
          active_dosages_arr = item.value
        } else if (item.column == 'min_qty') {
          min_qty = item.value
        } else if (item.column == 'max_qty') {
          max_qty = item.value
        } else if (item.column == 'weight_volume') {
          weight_volume = item.value
        }
      })
    }

    var ingredientWeightVolumePerUnit = []
    ingredients.forEach((item) => {
      const measurementid = item['default_measurment']

      if (item && item.ingredient_id != 0 && item.ingredient_id != '') {
        // if (!ingredientWeightVolumePerUnit[measurementid] && measurementid) {
        //   ingredientWeightVolumePerUnit[measurementid] = []
        // }
        var qty = item['formula_dosage_column_qty_received']
          ? item['formula_dosage_column_qty_received']
          : 0
        if (qty) {
          //ingredientWeightVolumePerUnit[measurementid].push(Qty)
          ingredientWeightVolumePerUnit.push({ unit: measurementid, qty: qty })
        }
      }
    })
    var IngredientWeight = {}
    if (ingredientWeightVolumePerUnit.length != 0) {
      IngredientWeight = ingredientWeightVolumePerUnit.reduce(
        (result, current) => {
          const key = current.unit
          var unit_name = ''
          const foundOption = Object.values(measurementList).find((option) => {
            return key == option['id']
          })
          if (foundOption) {
            unit_name = foundOption?.display_name
          }

          if (!result[key]) {
            result[key] = { unit: key, unit_name: unit_name, qty: 0 }
          }
          result[key].qty += parseFloat(current.qty)
          return result
        },
        {}
      )
    }
    //console.log('CheckFormlaBundleweight','perUnitQty',ingredientWeightVolumePerUnit,IngredientWeight)
    //console.log('CheckFormlaBundleweight', min_qty, max_qty)
    var rangeArr = []
    var allow_range_setting = 0
    var bundle_name = ''
    if (active_dosages_arr) {
      //ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE
      //ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES
      //MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_PER_INGREDIENT
      //MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS
      //MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT
      //  const [formulaSummaryData, setSummaryData] = useState('')
      console.log(
        'CheckFormlaBundleweight',
        'active_dosages_arr',
        active_dosages_arr,
        active_dosages
      )
      //console.log('CheckFormlaBundleweight',{ingredient_selection_type:ingredient_selection_type,allow_multiple_measurements:allow_multiple_measurements,data_allow_multiple_measurements:data.allow_multiple_measurement_types_in_same_formula})
      if (active_dosages_arr && active_dosages_arr.id && active_dosages == '') {
        bundle_name = 'Base Ingredient'
        if (
          ingredient_selection_type ==
          'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE'
        ) {
          var temp = {
            unit: active_dosages_arr.unit,
            from: active_dosages_arr.weight_from,
            to: active_dosages_arr.weight_to,
          }
          rangeArr.push(temp)
          allow_range_setting = 1
        } else if (
          ingredient_selection_type ==
          'ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES'
        ) {
          if (allow_multiple_measurements == false) {
            var perUnit = active_dosages_arr.extra
              ? active_dosages_arr.extra
              : []

            if (perUnit && Object.values(perUnit).length != 0) {
              Object.values(perUnit).map((item) => {
                var temp = {
                  unit: item.measurement_type,
                  from: item.weight_from,
                  to: item.weight_to,
                }
                rangeArr.push(temp)
              })
            }
            allow_range_setting = 1
          } else if (allow_multiple_measurements == true) {
            var temp = {
              unit: active_dosages_arr.measurement_type,
              from: active_dosages_arr.weight_from,
              to: active_dosages_arr.weight_to,
            }
            rangeArr.push(temp)
            allow_range_setting = 1
          }
        } else if (
          ingredient_selection_type ==
          'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_PER_INGREDIENT'
        ) {
          var temp = {
            unit: active_dosages_arr.measurement_type,
            from: active_dosages_arr.weight_from,
            to: active_dosages_arr.weight_to,
          }
          rangeArr.push(temp)
          allow_range_setting = active_dosages_arr.allow_min_max_setting
        } else if (
          ingredient_selection_type ==
          'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS'
        ) {
          var temp = {
            unit: active_dosages_arr.unit,
            from: active_dosages_arr.weight_from,
            to: active_dosages_arr.weight_to,
          }
          rangeArr.push(temp)
          allow_range_setting = active_dosages_arr.allow_min_max_setting
        } else if (
          ingredient_selection_type ==
          'MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT'
        ) {
          if (data.allow_multiple_measurement_types_in_same_formula == false) {
            var perUnit = active_dosages_arr.extra
              ? active_dosages_arr.extra
              : []

            if (perUnit && Object.values(perUnit).length != 0) {
              Object.values(perUnit).map((item) => {
                var temp = {
                  unit: item.measurement_type,
                  from: item.weight_from,
                  to: item.weight_to,
                }
                rangeArr.push(temp)
              })
            }
            allow_range_setting = active_dosages_arr.allow_min_max_setting
          } else if (
            data.allow_multiple_measurement_types_in_same_formula == true
          ) {
            var temp = {
              unit: active_dosages_arr.measurement_type,
              from: active_dosages_arr.weight_from,
              to: active_dosages_arr.weight_to,
            }
            rangeArr.push(temp)
            allow_range_setting = active_dosages_arr.allow_min_max_setting
          }
        }
      } else if (
        active_dosages_arr &&
        active_dosages_arr.id &&
        active_dosages != ''
      ) {
        bundle_name = capitalizeWords(active_dosages)
        if (
          ingredient_selection_type ==
            'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE' ||
          ingredient_selection_type ==
            'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS'
        ) {
          var temp = {
            unit: active_dosages_arr.unit,
            from: active_dosages_arr.weight_from,
            to: active_dosages_arr.weight_to,
          }
          rangeArr.push(temp)
          allow_range_setting = 1
        }
      }

      console.log(
        'CheckFormlaBundleweight',
        'rangeArr',
        rangeArr,
        allow_range_setting,
        IngredientWeight
      ) //allow_min_max_setting
    }
    if (
      rangeArr &&
      rangeArr.length != 0 &&
      IngredientWeight &&
      Object.values(IngredientWeight).length != 0
    ) {
      Object.values(IngredientWeight).map((item) => {
        var unit = item.unit
        var qty = Number(item.qty)
        var findRange = rangeArr.find((type) => type.unit == unit)
        if (
          (ingredient_selection_type ==
            'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE' ||
            ingredient_selection_type ==
              'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS') &&
          rangeArr.length == 1
        ) {
          var findRange = rangeArr && rangeArr[0] ? rangeArr[0] : []
          if (findRange) {
            if (allow_range_setting == true) {
              if (
                (qty >= Number(findRange.from) &&
                  qty <= Number(findRange.to)) == false
              ) {
                //console.log('CheckFormlaBundleweight',`${qty} is not between ${findRange.from}${item.unit_name} to ${findRange.to}${item.unit_name}.`);
                error.push({
                  msg: `Selected ${bundle_name} allows ${findRange.from}${item.unit_name} to ${findRange.to}${item.unit_name} `,
                })
              }
            }
          }
        } else {
          //console.log('CheckFormlaBundleweight', 'findRange', findRange)
          if (findRange) {
            if (allow_range_setting == true) {
              if (
                (qty >= Number(findRange.from) &&
                  qty <= Number(findRange.to)) == false
              ) {
                error.push({
                  msg: `Selected ${bundle_name} allows ${findRange.from}${item.unit_name} to ${findRange.to}${item.unit_name}  `,
                })
              }
            }
          }
        }
      })
    }
    console.log('bundleErrors', error)
    return error
  }
  const UpdateFormulaBuilderSetting = () => {
    if (data) {
      const prac_formula_setting =
        pracDetails && pracDetails.formula_setting
          ? JSON.parse(pracDetails.formula_setting)
          : []
      const prac_setting =
        prac_formula_setting && prac_formula_setting[data.name]
          ? prac_formula_setting[data.name]
          : []
      var requirements = [
        {
          column: 'formula_bilder',
          name: 'formula_bilder',
          is_required: 0,
          key: 'formula_bilder',
          allow_zero: false,
        },
        {
          column: 'prac_setting',
          name: 'prac_setting',
          is_required: 0,
          key: 'prac_setting',
          allow_zero: false,
        },
      ]
      var formValue = {
        formula_bilder: data,
        prac_setting: prac_setting,
      }
      var Final_array = []
      Object.values(requirements).map((i, key) => {
        var temp = {
          ...i,
          error: '',
          value: formValue[i.key],
        }
        Final_array.push(temp)
      })
      handleSectionFormData(Final_array, 'FORMULAS_SETTING')
    }
  }
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  const CheckFormulaIngredientPrice = async (array) => {
    var error = 0
    if (array.length != 0) {
      array.map((item) => {
        if (item.ingredient_id != '') {
          var price = item.formula_dosage_column_subtotal
            ? Number(item.formula_dosage_column_subtotal)
            : 0
          if (!price || price == 0 || isNaN(price)) {
            error = 1
          }
        }
      })
    }
    if (error == 0) {
      return true
    }
    return false
  }

  const unsetPrevDosagesType = () => {
    // after chnages dosages mode && Ingredient Calculation unset prev dosage type data
    console.log('unset prevDosageKey')
    setPrevFormulaBuilderDosageMode(null)
    setPrevFormulaBuilderDosageModeKey(null)
  }
  const handleSaveFormula = async (type = '') => {
    if (type == 'convert') {
      var status = await handleSubmit(true)
      console.log('status', type, status)
      if (status && status == true) {
        var url =
          BASEURL +
          '?page=formula_new&' +
          'builder_id=' +
          formulaConvertingTo +
          '&convet_to=' +
          formulaId +
          '&section=new'
        console.log('redirectToConvertedFrmula', url)
        openURL(url, '_parent')
      }
    } else if (type == 'order') {
      var status = await handleSubmit()
      console.log('status', type, status)
      if (status && status == true) {
        var url = BASEURL + '?page=formula_new&section=order&id=' + formulaId
        openURL(url, '_parent')
      }
    } else if (type == 'save') {
      var status = await handleSubmit()
      console.log('status', type, status)
      if (status && status == true) {
        var url = BASEURL + '?page=formula-review-new&' + 'id=' + formulaId
        openURL(url, '_parent')
      }
    } else if (type == 'cart') {
      var status = await handleSubmit()
      console.log('status', type, status)
      if (status && status == true) {
        var url =
          BASEURL + '?page=formula_new&section=addtocart&id=' + formulaId
        openURL(url, '_parent')
      }
    } else if (type == 'save_order') {
      var status = await handleSubmit()
      console.log('status', type, status)
      if (status && status == true) {
        if (is_order == 'true') {
          // redirect to Order Details
          var url =
            BASEURL +
            'admin/control.php?Page=order&action=editorder&section=editorder&oid=' +
            formulaDeatils?.order_id
          openURL(url, '_parent')
        }
      }
    } else {
      var status = await handleSubmit()
      console.log('status', type, status)
      if (status && status == true) {
        if (is_order == 'true') {
          // redirect to Order Details
          var url =
            BASEURL +
            'admin/control.php?Page=order&action=editorder&section=editorder&oid=' +
            formulaDeatils?.order_id
          openURL(url, '_parent')
        } else {
          var url = BASEURL + '?page=formula-review-new&' + 'id=' + formulaId
          openURL(url, '_parent')
        }
      }
    }
  }
  //##########################################
  if (formulaError == true && formulaErrorMsg != null && formulaId == '') {
    var errortext = (
      <>
        <Icon
          as={FaExclamationTriangle}
          size='2em'
          style={{ color: 'red', marginTop: '-0.5rem' }}
        />
        <span
          style={{
            color: 'red',
            marginLeft: '0.5rem',
            marginTop: '-0.5rem',
            fontSize: '1.5rem',
          }}
        >
          {formulaErrorMsg}
        </span>
      </>
    )
    return (
      <div>
        <Placeholder.Graph active />
        <Loader
          size='md'
          backdrop
          speed='slow'
          vertical
          center
          content={errortext}
        />
      </div>
    )
  }
  if (
    pracId == null ||
    pracId == '' ||
    pracId == undefined ||
    validPrac == false
  ) {
    var errortext = (
      <>
        <Icon
          as={FaExclamationTriangle}
          size='2em'
          style={{ color: 'red', marginTop: '-0.5rem' }}
        />
        <span
          style={{
            color: 'red',
            marginLeft: '0.5rem',
            marginTop: '-0.5rem',
            fontSize: '1.5rem',
          }}
        >
          Practitioner is missing.
        </span>
      </>
    )

    return (
      <div>
        <Placeholder.Graph active />
        {/* <Placeholder.Paragraph rows={19} /> */}
        <Loader
          size='md'
          backdrop
          speed='slow'
          vertical
          center
          content='loading...'
        />
      </div>
    )
  }
  if (
    formulaBuilderId == null ||
    formulaBuilderId == '' ||
    formulaBuilderId == undefined
  ) {
    var errortext = (
      <>
        <Icon as={FaExclamationTriangle} size='2em' style={{ color: 'red' }} />
        <span
          style={{ color: 'red', marginLeft: '0.5rem', fontSize: '1.5rem' }}
        >
          Formula Builder is missing.
        </span>
      </>
    )

    return (
      <div>
        <Placeholder.Graph active />
        {/* <Placeholder.Paragraph rows={19} /> */}
        <Loader
          size='md'
          backdrop
          speed='slow'
          vertical
          center
          content={errortext}
        />
      </div>
    )
  }
  if (loading || data == null) {
    return (
      <div>
        <Placeholder.Paragraph rows={19} />
        <Loader
          size='md'
          backdrop
          speed='slow'
          vertical
          center
          content='loading...'
        />
      </div>
    )
  }

  if (data && data != null) {
    var mainDivStyle = {}
    var titleStyle = {}
    // var mainDivStyle = {
    //   fontSize: data.text_font_size + 'px',
    //   fontFamily: data.text_font,
    //   color: data.text_color,
    // }
    // var titleStyle = {
    //   fontSize: data.title_font_size + 'px',
    //   fontFamily: data.title_font,
    //   color: data.title_color,
    //   backgroundColor: data.title_bg_color,
    //   marginLeft: data.title_margin_left,
    //   marginTop: data.title_margin_top,
    //   marginBottom: data.title_margin_bottom,
    //   marginRight: data.title_margin_right,
    //   textDecoration: data.title_underline,
    //   fontWeight: data.title_bold,
    // }
  } else {
    var mainDivStyle = {}
    var titleStyle = {}
  }

  return (
    <div
      className='FormulaBuilder container-fluid'
      data-testid='FormulaBuilder'
      style={mainDivStyle}
    >
      <h5 class='formula-title' style={titleStyle}>
        <b>
          {is_order ? 'Edit Order ' : 'Create a New'} {data.display_name}{' '}
          Formula
        </b>
      </h5>
      {/* onSubmit={handleSubmit} */}
      <form>
        {Object.entries(formulaSections).length !== 0 &&
          Object.entries(formulaSections).map(([key, value], index) => {
            if (value.is_active == 1) {
              if (value.key_value == 'FORMULAS_GENERAL_DETAILS') {
                return (
                  <FormulaBuilderGeneralSection
                    display_name={value.display_name}
                    onGeneralSectionChange={handleGeneralSectionChange}
                    formulaBuilderId={formulaBuilderId}
                    data={data}
                    numberDays={numberDays}
                    pracDetails={pracDetails}
                    formulaList={formulaList}
                    dosageSettings={dosageSettings} // all Dosages types
                    onNumberDaysChange={handleNumberDaysChange}
                    formulaDeatils={formulaDeatils} // important
                    DosageMode={formulaBuilderDosageMode}
                    ApplyFormValidation={ApplyFormValidation}
                    thisFormData={handleSectionFormData}
                    dosages_converting={setDosageConverting}
                    formula_converting={setFormulaConverting}
                    is_order={is_order}
                  />
                )
              } else if (value.key_value == 'FORMULAS_OPTIONS') {
                return (
                  <>
                    <FormulaBuilderOptionsSection
                      display_name={value.display_name}
                      formulaBuilderId={formulaBuilderId}
                      formulaDosagesType={formulaBuilderDosageModeType}
                      data={data}
                      numberDays={numberDays}
                      onNumberDaysChange={handleNumberDaysChange}
                      onNumberBagsChange={handleNumberBagsChange}
                      onOptionsSectionChange={handleOptionSectionChanges}
                      activeDosages={activeDosages}
                      pracDetails={pracDetails}
                      onActiveDosageDaysChange={handleActiveDosageChange}
                      formulaDosagesSetting={formulaDosagesSetting}
                      formulaDeatils={formulaDeatils} // important
                      updateDefaultMeasuremt={updateDefaultMeasuremt}
                      updateIngredientSelection={handleIngredientSelection}
                      ingredients={ingredients}
                      pracId={pracId}
                      formulaOptionCheck={handleFormulaOption}
                      formulaTotalQty={handletotalQty}
                      setConcentrates={setConcentrates}
                      activeOptionDosagesData={handleActiveOptionDosagesData}
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData}
                      dosageConverting={dosageConverting}
                      formulaConverting={formulaConverting}
                      dosageId={formulaBuilderDosageMode}
                    />
                  </>
                )
              } else if (value.key_value == 'FORMULAS_DOSAGES') {
                return (
                  <>
                    <FormulaBuilderDosagesSection
                      display_name={value.display_name}
                      formulaBuilderId={formulaBuilderId}
                      formulaDosagesType={formulaBuilderDosageModeType}
                      data={data}
                      dosageSettings={dosageSettings} // all Dosages modes data
                      activeDosages={activeDosages}
                      numberDays={numberDays}
                      onNumberDaysChange={handleNumberDaysChange}
                      formulaDosagesSetting={formulaDosagesSetting} // selected dosages mode data
                      onchange={HandleDosagesSectionChanges}
                      formulaDeatils={formulaDeatils} // important
                      dosageKey={formulaBuilderDosageModeKey}
                      measurementOptions={measurementOptions}
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData}
                      pracDetails={pracDetails}
                      measurmentConversions={measurmentConversions}
                      ingredient_selection_type={data.ingredient_selection_type}
                      dosageConverting={dosageConverting}
                      formulaConverting={formulaConverting}
                      ingredients={ingredients}
                      dosageId={formulaBuilderDosageMode}
                    />
                  </>
                )
              } else if (value.key_value == 'FORMULAS_FORMULA_INGREDIENTS') {
                const prac_formula_setting =
                  pracDetails && pracDetails.formula_setting
                    ? JSON.parse(pracDetails.formula_setting)
                    : []
                const prac_setting =
                  prac_formula_setting && prac_formula_setting[data.name]
                    ? prac_formula_setting[data.name]
                    : []

                /////////////////////////
                var converting = []
                const prev_converting = []
                // const converting = data ? JSON.parse(data.converting) : []
                // const prev_converting =
                //   prevdata && prevdata.converting
                //     ? JSON.parse(prevdata.converting)
                //     : []

                return (
                  <>
                    <FormulaBuilderIngredientsSection
                      formulaBuilderId={formulaBuilderId}
                      formulaData={data}
                      //initialColumns={columns} //columns,tableColumns
                      initialData={ingredients}
                      formulaIngredients={formulaIngredients}
                      dosagesBags={numberBags}
                      dosagesDays={numberDays}
                      formulaDosagesSetting={formulaDosagesSetting}
                      ingrediuentsTags={ingrediuentsTags}
                      currency={currency}
                      pracId={pracId}
                      prac_setting={prac_setting}
                      dacimalPlace={2} // default set manual
                      dosages_key={formulaDosagesSetting?.value}
                      seperator={seperator}
                      formulaIngredientsIdArr={formulaIngredientsIdArr}
                      nameTypes={nameTypes}
                      converting={converting}
                      prev_converting={prev_converting}
                      prevBuilderKey={data.name}
                      defaultMeasuremt={defaultMeasuremt}
                      defaultMeasuremtName={defaultMeasuremtName}
                      measurementOptions={measurementOptions}
                      allow_multiple_measurements={allow_multiple_measurements}
                      ingredient_selection_type={data.ingredient_selection_type}
                      measurment_type_list={data.measurment_type_list}
                      formulaDosagesType={formulaBuilderDosageModeType}
                      formulaDosagesKey={formulaBuilderDosageModeKey}
                      formulaDeatils={formulaDeatils} // important
                      setItemData={handleIngredientSectionChanges}
                      patient_id={formulaBuilderPatient}
                      allow_ingredients_type={ingredient_selection}
                      ingredient_decimal={ingredient_decimal}
                      measurmentConversions={measurmentConversions}
                      //setAbc={setAbc}
                      summaryData={handleSummaryData}
                      formulaOption={formulaOptionCheck}
                      activeOptionDosagesData={handleActiveOptionDosagesData}
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData}
                      setConcentrates={setConcentrates}
                      formulaTotalQty={handletotalQty}
                      concentrates={concentrates}
                      totalQty={formulaTotalQty}
                      loadingTable={loadingTable}
                      dosageConverting={dosageConverting}
                      formulaConverting={formulaConverting}
                      prevDosageKey={prevFormulaBuilderDosageModeKey}
                      updateprevDosageKey={unsetPrevDosagesType}
                    />
                  </>
                )
              } else if (
                value.key_value == 'FORMULAS_PREPARATION_INSTRUCTIONS'
              ) {
                return (
                  <>
                    <FormulaBuilderWithoutTagPreprationInstructionsSection
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      display_name={value}
                      ApplyFormValidation={ApplyFormValidation}
                      onChange={HandleBlankPreprationInstructionsSectionChanges}
                      formulaDeatils={formulaDeatils} // important
                      thisFormData={handleSectionFormData}
                    />
                    <FormulaBuilderPreprationInstructionsSection
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      currency={currency}
                      ingrediuentsTags={ingrediuentsTags}
                      ingredients={ingredients}
                      onChange={HandlePreprationInstructionsSectionChanges}
                      formulaDeatils={formulaDeatils}
                      index={index}
                      display_name={value}
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData} //Done
                    />
                  </>
                )
              } else if (value.key_value == 'FORMULAS_ADD_ON_SERVICES') {
                return (
                  <FormulaBuilderServicesSection
                    formulaBuilderId={formulaBuilderId}
                    data={data}
                    currency={currency}
                    display_name={value}
                    onServicesSectionChange={handleServiesSectionChanges}
                    formulaDeatils={formulaDeatils} // important
                    ApplyFormValidation={ApplyFormValidation}
                    thisFormData={handleSectionFormData} //Done
                    selectedAddOnServices={handleAddOnServicesPrice}
                    price={price} // Ingredient price need to calculate addon services final price to save
                  />
                )
              } else if (value.key_value == 'FORMULAS_REFILLS') {
                return (
                  <FormulaBuilderRefillSection
                    onRefillSectionChange={handleRefillSectionChange}
                    formulaBuilderId={formulaBuilderId}
                    data={data}
                    pracDetails={pracDetails}
                    display_name={value}
                    formulaDeatils={formulaDeatils} // important
                    ApplyFormValidation={ApplyFormValidation}
                    thisFormData={handleSectionFormData} //Done
                  />
                )
              } else if (value.key_value == 'FORMULAS_MARKUP') {
                return (
                  <FormulaBuilderMarkupSection
                    formulaBuilderId={formulaBuilderId}
                    data={data}
                    price={price}
                    currency={currency}
                    onMarkupSectionChange={handleMarkupSectionChanges}
                    pracDetails={pracDetails}
                    display_name={value}
                    formulaDeatils={formulaDeatils} // important
                    ApplyFormValidation={ApplyFormValidation}
                    thisFormData={handleSectionFormData} //Done
                  />
                )
              } else if (value.key_value == 'FORMULAS_PRICE') {
                return (
                  <FormulaBuilderPriceSection
                    formulaBuilderId={formulaBuilderId}
                    data={data}
                    price={price}
                    currency={currency}
                    allowMarkup={allowMarkup}
                    markup_type={markup_type}
                    markup_amount={markup_amount}
                    addOnServices={addOnServices}
                    ingredients={ingredients}
                    priceArr={priceArr}
                    display_name={value}
                    onChange={handlePriceSectionChanges}
                    formulaDosagesSetting={formulaDosagesSetting}
                    numberBags={numberBags}
                    activeOptionDosagesData={activeOptionDosagesData}
                    formulaDosagesType={formulaBuilderDosageModeType}
                    activeDosages={activeDosages}
                    ApplyFormValidation={ApplyFormValidation}
                    thisFormData={handleSectionFormData} //Done
                    formulaDeatils={formulaDeatils} // important
                  />
                )
              } else if (value.key_value == 'FORMULAS_SUMMARY') {
                return (
                  <FormulaBuilderSummarySection
                    formulaBuilderId={formulaBuilderId}
                    data={data}
                    price={price}
                    currency={currency}
                    // formulaSummary={summaryReducer.data}
                    formulaSummary={formulaSummaryData}
                    ApplyFormValidation={ApplyFormValidation}
                    thisFormData={handleSectionFormData} //Done
                    display_name={value.display_name}
                    allowRawQty={AllowRawQty}
                    allowEnterQty={AllowEnterQty}
                  />
                )
              } else if (
                value.key_value == 'FORMULAS_DISPENSARY_INSTRUCTIONS'
              ) {
                return (
                  <FormulaBuilderDispensaryInstructions
                    formulaBuilderId={formulaBuilderId}
                    data={data}
                    display_name={value.display_name}
                    formulaDeatils={formulaDeatils} // important
                    ApplyFormValidation={ApplyFormValidation}
                    onInsUpdate={handleDispensaryInstructions}
                    thisFormData={handleSectionFormData} //Done
                  />
                )
              } else if (
                value.key_value == 'FORMULAS_CAUTION_AND_CONTRADICTION'
              ) {
              } else if (value.key_value == 'FORMULAS_DRUG_INTERACTIONS') {
              } else if (value.key_value == 'FORMULAS_HERB_INTERACTION') {
              } else if (value.key_value == 'FORMULAS_ALLERGY_INFORMATION') {
              } else if (value.key_value == 'FORMULAS_TOXIC') {
              } else if (value.key_value == 'FORMULAS_INGREDIENT_NOTES') {
              }
            }
          })}
        {/* save,Submit to Admin && Add to Cart await handleSubmit()  */}
        {/* {Object.entries(formulaSections).length !== 0 ? (
          <>
            <FormulaSaveButton formulaId={formulaId} />
            <FormulaAddToCartButton formulaId={formulaId} />
          </>
        ) : null} */}
        {Object.entries(formulaSections).length !== 0 && (
          <>
            <FormulaSaveButton
              formulaId={formulaId}
              onchnage={handleSaveFormula}
              pracDetails={pracDetails}
              is_order={is_order}
            />
          </>
        )}

        {/* <FormulaBuilderIngredientConvert
          formulaBuilderId={formulaBuilderId}
          prevFormulaBuilderId={prevFormulaBuilderId}
          data={data}
          prevBuilder={prevFormulaBuilderType}
          prevIngredients={prevFormulaBuilderIngredients}
          ingredients={ingredients}
          onIngredientConvertSectionChange={onIngredientConvertSectionChange}
        /> */}
      </form>
    </div>
  )
}
FormulaBuilder.propTypes = {}

FormulaBuilder.defaultProps = {}

export default FormulaBuilder
// ApplyFormValidation={ApplyFormValidation}
// thisFormData={handleSectionFormData} //Done
// concentrates={concentrates}
// totalQty={formulaTotalQty}
