import React, { useEffect, useMemo, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import debounce from 'lodash.debounce'
//import Form from 'react-bootstrap/Form'
import CustomFooterTable from './customFooterTable.js'
import { useDrag, useDrop, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import { useSpring, config } from 'react-spring';

import {
  Table,
  IconButton,
  SelectPicker,
  InputNumber,
  Drawer,
  ButtonToolbar,
  Button,
  Placeholder,
  Nav,
  Message,
  Form,
} from 'rsuite'

import {
  Loader,
  Popover,
  Whisper,
  Tooltip,
  Notification,
  useToaster,
} from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { connect } from 'react-redux'

import { useDispatch, useSelector } from 'react-redux'
import { summaryRequest } from '../FormulaBuilderSummarySection/summary.action'

import TrashIcon from '@rsuite/icons/Trash'
import DragableIcon from '@rsuite/icons/Dragable'
import VisibleIcon from '@rsuite/icons/Visible'

import { capitalize } from '@mui/material'

import { produce } from 'immer'
import { v4 as uuidv4 } from 'uuid'
import Alert from 'react-bootstrap/Alert'

//import { IngredientSelect } from './ingredientselect'
import {
  ApiFormulaBulderIngredientDetails,
  ApiFormulaIngredientTabData,
  ApiFormulaBulderMultipleIngredientDetails,
} from '../../../services/api/api_formula_builder'
import FormulaBuilder from '../FormulaBuilder'

import IngredientView from './IngredientView'
import IngredientSelect from './IngredientSelect'
import { TabDropdown } from './TabDropdown'
import IngredientSearch from './IngredientSearch'
import { customNotificationMessage } from './customNotificationMessage.js'

const { HeaderCell, Cell, Column } = Table

const ItemTypes = {
  COLUMN: 'column',
  ROW: 'row',
}

function DraggableHeaderCell({ children, onDrag, id, ...rest }) {
  const ref = React.useRef(null)

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item, monitor) {
      onDrag(item.id, id)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { id },
    type: ItemTypes.COLUMN,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const isActive = canDrop && isOver

  drag(drop(ref))

  const styles = {
    padding: '0.6rem 1rem',
    cursor: 'grab',
    opacity: isDragging ? 0 : 1,
    borderLeft: isActive ? '2px solid #2589f5' : null,
    flexGrow: 1,
    fontSize: 16,
    textAlign: 'center',
    textTransform: 'capitalize',
  }

  return (
    <HeaderCell
      {...rest}
      style={{
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div ref={ref} style={styles}>
        {children}
      </div>
    </HeaderCell>
  )
}

function Row({ children, onDrag, id, rowData, ...rest }) {
  const ref = React.useRef(null)

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.ROW,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item, monitor) {
      onDrag && onDrag(item.id, rowData.id)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { id: rowData.id },
    type: ItemTypes.ROW,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const isActive = canDrop && isOver

  drag(drop(ref))

  const styles = {
    cursor: 'grab',
    opacity: isDragging ? 0.5 : 1,
    background: isActive ? '#ddd' : null,
    width: '100%',
    height: '100%',
    color: rowData.is_duplicate == true ? 'red' : null,
    borderTop: isActive ? '2px solid #2589f5' : null, //important
  }

  return (
    <div ref={ref} style={styles}>
      {children}
    </div>
  )
}
function RowDrag({ children, onDrag, id, rowData, ...rest }) {
  const ref = React.useRef(null)

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.ROW,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item, monitor) {
      onDrag && onDrag(item.id, rowData.id)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { id: rowData.id },
    type: ItemTypes.ROW,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const isActive = canDrop && isOver

  drag(drop(ref))

  const styles = {
    cursor: 'grab',
    opacity: isDragging ? 0.5 : 1,
    //background: isActive ? '#ddd' : null,
    // width: '100%',
    // height: '100%',
    color: rowData.is_duplicate == true ? 'red' : null,
    //borderTop: isActive ? '2px solid #2589f5' : null, //important
    backgroundColor: '#fff0',
    padding: '6px',
    borderRadius: '6px',
    border: isActive ? '2px solid #2589f5' : '1px solid gray',
    marginRight: '2px',
  }

  return (
    <button type='button' ref={ref} style={styles}>
      <DragableIcon style={{ fontSize: 20 }} />
    </button>
  )
}

function sort(source, sourceId, targetId) {
  // Old
  // const nextData = source.filter((item) => item.id !== sourceId)
  // const dragItem = source.find((item) => item.id === sourceId)
  // const index = nextData.findIndex((item) => item.id === targetId)

  // nextData.splice(index, 0, dragItem)
  // return nextData

  // update by Narottam Jaiswal
  const dragItem = source.find((item) => item.id === sourceId)
  const sourceIndex = source.findIndex((item) => item.id === sourceId)
  const targetIndex = source.findIndex((item) => item.id === targetId)
  // If the source and target indices are the same, return the original data
  if (sourceIndex === targetIndex) {
    return source
  }

  const nextData = [...source]
  nextData.splice(sourceIndex, 1)

  nextData.splice(targetIndex, 0, dragItem)
  return nextData
}

function sortUpdated(source, sourceId, targetId) {
  const nextData = [...source]
  const dragItem = nextData.find((item) => item.id === sourceId)
  const index = nextData.findIndex((item) => item.id === targetId)

  if (index >= 0) {
    nextData.splice(index, 0, dragItem)
    if (index < nextData.indexOf(dragItem)) {
      nextData.splice(nextData.indexOf(dragItem) + 1, 1)
    } else {
      nextData.splice(nextData.indexOf(dragItem), 1)
    }
  }

  return nextData
}

function getColumns(initialColumns) {
  const isMobile = window.innerWidth < 768

  var tableColumns = []

  Object.entries(initialColumns).map(([key, value]) => {
    var key = value.key_value
    var width = isMobile == 1 ? value.mobile_width : value.desktop_width //100
    if (key && key != '') {
      if (width == '' || width == 0 || width == undefined) {
        // Custom Width
        if (key.includes('_NAME') || key.includes('_NAMES')) {
          width = 250 // Custom Width
        } else if (
          key == 'FORMULA_DOSAGE_COLUMN_ACTION' ||
          key == 'FORMULA_DOSAGE_COLUMN_HERB_ADD'
        ) {
          width = 150 // Custom Width
        } else {
          width = 80 // Custom Width
        }
      }

      var temp = {
        name: value.display_name,
        id: key.toLowerCase(),
        key: key,
        position: parseFloat(value.position) || 0,
        width: Number(width),
      }
      var position = parseFloat(value.position) || 0
      if (
        value &&
        value.length !== 0 &&
        position !== '' &&
        value.is_active == 1
      ) {
        var existingColumn = tableColumns.find(
          (column) => column.id === temp.id
        )
        if (!existingColumn) {
          tableColumns.push(temp)
        }
      }
    }
  })
  //console.log('tableColumns', tableColumns)
  return tableColumns
}

function PreventOverflowContainer({ children, height = 500 }) {
  const container = React.useRef()
  const content = React.useRef()

  const containerStyle = {
    overflow: 'auto',
    position: 'relative',
  }

  const contentStyle = {
    height: '400%',
    width: '230%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  }

  React.useEffect(() => {
    container.current.scrollTop = content.current.clientHeight / 2 - 60
    container.current.scrollLeft =
      content.current.clientWidth / 2 - container.current.clientWidth / 2
  }, [container, content])

  return (
    <div style={{ ...containerStyle, height }} ref={container}>
      <div style={contentStyle} ref={content}>
        {children(() => container.current)}
      </div>
    </div>
  )
}

function compare(a, b) {
  let nameA = a.toUpperCase()
  let nameB = b.toUpperCase()

  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}
const countDecimalPlace = (number) => {
  if (number === null || number?.trim() === '') {
    return -1 // You can choose any value or return null, depending on your use case
  }
  const decimalPart = (number?.toString().split('.')[1] || '').length
  return decimalPart === 0 ? 0 : decimalPart
}

export function FormualIngredientTable({
  formulaBuilderId,
  formulaData,
  initialColumns = [],
  initialData = [],
  setItemData,
  formulaIngredients = [],
  dosagesDays,
  dosagesBags,
  formulaDosagesSetting,
  //conversion_type = 'do_not_convert',
  ingrediuentsTags = [],
  currency = '',
  pracId,
  prac_setting,
  dacimalPlace = 2,
  //default_qty_field = '',
  dosages_key,
  seperator,
  formulaIngredientsIdArr,
  namesType = [],
  converting,
  prev_converting,
  prevBuilderKey,
  defaultMeasuremtName,
  measurementOptions,
  allow_multiple_measurements,
  ingredient_selection_type,
  defaultMeasuremt,
  formulaDosagesKey,
  formulaDosagesType,
  measurment_type_list,
  formulaDeatils = [],
  patient_id,
  allow_ingredients_type,
  nameTypes,
  ingredient_decimal,
  measurmentConversions,
  setSpecifixSubtotal,
  summaryData,
  formulaOption,
  ApplyFormValidation,
  thisFormData,
  concentrates,
  totalQty,
  loadingTable,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [tableData, setTableData] = useState([])
  const [preTotalQty, setPreTotalQty] = useState([])
  const [TabDropdownData, setTabDropDownData] = useState([])
  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({})
  const [tableRowsData, setTableRowsData] = React.useState({})
  const handleTabDropdownData = (data) => {
    setTabDropDownData(data)
  }
  var requirements = [
    {
      column: 'ingredients',
      name: 'Ingredients',
      is_required: 0,
      key: 'ingredients',
      allow_zero: false,
    },
    {
      column: 'ingredients_count',
      name: 'Ingredients Count',
      is_required: 0,
      key: 'ingredients_count',
      allow_zero: false,
    },
    {
      column: 'ingredients_qty',
      name: 'Ingredients Total Qty',
      is_required: 0,
      key: 'ingredients_qty',
      allow_zero: false,
    },
  ]

  const [formulaIngredientsSearch, setFormulaIngredientsSearch] = useState([])
  useEffect(() => {
    // Fetch and set initial data
    fetchData()

    // Refresh data every 5 seconds (adjust the interval as needed)
    const intervalId = setInterval(() => {
      fetchData()
    }, 5000)

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId)
  }, []) // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    setFormulaIngredientsSearch([])
  }, [concentrates])
  const fetchData = () => {
    // Replace this with your data fetching logic
    // For example, fetch data from an API and update the state
    const newData = /* ... fetch data logic ... */ []

    setTableData(newData)
  }

  // useEffect(() => {
  //   console.log(formulaIngredientsSearch, 'formulaIngredientsSearch')
  // }, [formulaIngredientsSearch])

  const dispatch = useDispatch()
  const { SummaryReducer } = useSelector((response) => response)
  const [childInput, setChildInput] = useState('')
  const [data, setData] = React.useState([])
  const [columns, setColumns] = React.useState([])
  const [selectedIngredients, setSelectedIngredients] = React.useState([])
  const [ingredientTab, setIngredientTab] = useState('')
  const [ingredientTabStyle, setIngredientTabStyle] = useState('') // vertical,horizontal
  const [popup, setPopup] = React.useState(false) // when open Advanced Search
  const [ingredientTabOptions, setIngredientTabOptions] = useState([])

  const tabs = JSON.parse(formulaData.ingredient_tabs)
  const dogagesColumns = formulaData
    ? JSON.parse(formulaData.ingredient_selection_column_option)
    : []

  const [minRows, setMinRows] = useState(0)
  const [conversion_type, setconversiontype] = useState(
    formulaDosagesSetting
      ? formulaDosagesSetting.conversion_type
      : 'do_not_convert'
  ) // [do_not_convert,ingredient_ratio,fixed_ratio]
  const [default_qty_field, set_default_qty_field] = useState(
    formulaDosagesSetting?.default_qty_field
  )
  // for calcution
  //const [dosages_key, setDosagesKey] = useState(formulaDosagesSetting?.value)
  const [no_of_days_qty_field, setno_of_days_qty_field] = useState(
    formulaDosagesSetting?.no_of_days_qty_field
  )
  const [no_of_container_qty_field, setno_of_container_qty_field] = useState(
    formulaDosagesSetting?.no_of_container_qty_field
  )
  useEffect(() => {
    if (TabDropdownData) {
      getformulaSummary(data)
    }
  }, [TabDropdownData, data])

  // const yourFunction = (formulaOption) => {

  //   console.log("jfjv")

  // }

  // Use useEffect to trigger the function when formulaOption changes

  const [sortColumn, setSortColumn] = React.useState()
  const [sortType, setSortType] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [isConverting, setIsConverting] = React.useState(false)
  const toaster = useToaster()

  const [notMatch, setNotMatch] = useState([])

  dosagesDays = parseInt(dosagesDays) || 1

  // const [messages, setMessages] = useState([])
  const [messages, setMessages] = useState({})
  useEffect(() => {}, [messages])
  const [searchIngredients, setSearchIngredients] = useState([])

  const [testData, setTestData] = useState([])

  const [RowToBeDelete, setRowToBeDelete] = useState([])

  // Function to add a message with a status
  // const addMessage = (text, status) => {
  //   const newMessage = { text, status }
  //   setMessages((prevMessages) => [...prevMessages, newMessage])
  //   // Automatically remove the message after a specific time based on its status
  //   let removeTimeout = 2000 // Default: 5 seconds
  //   if (status === 'warning' || status === 'error') {
  //     removeTimeout = 60000 // Set to 60 seconds for 'warning' and 'error' messages
  //   }
  //   setTimeout(() => {
  //     setMessages((prevMessages) =>
  //       prevMessages.filter((msg) => msg !== newMessage)
  //     )
  //   }, removeTimeout)
  // }
  // update minRows && ingredient tab styling
  useEffect(() => {
    if (prac_setting) {
      var default_ingredient_rows = prac_setting.default_ingredient_rows
        ? prac_setting.default_ingredient_rows
        : formulaData.default_no_of_rows
      setMinRows(default_ingredient_rows)
      //alert(default_ingredient_rows)
      var ingredients_tabing_options = prac_setting.ingredients_tabing_options
        ? prac_setting.ingredients_tabing_options
        : ingredientTabStyle
      setIngredientTabStyle(ingredients_tabing_options)
    }

    const tabs = JSON.parse(formulaData.ingredient_tabs)
    const dogagesColumns = formulaData
      ? JSON.parse(formulaData.ingredient_selection_column_option)
      : []
  }, [formulaData])

  useEffect(() => {
    var prac_options = []
    var qty_field = formulaDosagesSetting
      ? formulaDosagesSetting.default_qty_field
      : ''

    if (typeof qty_field === 'string' && qty_field.trim() !== '') {
      qty_field = qty_field.toLowerCase()
      set_default_qty_field(qty_field)
    }

    if (prac_setting) {
      var optionsArr = prac_setting.ingredient_selection_column_option
        ? JSON.parse(prac_setting.ingredient_selection_column_option)
        : []
      prac_options = optionsArr[formulaDosagesKey] || []
    }
    var options = dogagesColumns[formulaDosagesKey] || []
    // update table column according to prac setting
    if (prac_options.length !== 0) {
      var thisoptions = Object.values(options).map((col, i) => {
        var key = col.key_value
        var prac_column = prac_options[key] || []
        if (prac_column.length !== 0) {
          var is_active = prac_column.is_active || col.is_active
          var position = parseInt(prac_column.position) || i
          //console.log('getColumn', key, is_active, position)
          return {
            ...col,
            is_active: is_active,
            position: position,
          }
        }
        // Return undefined for empty prac_column to avoid undefined values in the array
        return undefined
      })

      // Filter out undefined values from thisoptions before setting columns
      thisoptions = thisoptions.filter((col) => col !== undefined)

      //console.log('thisoptions', thisoptions)
      //setColumns(thisoptions)
      const finalColumns = getColumns(thisoptions)
      const updatedColumns = [...finalColumns]

      // Sort the data array by the 'position' column
      const temp = updatedColumns.sort((a, b) => {
        const positionA = a.position || 0
        const positionB = b.position || 0
        return positionA - positionB
      })

      // Set the columns and data in the state
      setColumns(updatedColumns)
    } else {
      const finalColumns = getColumns(options)
      const updatedColumns = [...finalColumns]

      // Sort the data array by the 'position' column
      const temp = updatedColumns.sort((a, b) => {
        const positionA = a.position || 0
        const positionB = b.position || 0
        return positionA - positionB
      })

      // Set the columns and data in the state
      setColumns(updatedColumns)
      //setColumns(options)
    }
    // add Rows when no ingredients
    if (data.length == 0) {
      setTimeout(() => {
        handleAdd()
      }, 200)
      //handleAdd()
      //setSelectedIngredients([])
    }
    //setcolumns(options)
    setconversiontype(
      formulaDosagesSetting
        ? formulaDosagesSetting.conversion_type
        : 'do_not_convert'
    )
  }, [formulaDosagesType, formulaDosagesKey])

  const handleAdd = (addRow = '') => {
    var addRows = addRow ? addRow : minRows
    const existingIds = new Set(data.map((item) => item.id))
    const newIngredients = []
    for (let i = 0; i < addRows; i++) {
      let newId = uuidv4() // Generate a unique ID
      while (existingIds.has(newId)) {
        newId = uuidv4() // Regenerate ID if it already exists
      }
      newIngredients.push({
        id: newId,
        ingredient_id: '',
        formula_dosage_column_all_names: '',
        formula_dosage_column_supplier: '',
        formula_dosage_column_form: '', // per ingredient display name
        formula_dosage_column_raw_per_day: '',
        formula_dosage_column_ratio: '',
        formula_dosage_column_enter_qty: '',
        formula_dosage_column_day: '',
        formula_dosage_column_per: '',
        formula_dosage_column_qty_received: '',
        formula_dosage_column_qty_received_original: '',
        formula_dosage_column_price: '',
        formula_dosage_column_price_original: '',
        formula_dosage_column_subtotal: '',
        formula_dosage_column_action: '',
        formula_dosage_column_g_per_day: '',
        formula_dosage_column_no_day_bag: '',
        formula_dosage_column_herb_add: '',
        extra_data: '',
        is_duplicate: false,
        ratio1: '',
        ratio2: '',
        // Per Ingredient Data
        ing_id: '', // per ingredient id
        ing_key: '', // per ingredient name
        ing_name: '', // per ingredient display name
        default_measurment: '',
        default_measurment_name: '',
        defaultratio: '0', //[1,0] // per ingredient allow ratio
        ratio_type: '', //[information_only,will_be_used_for_calculating_dosages] // per ingredient ratio type
        ratioingredient1: '', // per ingredient ratioingredient1 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
        ratioingredient2: '', // per ingredient ratioingredient2 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
        perRatio1: '', // per ingredient ratio
        perRatio2: '', // per ingredient ratio2
        is_loading: false,
      })
      existingIds.add(newId)
    }
    // update main Ingredients
    var merged = [...data, ...newIngredients]
    setData(merged)
    setItemData(merged)
  }

  useEffect(() => {
    if (formulaDeatils && formulaDeatils.id) {
      var formula_ingredients = formulaDeatils.ingredients
        ? JSON.parse(formulaDeatils.ingredients)
        : []
      var ingredientIds = data
        .map((item) => item.ingredient_id) // Access the ingredient_id property
        .filter(
          (ingredientId) =>
            ingredientId !== undefined &&
            ingredientId !== null &&
            ingredientId !== ''
        )
      //console.log('formula_ingredients', formula_ingredients)
      if (formula_ingredients.length != 0 && ingredientIds.length == 0) {
        // Merge formula_ingredients and ingredients arrays
        const mergedIngredients = [...formula_ingredients, ...data]
        //alert('mergedIngredients ' + mergedIngredients.length)

        setTimeout(() => {
          setData(mergedIngredients)
          setItemData(mergedIngredients)
          //addMoreRows()
        }, 500)
      } else {
      }
    }
  }, [formulaBuilderId])

  const getIngredientTabOptions = async () => {
    const response = await ApiFormulaIngredientTabData({
      formulaBuilderId,
      pracId,
      formulaDosagesType,
      patient_id,
    })
    const herbData = await response
    return herbData
  }

  useEffect(() => {
    const tabOptions = getIngredientTabOptions()
    tabOptions.then((response) => {
      //console.log(response)
      //var options = response != null ? JSON.parse(response) : []
      setIngredientTabOptions(response) // != null ? JSON.parse(response) : []
    })
  }, [formulaBuilderId, formulaDosagesType, patient_id]) //measurementOptions

  const selectedTabArr = Object.entries(tabs).find(([_, option]) => {
    return ingredientTab === option['key_value']
  })
  // useEffect(() => {
  //   if (Number(preTotalQty) != Number(totalQty) && totalQty != undefined) {
  //     setPreTotalQty(totalQty)
  //     if (totalQty) {

  //       adjustQtyReceived(data, 'formula_dosage_column_qty_received', totalQty);
  //     }
  //   }
  //   getformulaSummary(data)

  // }, [totalQty, formulaDosagesType]);

  var selectedTabName = selectedTabArr ? selectedTabArr[1].display_name : ''

  const handleTabFormulasMAin = async (value, extra, items) => {
    const maxAttempts = 3 // Maximum number of attempts to add ingredients
    var thisFormula = extra.target.defaultValue
    var defaultChecked = extra.target.defaultChecked
    var selectedFormulas = ''
    items.map((option, k) => {
      if (option.id == thisFormula) {
        selectedFormulas = option
      }
    })

    if (selectedFormulas) {
      var ingredientIds = data
        .map((item) => item.ingredient_id)
        .filter(
          (ingredientId) =>
            ingredientId !== undefined &&
            ingredientId !== null &&
            ingredientId !== ''
        )

      var all_herbs =
        selectedFormulas.all_herbs != null ? selectedFormulas.all_herbs : []
      if (all_herbs.length == 0) {
        // addMessage(
        //   'Formula : ' + selectedFormulas.name + 'has no ingredients.',
        //   'error'
        // )
        customNotificationMessage({
          text: 'Formula : ' + selectedFormulas.name + 'has no ingredients.',
          status: 'error',
          key: 'common',
          fun: setMessages,
          type: 'add',
        })
        return false
      }

      var ingredientsWithBlankId = data.filter(
        (ingredient) => ingredient.ingredient_id === ''
      )
      ////////////////////////////////
      let addRowAttempts = 0
      while (addRowAttempts < maxAttempts) {
        if (ingredientsWithBlankId.length < all_herbs.length) {
          var addExtraRows = Math.abs(
            ingredientsWithBlankId.length - all_herbs.length
          )
          handleAdd(addExtraRows)
        }

        // Reinitialize ingredientsWithBlankId after adding extra rows
        ingredientsWithBlankId = data.filter(
          (ingredient) => ingredient.ingredient_id === ''
        )
        addRowAttempts++
      }
      ////////////////////////////////////////
      all_herbs.map(async (option, k) => {
        let emptyRowId
        let attempts = 0
        const response = await getIngredientDetails(option.herb_id)
        // console.log('findIngredient-', response)
        if (response.length == 0) {
          // addMessage('Ingredient ' + option.herb_id + ' not matched.', 'error')
          customNotificationMessage({
            text: 'Ingredient ' + option.herb_id + ' not matched.',
            status: 'error',
            key: 'common',
            fun: setMessages,
            type: 'add',
          })
          return false
        }

        // if (allow_ingredients_type.includes(response?.ing_id) == false) {
        //   addMessage(
        //     'Ingredient ' +
        //       response?.name +
        //       ' - ' +
        //       response?.ing_name +
        //       ' is not allow.' +
        //       response?.ing_id,
        //     'error'
        //   )
        //   return false
        // }
        while (attempts < maxAttempts) {
          //var emptyRowId = ''
          if (ingredientsWithBlankId.length != 0 && ingredientsWithBlankId[k]) {
            emptyRowId = ingredientsWithBlankId[k]
          }
          if (emptyRowId == '' || emptyRowId == undefined) {
            handleAdd()
            ingredientsWithBlankId = data.filter(
              (ingredient) => ingredient.ingredient_id === ''
            )
            emptyRowId =
              ingredientsWithBlankId.length != 0
                ? ingredientsWithBlankId[0]
                : ''
            //alert(emptyRowId)
          }
          if (emptyRowId && emptyRowId != '') {
            // console.log(
            //   'add formula ingredient ' +
            //   option.herb_id +
            //   ' - on ' +
            //   emptyRowId.id
            // )
            var id = emptyRowId?.i0d

            if (ingredientIds.includes(option.herb_id) && attempts == 0) {
              // addMessage(
              //   'Ingredient : ' +
              //     response?.name +
              //     ' - ' +
              //     response?.ing_name +
              //     ' has already added.',
              //   'info'
              // )
              customNotificationMessage({
                text:
                  'Ingredient : ' +
                  response?.name +
                  ' - ' +
                  response?.ing_name +
                  ' has already added.',
                status: 'info',
                key: 'common',
                fun: setMessages,
                type: 'add',
              })
              return false
            } else {
              var thisIng = genrateIngredientArr(option.herb_id, id, response)
              setData((prevData) =>
                prevData.map((ingredient) =>
                  ingredient.id == id ? thisIng : ingredient
                )
              )
              // Check if the ingredient was successfully added before calling handleIngredientQty
              if (data.some((row) => row.ingredient_id == response?.id)) {
                handleIngredientQty(
                  option.enter_qty,
                  id,
                  'formula_dosage_column_enter_qty',
                  thisIng,
                  conversion_type
                )
                // addMessage(
                //   'Ingredient : ' +
                //     response?.name +
                //     ' - ' +
                //     response?.ing_name +
                //     ' has added successfully.',
                //   'success'
                // )
                customNotificationMessage({
                  text:
                    'Ingredient : ' +
                    response?.name +
                    ' - ' +
                    response?.ing_name +
                    ' has added successfully.',
                  status: 'success',
                  key: 'common',
                  fun: setMessages,
                  type: 'add',
                })
              }
            }
          } else {
            // addMessage(
            //   'Ingredient ' +
            //     response?.name +
            //     ' - ' +
            //     response?.ing_name +
            //     ' is not added due to no empty row exists.',
            //   'error'
            // )
            //return false
            // console.log(
            //   'not add formula ingredient ' +
            //     option.herb_id +
            //     ' - on ' +
            //     emptyRowId
            // )
          }
          attempts++
        }
      })
    }
  }
  const handleTabFormulas = async (value, extra, items, checked) => {
    var thisFormula = extra.target.defaultValue
    //var defaultChecked = extra.target.defaultChecked
    var selectedFormulas = ''
    items.map((option, k) => {
      if (option.id == thisFormula) {
        selectedFormulas = option
      }
    })
    if (selectedFormulas && checked == true) {
      // console.log(selectedFormulas.id, 'selected')
      setLoading(true)
      // Filter out items with null or blank ingredient_id from data
      var tempArr = data.filter((item) => {
        const ingredientId = item.ingredient_id
        return ingredientId !== null && ingredientId !== ''
      })
      // console.log('thisFormula', selectedFormulas)
      // Filter out undefined, null, and empty ingredient IDs
      const ingredientIds = tempArr
        .map((item) => item.ingredient_id)
        .filter(
          (ingredientId) =>
            ingredientId !== undefined &&
            ingredientId !== null &&
            ingredientId !== ''
        )
      // console.log(selectedFormulas,"selectedFormulas")
      var searchIngredients =
        selectedFormulas.all_herbs != null
          ? selectedFormulas.selectedFormulas
          : []
      if (searchIngredients.length == 0) {
        // addMessage(
        //   'Formula : ' + selectedFormulas.name + ' has no ingredients.',
        //   'error'
        // )
        customNotificationMessage({
          text: 'Formula : ' + selectedFormulas.name + ' has no ingredients.',
          status: 'error',
          key: selectedFormulas.id,
          fun: setMessages,
          type: 'add',
        })
        setLoading(false)
        return false
      }

      ////////////////////////////////////////
      //console.log('searchIngredients', searchIngredients)
      const updatedIngredients = []
      // Use a for...of loop to support asynchronous calls
      let errorMessageCode = []
      for (const val of searchIngredients) {
        if (
          ingredientIds.includes(val.herb_id) === false &&
          formulaIngredientsIdArr.includes(val.herb_id.toString())
        ) {
          // if (ingredientIds.includes(val.herb_id) === false) {
          const updatedData = await addMultipleIngredient(
            tempArr,
            val.herb_id,
            thisFormula
          )
          if (updatedData) {
            updatedData['formula_dosage_column_enter_qty'] = val.enter_qty
            const finalArr = await qtyConversion(
              'formula_dosage_column_enter_qty',
              val.enter_qty,
              updatedData,
              conversion_type
              //array.formula_dosage_column_day
            )
            var this_decimal = finalArr['final_decimal_place']
            if (val.enter_qty != '') {
              // update decimal
              finalArr['formula_dosage_column_enter_qty'] = parseFloat(
                finalArr['formula_dosage_column_enter_qty']
              ).toFixed(this_decimal) //dacimalPlace
              finalArr['formula_dosage_column_raw_per_day'] = parseFloat(
                finalArr['formula_dosage_column_raw_per_day']
              ).toFixed(this_decimal) //dacimalPlace
            } else {
              finalArr['formula_dosage_column_raw_per_day'] = ''
            }
            //console.log('addMultipleIngredient', updatedData)
            var thisupdatedIngredients = convert_mesurement(finalArr)
            var final =
              thisupdatedIngredients != undefined &&
              thisupdatedIngredients != ''
                ? thisupdatedIngredients
                : finalArr
            updatedIngredients.push(final)
          }
        } else {
          // console.log(val, 'JJJJJJ')
          // if(val.herb_name){
          errorMessageCode.push(' ' + val.herb_name)
          // }
          // addMessage(
          //   'Formual : ' + val.herb_id + ' has no ingredients.',
          //   'error'
          // )
          // return false;
        }
      }

      if (errorMessageCode.length > 0) {
        // addMessage(
        //   'Formula : ' + errorMessageCode + ' has no ingredients.',
        //   'error'
        // )
        customNotificationMessage({
          text:
            'Formula : ' +
            errorMessageCode.join(',', ' ') +
            ' has no ingredients.',
          status: 'error',
          key: selectedFormulas.id,
          fun: setMessages,
          type: 'add',
        })
        // return false;
      }
      // Update tempArr with all the updated ingredients
      tempArr = [...tempArr, ...updatedIngredients]
      //console.log('finalArr', tempArr)
      setData(tempArr) // Update state with the final array

      setItemData(tempArr)
      //setSearchIngredients([])
      setLoading(false)
      adjustQtyReceived(
        tempArr,
        'formula_dosage_column_qty_received',
        'formula_dosage_column_enter_qty',
        totalQty || 0,
        dacimalPlace
      ) //  calculate qty received when check formula
      setTimeout(() => {
        // console.log('test123')
        handleIngredientPercent()
      }, 100)
    } else if (selectedFormulas && checked == false) {
      // alert('Remove Formulas Ingredients')
      // console.log(selectedFormulas.id, 'deseleted')
      setLoading(true)
      customNotificationMessage({
        key: selectedFormulas.id,
        fun: setMessages,
        type: 'delete',
      })
      // Filter out items with null or blank ingredient_id from data
      var tempArr = data.filter((item) => {
        const ingredientId = item.ingredient_id
        return ingredientId !== null && ingredientId !== ''
      })

      const ingredientIds = tempArr
        .map((item) => item.ingredient_id)
        .filter(
          (ingredientId) =>
            ingredientId !== undefined &&
            ingredientId !== null &&
            ingredientId !== ''
        )

      var searchIngredients =
        selectedFormulas.all_herbs != null ? selectedFormulas.all_herbs : []
      if (searchIngredients.length == 0) {
        // addMessage(
        //   'Formula : ' + selectedFormulas.name + ' has no ingredients.',
        //   'error'
        // )
        // customNotificationMessage({
        //   text:  'Formula : ' + selectedFormulas.name + ' has no ingredients.',
        //   status: 'error',
        //   key: selectedFormulas.id,
        //   fun: setMessages,
        //   type:"add"
        // })
        setLoading(false)
        return false
      }
      // Use a for...of loop to support asynchronous calls
      for (const val of searchIngredients) {
        if (ingredientIds.includes(val.herb_id)) {
          // Remove selected ingredients that meet the conditions
          tempArr = tempArr.filter((thisIng) => {
            return (
              thisIng.ingredient_id !== val.herb_id ||
              thisIng.formula_id !== thisFormula
            )
          })
        }
      }
      // Update tempArr with all the updated ingredients
      //console.log('finalArr', tempArr)
      setData(tempArr) // Update state with the final array
      setItemData(tempArr)
      //setSearchIngredients([])
      setLoading(false)
      handleIngredientPercent()
    } else {
      // addMessage('Something went wrong.', 'error')
      customNotificationMessage({
        text: 'Something went wrong.',
        status: 'error',
        key: 'common',
        fun: setMessages,
        type: 'add',
      })
      return false
    }
  }
  const handleTabMultipleFormulas = async (formula = [], array = []) => {
    //console.log('handleTabMultipleFormulas', formula, array)
    if (formula.length != 0) {
      formula.map((thisFormula) => {
        //console.log('checkFormula', thisFormula)
        var allHerbs = thisFormula.all_herbs
        //console.log('formulaHerbsCheck', allHerbs)
        if (
          allHerbs == null ||
          allHerbs == undefined ||
          (allHerbs != null && allHerbs.length == 0)
        ) {
          customNotificationMessage({
            text: thisFormula.name + ' has no Ingredients',
            status: 'error',
            key: thisFormula.id,
            fun: setMessages,
            type: 'add',
          })
        }
      })
    }
    if (array.length != 0 && formula.length != 0) {
      setLoading(true)

      var tempArr = data.filter((item) => {
        const ingredientId = item.ingredient_id
        return ingredientId != null && ingredientId != ''
      })
      const ingredientIds = tempArr
        .map((item) => item.ingredient_id)
        .filter(
          (ingredientId) =>
            ingredientId != undefined &&
            ingredientId != null &&
            ingredientId != ''
        )
      const searchIngredients = []
      // Use a for...of loop to support asynchronous calls
      let errorMessageCode = []
      for (const val of array) {
        if (formulaIngredientsIdArr.includes(val.herb_id.toString())) {
          if (ingredientIds.includes(val.herb_id)) {
            // console.log(val.herb_id + ' - this Ingredient already added')
            customNotificationMessage({
              text:
                'Formula' + ': ' + val.herb_name + ' Ingredient already added',
              status: 'error',
              key: val.formula_id,
              fun: setMessages,
              type: 'add',
            })
          } else {
            searchIngredients.push(val)
          }
        } else {
          // console.log(
          //   val.herb_name +
          //     ' - error this Ingredient Ingredient type naot Allowed',
          //   val
          // )
          customNotificationMessage({
            text:
              'Formula' + ': ' + val.herb_name + ' Ingredient type not Allowed',
            status: 'error',
            key: val.formula_id,
            fun: setMessages,
            type: 'add',
          })
        }
      }
      // console.log('searchIngredients', searchIngredients)
      const updatedIngredients = []
      if (searchIngredients.length != 0) {
        const selecedIngredients = searchIngredients
          .map((item) => item.herb_id)
          .filter(
            (herbId) => herbId !== undefined && herbId !== null && herbId !== ''
          )
        const response = await getMultipleIngredientDetails(selecedIngredients)
        if (response.length != 0) {
          // console.log('searchIngredientsResponse', response)
          for (const val of searchIngredients) {
            const thisIngredientResponce = response.find(
              (arr) => arr.id == val.herb_id
            )
            if (thisIngredientResponce) {
              const updatedData = genrateIngredientArr(
                val.herb_id,
                thisIngredientResponce.id,
                thisIngredientResponce,
                val.formula_id
              )
              if (updatedData) {
                updatedData['formula_dosage_column_enter_qty'] = val.enter_qty
                const finalArr = await qtyConversion(
                  'formula_dosage_column_enter_qty',
                  val.enter_qty,
                  updatedData,
                  conversion_type
                  //array.formula_dosage_column_day
                )
                var this_decimal = finalArr['final_decimal_place']
                if (val.enter_qty != '') {
                  // update decimal
                  finalArr['formula_dosage_column_enter_qty'] = parseFloat(
                    finalArr['formula_dosage_column_enter_qty']
                  ).toFixed(this_decimal) //dacimalPlace
                  finalArr['formula_dosage_column_raw_per_day'] = parseFloat(
                    finalArr['formula_dosage_column_raw_per_day']
                  ).toFixed(this_decimal) //dacimalPlace
                } else {
                  finalArr['formula_dosage_column_raw_per_day'] = ''
                }
                //console.log('addMultipleIngredient', updatedData)
                var thisupdatedIngredients = convert_mesurement(finalArr)
                var final =
                  thisupdatedIngredients != undefined &&
                  thisupdatedIngredients != ''
                    ? thisupdatedIngredients
                    : finalArr
                updatedIngredients.push(final)
              } else {
                // something went wrong
              }
            } else {
              // product not matched
              console.log(val.herb_id, 'responce not found')
              customNotificationMessage({
                text: 'Formula' + ': ' + val.herb_name + ' not found',
                status: 'error',
                key: val.formula_id,
                fun: setMessages,
                type: 'add',
              })
            }
          }
        }
      }
      tempArr = [...tempArr, ...updatedIngredients]
      setData(tempArr) // Update state with the final array
      setItemData(tempArr)
      setLoading(false)
      adjustQtyReceived(
        tempArr,
        'formula_dosage_column_qty_received',
        'formula_dosage_column_enter_qty',
        totalQty || 0,
        dacimalPlace
      ) //  calculate qty received when check formula
      setTimeout(() => {
        handleIngredientPercent()
      }, 100)
    } else {
      //error
    }
    setLoading(false)
  }
  const handleTabMultipleRemoveFormulas = async (formula = [], array = []) => {
    if (array.length != 0) {
      // console.log('handleTabMultipleRemoveFormulas', formula, array)
      setLoading(true)
      var tempArr = data.filter((item) => {
        const ingredientId = item.ingredient_id
        return ingredientId !== null && ingredientId !== ''
      })

      const ingredientIds = tempArr
        .map((item) => item.ingredient_id)
        .filter(
          (ingredientId) =>
            ingredientId !== undefined &&
            ingredientId !== null &&
            ingredientId !== ''
        )
      var searchIngredients = []
      for (const val of array) {
        if (formulaIngredientsIdArr.includes(val.herb_id.toString())) {
          if (ingredientIds.includes(val.herb_id)) {
            searchIngredients.push(val)
          } else {
            // console.log(val.herb_id + ' this ingredient exist')
            // customNotificationMessage({
            //   // text: 'Formula : ' + selectedFormulas.name + 'has no ingredients.',
            //   status: 'error',
            //   key: 'common',
            //   fun: setMessages,
            //   type: 'delete',
            // })
          }
        } else {
          // console.log(
          //   val.herb_id +
          //     ' - error this Ingredient Ingredient type naot Allowed'
          // )
          // customNotificationMessage({
          //   // text: 'Formula : ' + selectedFormulas.name + 'has no ingredients.',
          //   status: 'error',
          //   key: val.formula_id,
          //   fun: setMessages,
          //   type: 'delete',
          // })
        }
      }
      if (searchIngredients.length == 0) {
        setLoading(false)
        return false
      } else {
        for (const val of searchIngredients) {
          if (ingredientIds.includes(val.herb_id)) {
            // Remove selected ingredients that meet the conditions
            tempArr = tempArr.filter((thisIng) => {
              return (
                thisIng.ingredient_id !== val.herb_id ||
                thisIng.formula_id !== val.formula_id
              )
            })
          }
        }

        setData(tempArr) // Update state with the final array
        setItemData(tempArr)
        //setSearchIngredients([])
        setLoading(false)
        handleIngredientPercent()
      }
    }
  }

  const addIngredient = async (value) => {
    // Remove items with null or blank ingredient_id
    var tempData = data.filter((item) => {
      const ingredientId = item.ingredient_id
      return ingredientId !== null && ingredientId !== ''
    })

    const existingIds = new Set(tempData.map((item) => item.id))
    const newIngredients = []
    let newId = uuidv4() // Generate a unique ID
    while (existingIds.has(newId)) {
      newId = uuidv4() // Regenerate ID if it already exists
    }

    const maxAttempts = 3 // Maximum number of attempts to add ingredients

    // Filter out undefined, null, and empty ingredient IDs
    const ingredientIds = data
      .map((item) => item.ingredient_id)
      .filter(
        (ingredientId) =>
          ingredientId !== undefined &&
          ingredientId !== null &&
          ingredientId !== ''
      )

    // Retrieve ingredient details
    const response = await getIngredientDetails(value)

    if (response.length === 0) {
      // addMessage('Ingredient ' + value + ' not matched.', 'error')
      customNotificationMessage({
        text: 'Ingredient ' + value + ' not matched.',
        status: 'error',
        key: 'common',
        fun: setMessages,
        type: 'add',
      })
      return false
    }

    // Check if the ingredient already exists
    if (ingredientIds.includes(value)) {
      // addMessage(
      //   'Ingredient : ' +
      //     response?.name +
      //     ' - ' +
      //     response?.ing_name +
      //     ' has already been added.',
      //   'info'
      // )
      customNotificationMessage({
        text:
          'Ingredient : ' +
          response?.name +
          ' - ' +
          response?.ing_name +
          ' has already been added.',
        status: 'info',
        key: 'common',
        fun: setMessages,
        type: 'add',
      })
      return false
    }

    let attempts = 0
    let id = newId

    const thisIng = genrateIngredientArr(value, id, response)
    tempData.push(thisIng)
    setData(tempData)
    // Check if the ingredient was successfully added
    if (tempData.some((row) => row.ingredient_id == value)) {
      // addMessage(
      //   'Ingredient : ' +
      //     response?.name +
      //     ' - ' +
      //     response?.ing_name +
      //     ' has been added successfully.',
      //   'success'
      // )
      customNotificationMessage({
        text:
          'Ingredient : ' +
          response?.name +
          ' - ' +
          response?.ing_name +
          ' has been added successfully.',
        status: 'success',
        key: 'common',
        fun: setMessages,
        type: 'add',
      })
    }
  }

  const updateAddedSearchIngredients = (value) => {
    setSearchIngredients(value)
  }

  useEffect(() => {
    if (popup === false && searchIngredients.length !== 0) {
      AddSearchIngredients()
    }
  }, [popup, searchIngredients])

  useEffect(() => {
    if (formulaOption.status === false) {
      const rowsToDelete = data.filter(
        (rowData) =>
          rowData.ing_id !== formulaOption.ingredient_id &&
          formulaOption.status === false
      )
      // Iterate over each row to delete with a delay
      setData(rowsToDelete)
      setItemData(rowsToDelete)
      setTimeout(() => {
        handleIngredientPercent()
        getformulaSummary(rowsToDelete)
      }, 500)
    }
  }, [formulaOption])

  const AddSearchIngredients = async () => {
    setLoading(true)
    // Filter out items with null or blank ingredient_id from data
    var tempArr = data.filter((item) => {
      const ingredientId = item.ingredient_id
      return ingredientId !== null && ingredientId !== ''
    })
    // Filter out undefined, null, and empty ingredient IDs
    const ingredientIds = tempArr
      .map((item) => item.ingredient_id)
      .filter(
        (ingredientId) =>
          ingredientId !== undefined &&
          ingredientId !== null &&
          ingredientId !== ''
      )
    // Assuming searchIngredients is the array to filter
    // and ingredientIds is the array of values to remove

    const filteredSearchIngredients = searchIngredients.filter(
      (ingredient) => !ingredientIds.includes(ingredient)
    )
    // filteredSearchIngredients now contains searchIngredients with values removed
    //alert(filteredSearchIngredients)
    // console.log(searchIngredients)
    if (filteredSearchIngredients.length > 0) {
      const updatedIngredients = []

      // Use a for...of loop to support asynchronous calls
      for (const val of filteredSearchIngredients) {
        if (ingredientIds.includes(val) === false) {
          const updatedData = await addMultipleIngredient(tempArr, val)
          if (updatedData) {
            // console.log('addMultipleIngredient', updatedData)
            var thisupdatedIngredients = convert_mesurement(updatedData)
            var final =
              thisupdatedIngredients != undefined &&
              thisupdatedIngredients != ''
                ? thisupdatedIngredients
                : updatedData
            updatedIngredients.push(final)
          }
        }
      }
      if (updatedIngredients.length != 0) {
        // Update tempArr with all the updated ingredients
        tempArr = [...tempArr, ...updatedIngredients]
        // console.log('finalArr', tempArr)
        setData(tempArr) // Update state with the final array
        //setSearchIngredients([])
        setItemData(tempArr)
      }
    }
    setLoading(false)
  }

  const addMultipleIngredient = async (arr, value, formual = 0) => {
    // Remove items with null or blank ingredient_id
    var tempData = arr.filter((item) => {
      const ingredientId = item.ingredient_id
      return ingredientId !== null && ingredientId !== ''
    })

    const existingIds = new Set(tempData.map((item) => item.id))
    const newIngredients = []
    let newId = uuidv4() // Generate a unique ID
    while (existingIds.has(newId)) {
      newId = uuidv4() // Regenerate ID if it already exists
    }

    // Filter out undefined, null, and empty ingredient IDs
    const ingredientIds = tempData
      .map((item) => item.ingredient_id)
      .filter(
        (ingredientId) =>
          ingredientId !== undefined &&
          ingredientId !== null &&
          ingredientId !== ''
      )

    // Retrieve ingredient details
    const response = await getIngredientDetails(value)

    if (response.length === 0) {
      // addMessage('Ingredient ' + value + ' not matched.', 'error')
      customNotificationMessage({
        text: 'Ingredient ' + value + ' not matched.',
        status: 'error',
        key: 'common',
        fun: setMessages,
        type: 'add',
      })
      return false
    }

    // Check if the ingredient already exists
    if (ingredientIds.includes(value)) {
      // addMessage(
      //   'Ingredient : ' +
      //     response?.name +
      //     ' - ' +
      //     response?.ing_name +
      //     ' has already been added.',
      //   'info'
      // )
      customNotificationMessage({
        text:
          'Ingredient : ' +
          response?.name +
          ' - ' +
          response?.ing_name +
          ' has already been added.',
        status: 'info',
        key: 'common',
        fun: setMessages,
        type: 'add',
      })
      return false
    }
    let id = newId

    const thisIng = genrateIngredientArr(value, id, response, formual)
    //tempData.push(thisIng)
    return thisIng
  }

  // Function to generate a new ID using uuidv4
  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }
  //////////////////////////////////////////////

  useEffect(() => {
    // to avoid to get parent incredient data
    // setData(
    //   Object.keys(initialData).map((key) => ({
    //     id: key,
    //     ...initialData[key],
    //   }))
    // )
  }, [initialData])

  /*
  useEffect(() => {
    dosagesDays = parseInt(dosagesDays) || 1
    //console.log('dosagesDays', dosagesDays)
    //console.log('columns', initialColumns, initialData)
    //setTimeout(() => {
    const finalColumns = getColumns({ initialColumns })
    //console.log('finalColumns', finalColumns)
    const updatedColumns = [...finalColumns]
    //const updatedData = Array.isArray(initialData) ? [...initialData] : []
    const updatedData = Object.keys(initialData).map((key) => ({
      id: key,
      ...initialData[key],
    }))
   
    // Sort the data array by the 'position' column
    var temp = updatedColumns.sort((a, b) => {
      const positionA = a.position || 0
      const positionB = b.position || 0
      return positionA - positionB
    })
    //console.log(temp)
    const ingredientIds = updatedData
      .map((item) => item.ingredient_id)
      .filter(
        (ingredientId) =>
          ingredientId !== undefined &&
          ingredientId !== null &&
          ingredientId != ''
      )
    setSelectedIngredients(ingredientIds)
    //console.log('convert updatedData', updatedData)
    //console.log('selectedIngredients', selectedIngredients)
    setColumns(updatedColumns)
    setData(updatedData) // too many render
    //}, 500)
  }, [initialData, initialColumns])*/

  // auto calculate and per column wise width
  /*useEffect(() => {
    const calculateColumnWidths = () => {
      const tableWrapper = document.getElementById('table-wrapper')
      if (tableWrapper) {
        // if (columns.length > 7) {
        //   const updatedColumns = columns.map((column) => {
        //     //console.log('column', column)
        //     var thisWidth = column.key.includes('_NAMES') ? 200 : 100
        //     return {
        //       ...column,
        //       width: thisWidth,
        //     }
        //   })
        //   setColumns(updatedColumns)
        // } else {
        const tableWidth = tableWrapper.clientWidth
        const totalColumns = columns.length
        const defaultWidth = tableWidth / totalColumns
   
        const updatedColumns = columns.map((column) => {
          return {
            ...column,
            width: defaultWidth,
          }
        })
        setColumns(updatedColumns)
        //}
      }
    }
   
    calculateColumnWidths()
    window.addEventListener('resize', calculateColumnWidths)
   
    return () => {
      window.removeEventListener('resize', calculateColumnWidths)
    }
    //setItemData(data)
  }, [columns])*/

  const debouncedSetItemData = debounce((newData) => {
    setItemData(newData)
  }, 5000) // Adjust the delay as needed

  useEffect(() => {
    //setItemData(data)
    // debouncedSetItemData(data)
    // return () => {
    //   debouncedSetItemData.cancel() // Clean up the debounced function on unmount
    // }
  }, [data])
  useEffect(() => {
    //formValidation()
  }, [ApplyFormValidation])

  // // converting trigger
  // useEffect(() => {
  //   //console.clear()
  //   //console.log('converting', converting)
  //   //alert('formula Builder Type chnaged')
  //   var selectedIngredientArr = data
  //     .filter((v) => v.ingredient_id !== null && v.ingredient_id !== '')
  //     .map((v) => v.ingredient_id)
  //   if (selectedIngredientArr.length != 0) {
  //     setTimeout(() => {
  //       //setIsConverting(true)
  //       //convert()
  //     }, 2000)
  //   }
  // }, [formulaBuilderId])

  useEffect(() => {
    // re-calculate on dosagesDays, dosagesBags change
    var selectedIngredientArr = data
      .filter((v) => v.ingredient_id !== null && v.ingredient_id !== '')
      .map((v) => v.ingredient_id)
    if (
      columns.length !== 0 &&
      data.length !== 0 &&
      dosages_key != '' &&
      dosagesDays !== 0 &&
      dosagesBags !== 0 &&
      selectedIngredientArr.length != 0 //&&
      // default_qty_field != '' &&
      // default_qty_field != undefined
    ) {
      //console.log('tryingto refresh ingredient data', dosagesDays, dosagesBags)
      updateIngredients() // Uncomment this line to call the updateIngredients() function.
    }
  }, [dosagesDays, dosagesBags, dosages_key])

  var ingredientOptions = []
  useEffect(() => {
    setTimeout(() => {
      if (formulaIngredients.length != 0) {
        var ingredientOptions = formulaIngredients
      } else {
        //setData([])
        //addMoreRows()
        //const RemoveSelectedIngredientTypeData = data.filter((user) => user.ing_id !== '2') // deleted all where ing_id ==3[raw]
      }
    }, 2000)
  }, [formulaIngredients])

  const handleDragColumn = (sourceId, targetId) => {
    // var finalArr = sort(columns, sourceId, targetId)
    // setItemData(data)
    // setColumns(finalArr)
  }

  const handleSortColumn = (sortColumn, sortType) => {
    //console.log('handleSortColumn', sortColumn, sortType)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortColumn)
      setSortType(sortType)
      getData()
    }, 500)
  }

  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn] || 0
        let y = b[sortColumn] || 0
        if (typeof x === 'string') {
          x = x.charCodeAt()
        }
        if (typeof y === 'string') {
          y = y.charCodeAt()
        }
        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }

    return data
  }

  // const getformulaSummary = (updateIngredients) => {

  //   const result = {}
  //   const hasRawPerDayColumn = columns.some(
  //     (column) => column.key === 'FORMULA_DOSAGE_COLUMN_RAW_PER_DAY'
  //   )
  //   const rawPerDayColumn = columns.find(
  //     (column) => column.key === 'FORMULA_DOSAGE_COLUMN_RAW_PER_DAY'
  //   )
  //   const rawPerDayColumnName = rawPerDayColumn
  //     ? rawPerDayColumn.name
  //     : 'Raw/Day'

  //   const qtyValueColumn = columns.find(
  //     (column) => column.key === 'FORMULA_DOSAGE_COLUMN_ENTER_QTY'
  //   )
  //   const qtyValueColumnName = qtyValueColumn
  //     ? qtyValueColumn.name
  //     : 'Enter Quantity'
  //   const qtyReceivedColumn = columns.find(
  //     (column) => column.key === 'FORMULA_DOSAGE_COLUMN_QTY_RECEIVED'
  //   )
  //   const qtyReceivedColumnName = qtyReceivedColumn
  //     ? qtyReceivedColumn.name
  //     : 'Quantity Received'

  //   updateIngredients.forEach((ingredient) => {
  //     const form = ingredient.formula_dosage_column_form
  //     const subtotalKey = 'formula_dosage_column_subtotal'
  //     const qtyKey = 'formula_dosage_column_enter_qty'
  //     const qtyReceivedKey = 'formula_dosage_column_qty_received'
  //     const priceKey = 'formula_dosage_column_price'
  //     const rawPerDayKey = 'formula_dosage_column_raw_per_day'
  //     const measurementKey = 'default_measurment'

  //     if (form) {
  //       const subtotalValue = parseFloat(ingredient[subtotalKey])
  //       const qtyValue = parseFloat(ingredient[qtyKey])
  //       const qtyReceivedValue = parseFloat(ingredient[qtyReceivedKey])
  //       const price = parseFloat(ingredient[priceKey])
  //       const measurement = parseFloat(ingredient[measurementKey])
  //       const rawPerDay = parseFloat(ingredient[rawPerDayKey])

  //       if (!isNaN(subtotalValue)) {
  //         // Initialize form entry if not present
  //         if (!result[form]) {
  //           result[form] = {
  //             subtotal: 0,
  //             totalQty: 0,
  //             totalQtyReceived: 0,
  //             price: 0,
  //             rawPerDay: 0,
  //             quantitySelected: 0,
  //             measurement,
  //             rawPerDayColumnName: rawPerDayColumnName,
  //             qtyValueColumnName: qtyValueColumnName,
  //             qtyReceivedColumnName: qtyReceivedColumnName,
  //           }
  //         }

  //         // Increment subtotal for the form
  //         result[form].subtotal += subtotalValue

  //         // Increment total quantity for the form
  //         result[form].totalQty += qtyValue || 0

  //         // Increment total quantity received for the form
  //         result[form].totalQtyReceived += qtyReceivedValue || 0
  //         result[form].price += price || 0
  //         result[form].quantitySelected += 1

  //         if (hasRawPerDayColumn && rawPerDayColumn) {
  //           result[form].rawPerDay += rawPerDay || 0
  //         }
  //       }
  //     }
  //   })
  //   summaryData(result)
  //   // dispatch(summaryRequest(result))
  // }

  const getformulaSummary = (updateIngredients) => {
    const result = {}

    const measurementTypes = measurment_type_list
    const hasRawPerDayColumn = columns.some(
      (column) => column.key === 'FORMULA_DOSAGE_COLUMN_RAW_PER_DAY'
    )
    const rawPerDayColumn = columns.find(
      (column) => column.key === 'FORMULA_DOSAGE_COLUMN_RAW_PER_DAY'
    )
    const rawPerDayColumnName = rawPerDayColumn
      ? rawPerDayColumn.name
      : 'Raw/Day'

    const qtyValueColumn = columns.find(
      (column) => column.key === 'FORMULA_DOSAGE_COLUMN_ENTER_QTY'
    )
    const qtyValueColumnName = qtyValueColumn
      ? qtyValueColumn.name
      : 'Enter Quantity'
    const qtyReceivedColumn = columns.find(
      (column) => column.key === 'FORMULA_DOSAGE_COLUMN_QTY_RECEIVED'
    )
    const qtyReceivedColumnName = qtyReceivedColumn
      ? qtyReceivedColumn.name
      : 'Quantity Received'

    updateIngredients.forEach((ingredient) => {
      const form = ingredient.formula_dosage_column_form
      const subtotalKey = 'formula_dosage_column_subtotal'
      const qtyKey = 'formula_dosage_column_enter_qty'
      const qtyReceivedKey = 'formula_dosage_column_qty_received'
      const priceKey = 'formula_dosage_column_price'
      const rawPerDayKey = 'formula_dosage_column_raw_per_day'
      const measurementId = 'default_measurment'
      if (form) {
        const subtotalValue = parseFloat(ingredient[subtotalKey])
        const qtyValue = parseFloat(ingredient[qtyKey])
        const qtyReceivedValue = parseFloat(ingredient[qtyReceivedKey])
        const price = parseFloat(ingredient[priceKey])
        const measurementid = ingredient[measurementId]
        const rawPerDay = parseFloat(ingredient[rawPerDayKey])

        const measurementType = measurementTypes.find(
          (type) => type.id === measurementid
        )

        if (!isNaN(subtotalValue)) {
          const measurement = measurementType.display_name

          // Initialize form entry if not present
          if (!result[form]) {
            result[form] = {}
          }

          // Initialize measurement entry if not present
          if (!result[form][measurement]) {
            result[form][measurement] = {
              ingredientType: form,
              subtotal: 0,
              totalQty: 0,
              totalQtyReceived: 0,
              price: 0,
              rawPerDay: 0,
              quantitySelected: 0,
              measurement,
              rawPerDayColumnName: rawPerDayColumnName,
              qtyValueColumnName: qtyValueColumnName,
              qtyReceivedColumnName: qtyReceivedColumnName,
            }
          }

          // Increment subtotal for the form with measurement
          result[form][measurement].subtotal += subtotalValue

          // Increment total quantity for the form with measurement
          result[form][measurement].totalQty += qtyValue || 0

          // Increment total quantity received for the form with measurement
          result[form][measurement].totalQtyReceived += qtyReceivedValue || 0
          result[form][measurement].price += price || 0
          result[form][measurement].quantitySelected += 1
          if (hasRawPerDayColumn && rawPerDayColumn) {
            result[form][measurement].rawPerDay += rawPerDay || 0
          }
        }
      }
    })

    summaryData(result)
  }

  const calculateColumnTotal = (data, columnId) => {
    const total = data.reduce(
      (acc, row) => acc + parseFloat(row[columnId] || 0),
      0
    )
    return parseFloat(total.toFixed(2)) // Round off to two decimal places
  }
  // Object to store column totals
  const columnTotals = {}

  // Populate column totals
  columns.forEach((column) => {
    // return false;
    columnTotals[column.id] = calculateColumnTotal(data, column.id)
  })
  const arrangeQtyReceivedByEnterQty = (array, qtyReceived, enterQty) => {
    var tempData = array
    var finalArr = []

    tempData.forEach((rowData) => {
      var temp = { ...rowData }

      if (enterQty in rowData && qtyReceived in rowData) {
        // Arrange qtyReceived based on enterQty
        temp[qtyReceived] = temp[enterQty]
        finalArr.push(temp)
      }
    })
    return finalArr
  }

  const adjustQtyReceived = (
    array,
    columnId,
    enterQty,
    totalQty,
    dacimalPlace
  ) => {
    var tempData = array
    var finalArr = []
    const columnTotals = calculateColumnTotal(tempData, columnId)
    const totalEnterQuantity = calculateColumnTotal(tempData, enterQty)

    const prevColumnTotals = columnTotals

    if (
      Number(columnTotals) > Number(totalQty) ||
      (Number(prevColumnTotals) !== 0 &&
        Number(prevColumnTotals) !== Number(totalQty))
    ) {
      const scaleFactor = totalQty / columnTotals

      if (!isNaN(scaleFactor)) {
        tempData.map((rowData) => {
          if (columnId in rowData) {
            var temp = { ...rowData }
            const totalEnterQtyRatio = temp[enterQty] / totalEnterQuantity
            const distributedTotalEnterQty = (
              totalQty * totalEnterQtyRatio
            ).toFixed(rowData['decimal_place'])

            // Update qty received
            temp[columnId] =
              Number(totalQty) === 0 ? temp[enterQty] : distributedTotalEnterQty
            // Update formula_dosage_column_subtotal
            const subtotal =
              temp[columnId] * temp['formula_dosage_column_price']
            temp['formula_dosage_column_subtotal'] = subtotal.toFixed(3)

            finalArr.push(temp)
          }
        })
      }
      setItemData(finalArr)
      setData(finalArr)
      arrangeQtyReceivedByEnterQty(finalArr, columnId, enterQty)
      getformulaSummary(finalArr)
    }
  }
  // useEffect(() => {
  //   if (
  //     formulaDosagesType == 'per' &&
  //     ingredient_selection_type !==
  //     'MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT' && allow_multiple_measurements == false
  //   ) {
  //     if (
  //       Number(preTotalQty) != Number(totalQty) ||
  //       Number(preTotalQty) == Number(totalQty)
  //     ) {
  //       setPreTotalQty(totalQty || 0)
  //       adjustQtyReceived(
  //         data,
  //         'formula_dosage_column_qty_received',
  //         'formula_dosage_column_enter_qty',
  //         totalQty || 0
  //       )
  //     } else if (totalQty == undefined) {
  //       getformulaSummary(data)
  //     }
  //   }
  // }, [totalQty, formulaDosagesType, formulaDosagesSetting.conversion_type])

  useEffect(() => {
    if (
      (formulaDosagesType === 'per' &&
        ingredient_selection_type ===
          'MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT' &&
        formulaData.allow_multiple_measurement_types_in_same_formula === '0') ||
      (ingredient_selection_type ===
        'ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES' &&
        allow_multiple_measurements === false) ||
      ingredient_selection_type ===
        'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE' ||
      ingredient_selection_type ===
        'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS'
    ) {
      if (
        Number(preTotalQty) !== Number(totalQty) ||
        Number(preTotalQty) === Number(totalQty)
      ) {
        setPreTotalQty(totalQty || 0)
        adjustQtyReceived(
          data,
          'formula_dosage_column_qty_received',
          'formula_dosage_column_enter_qty',
          totalQty || 0,
          dacimalPlace
        )
      } else if (totalQty === undefined) {
        getformulaSummary(data)
      }
    }
  }, [
    totalQty,
    formulaDosagesType,
    formulaDosagesSetting.conversion_type,
    allow_multiple_measurements,
    ingredient_selection_type,
  ])

  useEffect(() => {
    const existingIds = new Set(data.map((item) => item.id))
    const newIngredients = []
    for (let i = 0; i < 1; i++) {
      let newId = uuidv4() // Generate a unique ID
      while (existingIds.has(newId)) {
        newId = uuidv4() // Regenerate ID if it already exists
      }
      newIngredients.push({
        id: newId,
        ingredient_id: '',
        formula_dosage_column_all_names: '',
        formula_dosage_column_supplier: '',
        formula_dosage_column_form: '', // per ingredient display name
        formula_dosage_column_raw_per_day: '',
        formula_dosage_column_ratio: '',
        formula_dosage_column_enter_qty: '',
        formula_dosage_column_day: '',
        formula_dosage_column_per: '',
        formula_dosage_column_qty_received: '',
        formula_dosage_column_price: '',
        formula_dosage_column_subtotal: '',
        formula_dosage_column_action: '',
        formula_dosage_column_g_per_day: '',
        formula_dosage_column_no_day_bag: '',
        formula_dosage_column_herb_add: '',
        extra_data: '',
        is_duplicate: false,
        ratio1: '',
        ratio2: '',
        // Per Ingredient Data
        ing_id: '', // per ingredient id
        ing_key: '', // per ingredient name
        ing_name: '', // per ingredient display name
        default_measurment: '',
        default_measurment_name: '',
        defaultratio: '0', //[1,0] // per ingredient allow ratio
        ratio_type: '', //[information_only,will_be_used_for_calculating_dosages] // per ingredient ratio type
        ratioingredient1: '', // per ingredient ratioingredient1 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
        ratioingredient2: '', // per ingredient ratioingredient2 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
        perRatio1: '', // per ingredient ratio
        perRatio2: '', // per ingredient ratio2
        is_loading: false,
      })
      existingIds.add(newId)
    }
    // update main Ingredients
    var temp = [...data, ...newIngredients]
    setTableRowsData(temp)
    // setData(temp)
  }, [data])

  useEffect(() => {
    setTimeout(() => {
      const existingIds = new Set(data.map((item) => item.id))
      const newIngredients = []
      for (let i = 0; i < minRows; i++) {
        let newId = uuidv4() // Generate a unique ID
        while (existingIds.has(newId)) {
          newId = uuidv4() // Regenerate ID if it already exists
        }
        newIngredients.push({
          id: newId,
          ingredient_id: '',
          formula_dosage_column_all_names: '',
          formula_dosage_column_supplier: '',
          formula_dosage_column_form: '', // per ingredient display name
          formula_dosage_column_raw_per_day: '',
          formula_dosage_column_ratio: '',
          formula_dosage_column_enter_qty: '',
          formula_dosage_column_day: '',
          formula_dosage_column_per: '',
          formula_dosage_column_qty_received: '',
          formula_dosage_column_price: '',
          formula_dosage_column_subtotal: '',
          formula_dosage_column_action: '',
          formula_dosage_column_g_per_day: '',
          formula_dosage_column_no_day_bag: '',
          formula_dosage_column_herb_add: '',
          extra_data: '',
          is_duplicate: false,
          ratio1: '',
          ratio2: '',
          // Per Ingredient Data
          ing_id: '', // per ingredient id
          ing_key: '', // per ingredient name
          ing_name: '', // per ingredient display name
          default_measurment: '',
          default_measurment_name: '',
          defaultratio: '0', //[1,0] // per ingredient allow ratio
          ratio_type: '', //[information_only,will_be_used_for_calculating_dosages] // per ingredient ratio type
          ratioingredient1: '', // per ingredient ratioingredient1 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
          ratioingredient2: '', // per ingredient ratioingredient2 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
          perRatio1: '', // per ingredient ratio
          perRatio2: '', // per ingredient ratio2
          is_loading: false,
        })
        existingIds.add(newId)
      }
      // update main Ingredients
      var temp = [...data, ...newIngredients]
      setData(temp)
    }, 1000)
  }, [minRows])

  const handleDragRow = (sourceId, targetId) => {
    var finalArr = sort(data, sourceId, targetId)
    //setItemData(finalArr)
    setData(finalArr)
    // handleIngredientPercent()
  }

  if (columns.length === 0) {
    return (
      <Placeholder.Grid
        rows={10}
        columns={columns.length === 0 ? 8 : columns.length}
        active
      />
    )
  }

  const getIngredientDetails = async (Herb_Id) => {
    const response = await ApiFormulaBulderIngredientDetails({
      formulaBuilderId,
      Herb_Id,
      pracId,
    })
    const herbData = await response
    return herbData
  }

  const getMultipleIngredientDetails = async (Herb_Id) => {
    const response = await ApiFormulaBulderMultipleIngredientDetails({
      formulaBuilderId,
      Herb_Id,
      pracId,
    })
    const herbData = await response
    return herbData
  }

  const measurement_options_arr = measurment_type_list
    ? measurment_type_list
    : []

  const HerbTagOptions = ingrediuentsTags.map((item) => ({
    label: item.name,
    value: item.id,
  }))

  const handleIngredientChange = async (value, id, key) => {
    if (value != '' && value != null && value != undefined) {
      var thisIng = genrateIngredientArr(value, id, [])
      setData((prevdata) =>
        produce(prevdata, (draft) => {
          const index = draft.findIndex((val) => val.id === id)
          if (index !== -1) {
            draft[index] = thisIng
          }
        })
      )

      const response = await getIngredientDetails(value)
      if (response.length == 0) {
        // addMessage('Ingredient ' + value + 'not matched.', 'error')
        customNotificationMessage({
          text: 'Ingredient ' + value + 'not matched.',
          status: 'error',
          key: 'common',
          fun: setMessages,
          type: 'add',
        })
        return false
      }

      var thisIng = genrateIngredientArr(value, id, response)

      setData((prevdata) =>
        produce(prevdata, (draft) => {
          const index = draft.findIndex((val) => val.id === id)
          if (index !== -1) {
            draft[index] = thisIng
          }
        })
      )
      //setItemData(data)
      const updatedIngredients = data.map((ingredient) => {
        if (ingredient.id === id) {
          return thisIng
        }
        return ingredient
      })
      const selectedIngredient = updatedIngredients.filter(
        (ingredient) => ingredient.formula_dosage_column_form
      ).length
      setItemData(updatedIngredients)
      setSelectedIngredients(selectedIngredient)
      setTestData(updatedIngredients)
      //})
    } else {
      //alert('null ingredient')
      //handleIngredientDelete(id)
      empyThisIngredient(id)
    }
  }

  const handleIngredientQty = async (
    value,
    id,
    key,
    array,
    conversion_type = 'do_not_convert'

    //dosagesDays
  ) => {
    try {
      const finalArr = await qtyConversion(
        key,
        value,
        array,
        conversion_type
        //array.formula_dosage_column_day
      )
      // update decimal
      // finalArr['formula_dosage_column_enter_qty'] = parseFloat(
      //   finalArr['formula_dosage_column_enter_qty']
      // ).toFixed(dacimalPlace)
      // finalArr['formula_dosage_column_raw_per_day'] = parseFloat(
      //   finalArr['formula_dosage_column_raw_per_day']
      // ).toFixed(dacimalPlace)
      if (value != '') {
        // update decimal
        finalArr['formula_dosage_column_enter_qty'] = parseFloat(
          finalArr['formula_dosage_column_enter_qty']
        )
        finalArr['formula_dosage_column_raw_per_day'] = parseFloat(
          finalArr['formula_dosage_column_raw_per_day']
        )
      } else {
        finalArr['formula_dosage_column_raw_per_day'] = ''
        finalArr['formula_dosage_column_enter_qty'] = ''
      }
      var thisupdatedIngredients = convert_mesurement(finalArr)
      var final =
        thisupdatedIngredients != undefined && thisupdatedIngredients != ''
          ? thisupdatedIngredients
          : finalArr

      const updatedIngredients = [...data]
      const index = updatedIngredients.findIndex(
        (ingredient) => ingredient.id === id
      )
      if (index !== -1) {
        updatedIngredients[index] = final
      }
      setData(updatedIngredients)
      setItemData(updatedIngredients)

      handleIngredientPercent()
      getformulaSummary(updatedIngredients)
      if (
        (ingredient_selection_type ===
          'MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT' &&
          formulaData.allow_multiple_measurement_types_in_same_formula ===
            '0') ||
        (ingredient_selection_type ===
          'ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES' &&
          allow_multiple_measurements === false) ||
        ingredient_selection_type ===
          'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE' ||
        ingredient_selection_type ===
          'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS'
      ) {
        adjustQtyReceived(
          updatedIngredients,
          'formula_dosage_column_qty_received',
          'formula_dosage_column_enter_qty',
          totalQty,
          dacimalPlace
        )
      }
    } catch (error) {
      // Handle any error that occurred during qtyConversion()

      console.error('An error occurred during qtyConversion:', error)
    }
  }

  const qtyConversion = (
    field,
    qty,
    array,
    conversion_type = 'do_not_convert'
    //dosagesDays = 1
  ) => {
    //alert(dosages_key)
    //console.log('QtyConversion', field, qty, array, conversion_type)
    var qtyField = '' //default_qty_field.toLowerCase()

    if (
      default_qty_field !== undefined &&
      default_qty_field != null &&
      default_qty_field != ''
    ) {
      var qtyField = default_qty_field.toLowerCase()
      // Now you can use qtyField for further operations
    } else {
      console.error('default_qty_field is undefined')
    }

    var price = parseFloat(array.formula_dosage_column_price)
    var qtyValue = parseFloat(qty) || 0
    var ingQty = 1
    var rawQty = 1
    var ratioText = ''
    // calculate Qty
    if (conversion_type === 'ingredient_ratio') {
      var ratio1 = 1
      var ratio2 = 1
      if (array.defaultratio == 1) {
        ratio1 = parseFloat(array.ratio1)
        ratio2 = parseFloat(array.ratio2)
        if (!ratio1 || !ratio2) {
          if (array.ratio_type == 'information_only') {
            ratio1 = parseFloat(array.perRatio1) || 1
            ratio2 = parseFloat(array.perRatio2) || 1
          } else if (
            array.ratio_type == 'will_be_used_for_calculating_dosages'
          ) {
            ratio1 = parseFloat(array.perRatio1) || 1
            ratio2 = parseFloat(array.perRatio2) || 1
          }
        }
      } else {
        ratio1 = parseFloat(array.ratio1) || 1
        ratio2 = parseFloat(array.ratio2) || 1
        // console.log('Error: ingredient_ratio: per ingredient not allow ratio')
      }
      // console.log('information_only -> array.ratio_type -> ratio :', [
      //   ratio1,
      //   ratio2,
      // ])
      if (field == 'formula_dosage_column_enter_qty') {
        ingQty = qtyValue
        rawQty = qtyValue * (ratio2 / ratio1)
      } else if (field == 'formula_dosage_column_raw_per_day') {
        ingQty = qtyValue * (ratio1 / ratio2)
        rawQty = qtyValue
      } else {
        console.log('Error: ingredient_ratio: field not match')
      }
      ratioText = ratio1 + ':' + ratio2
    } else if (conversion_type === 'fixed_ratio') {
      var ratio1 = 1
      var ratio2 = 1
      var thisIngredientKey = array.ing_key.toUpperCase()
      var ratioArr = formulaDosagesSetting.ratio
        ? JSON.parse(formulaDosagesSetting.ratio)
        : []
      //console.log('fixed_ratio', thisIngredientKey, ratioArr)
      // var thisIngredientRatio = Object.keys(ratioArr)
      //   .filter((key) => key.includes(thisIngredientKey))
      //   .map((key) => ratioArr[key])
      var thisIngredientRatio =
        ratioArr && ratioArr[thisIngredientKey]
          ? ratioArr[thisIngredientKey]
          : []

      if (thisIngredientRatio.length != 0) {
        ratio1 = parseFloat(thisIngredientRatio.ratio1) || 1
        ratio2 = parseFloat(thisIngredientRatio.ratio2) || 1
      } else {
        console.log('Error: fixed_ratio: per ingredient ratio not exist')
      }
      console.log('fixed_ratio -> ratio :', [ratio1, ratio2])
      ratioText = ratio1 + ':' + ratio2
      if (field == 'formula_dosage_column_enter_qty') {
        ingQty = qtyValue
        rawQty = qtyValue * (ratio2 / ratio1)
      } else if (field == 'formula_dosage_column_raw_per_day') {
        ingQty = qtyValue * (ratio1 / ratio2)
        rawQty = qtyValue
      } else {
        console.log('Error: fixed_ratio: field not match')
      }
    } else if (conversion_type === 'do_not_convert') {
      ratio1 = parseFloat(array.ratio1) || 1
      ratio2 = parseFloat(array.ratio2) || 1
      ratioText = ratio1 + ':' + ratio2
      ingQty = qtyValue
      rawQty = qtyValue
    } else {
      ratio1 = parseFloat(array.ratio1) || 1
      ratio2 = parseFloat(array.ratio2) || 1
      ratioText = ratio1 + ':' + ratio2
      ingQty = qtyValue
      rawQty = qtyValue
    }
    //////////////////////////////////////////////
    /*ingQty = ingQty
      .replace(/[^\d.]/g, '') // Numbers and decimals only
      .replace(/(^[\d]{9})[\d]/g, '$1') // Not more than 2 digits at the beginning
      .replace(/(\..*)\./g, '$1') // Decimal can't exist more than once
      .replace(/(\.[\d]{2})./g, '$1') // Not more than 2 digits after decimal
    rawQty = rawQty
      .replace(/[^\d.]/g, '') // Numbers and decimals only
      .replace(/(^[\d]{9})[\d]/g, '$1') // Not more than 2 digits at the beginning
      .replace(/(\..*)\./g, '$1') // Decimal can't exist more than once
      .replace(/(\.[\d]{2})./g, '$1') // Not more than 2 digits after decimal
      */
    /////////////////////////////////////////////
    //ingQty = parseFloat(ingQty).toFixed(dacimalPlace)
    //rawQty = parseFloat(rawQty).toFixed(dacimalPlace)
    var this_decimal = array['final_decimal_place']
    const updatedArray = {
      ...array,
      formula_dosage_column_enter_qty: ingQty,
      formula_dosage_column_raw_per_day: rawQty,
      formula_dosage_column_day: dosagesDays,
      formula_dosage_column_no_day_bag: dosagesBags,
    }
    if (ratioText) {
      updatedArray.formula_dosage_column_ratio = ratioText
    }
    //var main_qty = 0
    if (qtyField == 'formula_dosage_column_enter_qty') {
      var main_qty = parseFloat(ingQty)
    }
    //  else if (qtyField == 'formula_dosage_column_qty_received') {
    //   var main_qty = parseFloat(qtyField)
    // }
    else {
      var main_qty = parseFloat(rawQty)
    }

    if (dosages_key == 'per') {
      var finalQty = main_qty
      var subtotal = main_qty * price
    } else if (dosages_key == 'per' && totalQty) {
      var finalQty = parseFloat(qtyField)
    } else if (dosages_key == 'bag') {
      var finalQty = dosagesBags * main_qty
      var subtotal = dosagesBags * main_qty * price
    } else if (dosages_key == 'daily') {
      var finalQty = dosagesDays * main_qty
      var subtotal = dosagesDays * main_qty * price
    }
    //console.log(dosages_key, main_qty, finalQty)
    //console.log('finalQty', finalQty, subtotal)
    //subtotal = parseFloat(subtotal) || 0
    //subtotal = subtotal.toFixed(dacimalPlace)
    updatedArray.formula_dosage_column_qty_received_original =
      finalQty !== NaN ? parseFloat(finalQty).toFixed(this_decimal) : 0
    updatedArray.formula_dosage_column_qty_received =
      finalQty !== NaN ? parseFloat(finalQty).toFixed(this_decimal) : 0
    updatedArray.formula_dosage_column_subtotal =
      subtotal !== NaN ? parseFloat(subtotal).toFixed(this_decimal) : 0

    return updatedArray
  }

  const decimalLength = (number) => {
    // Convert the number to a string
    const numString = number.toString()

    // Check if the number has a decimal point
    if (numString.includes('.')) {
      // Find the index of the decimal point
      const decimalIndex = numString.indexOf('.')

      // Calculate the length of the decimal part
      const decimalLength = numString.length - 1 - decimalIndex

      return decimalLength
    } else {
      // If there is no decimal point, the length is 0
      return 0
    }
  }

  const handleIngredientPercent = () => {
    if (totalQty && formulaDosagesType == 'per') {
      var qtyField = 'formula_dosage_column_qty_received'
    } else {
      var qtyField = default_qty_field.toLowerCase()
    }

    const finalQtyArr = data.map((v) => parseFloat(v[qtyField]) || 0)

    const finalQtySum = finalQtyArr.reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) + parseFloat(currentValue),
      0
    )
    setData((prevData) =>
      produce(prevData, (draft) => {
        const finalQtyArr = draft.map((v) => parseFloat(v[qtyField]) || 0)
        const finalQtySum = finalQtyArr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        )
        draft.forEach((array) => {
          if (array.ingredient_id != '') {
            var qty = parseFloat(array[qtyField]) || 0
            var this_decimal = array['final_decimal_place']
            if (qty || qty == 0) {
              var per = (qty / finalQtySum) * 100
              per = parseFloat(per) || 0
              array.formula_dosage_column_per = per.toFixed(this_decimal) + '%' //dacimalPlace
            }
          }
        })
      })
    )
  }

  const handleIngredientPercent22 = () => {
    setData((prevData) => {
      const finalQtyArr = prevData.map((v) => parseFloat(v.qty) || 0)
      const finalQtySum = finalQtyArr.reduce(
        (acc, currentValue) => acc + currentValue,
        0
      )

      const updatedData = prevData.map((item) => {
        const qty = parseFloat(item.qty) || 0
        const per = qty ? (qty / finalQtySum) * 100 : 0
        return {
          ...item,
          formula_dosage_column_per: per.toFixed(2) + '%',
        }
      })

      //setItemData(updatedData) // Send data to parent component
      return updatedData
    })
  }

  const handleIngredientDeleteMain = (id, is_convert = false) => {
    setData((prevdata) => {
      const index = prevdata.findIndex((item) => item.id === id)
      const newData = [...prevdata]
      newData.splice(index, 1)
      setItemData(newData)
      return newData
    })
  }

  const handleIngredientDelete = async (rowData, is_convert = false) => {
    // If rowData is a single object, delete it
    setData((prevdata) => {
      return prevdata.filter(
        (updatedIngredients) => updatedIngredients.id !== rowData.id
      )
    })

    // Handle other asynchronous operations
    handleIngredientPercent()

    // Update other states and perform additional asynchronous operations
    const updatedIngredients = data.filter(
      (ingredient) => ingredient.id !== rowData.id
    )
    setItemData(updatedIngredients)

    return new Promise((resolve) => {
      setTimeout(() => {
        handleIngredientPercent()
        adjustQtyReceived(
          updatedIngredients,
          'formula_dosage_column_qty_received',
          'formula_dosage_column_enter_qty',
          totalQty,
          dacimalPlace
        )
        getformulaSummary(updatedIngredients)

        resolve()
      }, 100)
    })
  }
  const handleIngredientTags = (tag, id, key, array) => {
    const updatedArray = { ...array, formula_dosage_column_herb_add: tag }
    const updatedIngredients = data.map((ingredient) => {
      if (ingredient.id === id) {
        return updatedArray
      }
      return ingredient
    })
    setItemData(updatedIngredients)
  }
  const handleIngredientMeasurment = (
    this_measurment,
    id,
    key,
    array,
    type
  ) => {
    //alert([this_measurment, type, allow_multiple_measurements])
    if (
      type == 'ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES' &&
      allow_multiple_measurements == false
    ) {
      // console.log('handleIngredientMeasurment-if')
      //alert(allow_multiple_measurements)
      // const updatedIngredients = data.map((ingredient) => {
      //   ingredient.default_measurment = this_measurment
      //   return ingredient
      // })
      const updatedIngredients = data.map((ingredient) => {
        var arr = {
          ...ingredient, // Clone the ingredient
          default_measurment: this_measurment, // Override the default_measurment property
        }
        // console.log(
        //   'updated',
        //   arr.default_measurment,
        //   arr.ing_default_measurment_id
        // )
        if (arr.ingredient_id != '') {
          var thisupdatedIngredients = convert_mesurement(arr)
          var final =
            thisupdatedIngredients != undefined && thisupdatedIngredients != ''
              ? thisupdatedIngredients
              : arr
          return final
        } else {
          return arr
        }
      })

      setData(updatedIngredients)
      setItemData(updatedIngredients)
    } else {
      const updatedArray = { ...array, default_measurment: this_measurment }
      var thisupdatedIngredients = convert_mesurement(updatedArray)
      var final =
        thisupdatedIngredients != undefined && thisupdatedIngredients != ''
          ? thisupdatedIngredients
          : updatedArray
      const updatedIngredients = data.map((ingredient) => {
        if (ingredient.id === id) {
          return final
        }
        return ingredient
      })
      setData(updatedIngredients)
      setItemData(updatedIngredients)
    }
  }
  function Ingredient_Measurement({
    ingredient_selection_type,
    rowData,
    measurementOptions,
    defaultMeasuremt,
    allow_multiple_measurements,
  }) {
    if (ingredient_selection_type == '' || rowData.ingredient_id == '') {
      return ''
    }
    if (
      ingredient_selection_type == 'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE'
    ) {
      return measurementOptions.this_measurement.map(
        (value1, k) => value1.display_name
      )
    } else if (
      ingredient_selection_type ===
      'ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES'
    ) {
      const this_measurement = measurementOptions.this_measurement
        ? measurementOptions.this_measurement
        : []
      return (
        <select
          placeholder='Measurement'
          value={measurementOptions.default_measurement}
          onChange={(e) => {
            handleIngredientMeasurment(
              e.target.value,
              rowData.id,
              'default_measurment',
              rowData,
              ingredient_selection_type
            )
          }}
        >
          {this_measurement.map((option) => (
            <option key={option.name} value={option.id}>
              {option.display_name}
            </option>
          ))}
        </select>
      )
    } else if (
      ingredient_selection_type ===
      'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_PER_INGREDIENT'
    ) {
      const foundOption = measurementOptions.find((option) => {
        return rowData['ing_id'] === option['ingredient_id']
      })
      if (rowData.ingredient_id != '' && foundOption) {
        const this_measurement = foundOption.this_measurement
        return this_measurement && this_measurement != 0
          ? this_measurement[0]['display_name']
          : ''
      }
      // per ingredient wise measurement
      return ''
    } else if (
      ingredient_selection_type ==
      'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS'
    ) {
      const foundOption =
        measurementOptions && measurementOptions.length > 0
          ? measurementOptions[0]
          : null // You can use null or another appropriate default value

      if (rowData.ingredient_id != '' && foundOption) {
        const this_measurement = foundOption.this_measurement
        return this_measurement && this_measurement != 0
          ? this_measurement[0]['id']
          : ''
      }
    } else if (
      ingredient_selection_type ==
      'MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT'
    ) {
      const foundOption = measurementOptions.find((option) => {
        return rowData['ing_id'] === option['ingredient_id']
      })

      if (rowData.ingredient_id !== '' && foundOption) {
        const this_measurement = foundOption.this_measurement
        return (
          <select
            placeholder='Measurement'
            value={measurementOptions.default_measurement}
            onChange={(e) => {
              handleIngredientMeasurment(
                e.target.value,
                rowData.id,
                'default_measurment',
                rowData,
                ingredient_selection_type
              )
            }}
          >
            {this_measurement.map((option) => (
              <option key={option.name} value={option.id}>
                {option.display_name}
              </option>
            ))}
          </select>
        )
      }
    }
  }

  const empyThisIngredient = (id) => {
    const updatedIngredients = [...data]
    const index = updatedIngredients.findIndex(
      (ingredient) => ingredient.id === id
    )
    var emptythisIng = {
      id: id,
      ingredient_id: '',
      formula_dosage_column_all_names: '',
      formula_dosage_column_supplier: '',
      formula_dosage_column_form: '',
      formula_dosage_column_raw_per_day: '',
      formula_dosage_column_ratio: '',
      formula_dosage_column_enter_qty: '',
      formula_dosage_column_day: '',
      formula_dosage_column_per: '',
      formula_dosage_column_qty_received: '',
      formula_dosage_column_price: '',
      formula_dosage_column_subtotal: '',
      formula_dosage_column_action: '',
      formula_dosage_column_g_per_day: '',
      formula_dosage_column_no_day_bag: '',
      formula_dosage_column_herb_add: '',
      extra_data: '',
      is_duplicate: false,
      ratio1: '',
      ratio2: '',
      // Per Ingredient Data
      ing_id: '', // per ingredient id
      ing_key: '', // per ingredient name
      ing_name: '', // per ingredient display name
      default_measurment: '',
      defaultratio: '0', //[1,0] // per ingredient allow ratio
      ratio_type: '', //[information_only,will_be_used_for_calculating_dosages] // per ingredient ratio type
      ratioingredient1: '', // per ingredient ratioingredient1 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
      ratioingredient2: '', // per ingredient ratioingredient2 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
      perRatio1: '', // per ingredient ratio
      perRatio2: '', // per ingredient ratio2
    }
    if (index !== -1) {
      updatedIngredients[index] = emptythisIng
    }
    //console.log('renderTable', updatedIngredients)
    setData(updatedIngredients)
    setItemData(updatedIngredients)
  }
  const updateIngredients = () => {
    var temp = []
    //var qtyField = default_qty_field.toLowerCase()
    var qtyField = '' //default_qty_field.toLowerCase()
    if (
      default_qty_field !== undefined &&
      default_qty_field != null &&
      default_qty_field != ''
    ) {
      var qtyField = default_qty_field.toLowerCase()
      // Now you can use qtyField for further operations
    } else {
      console.error('default_qty_field is undefined')
    }

    const updatedData = data.map((array, key) => {
      // Clone the array object to avoid directly modifying the state
      const updatedArray = { ...array }

      updatedArray.formula_dosage_column_no_day_bag = dosagesBags
      updatedArray.formula_dosage_column_day = dosagesDays

      const finalArr = qtyConversion(
        qtyField,
        array[qtyField],
        array,
        conversion_type
      )
      temp.push(finalArr)
      return updatedArray
    })

    const finalQtyArr = temp.map((v) => parseFloat(v[qtyField]) || 0)
    const finalQtySum = finalQtyArr.reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) + parseFloat(currentValue),
      0
    )
    temp.map((array, key) => {
      var qty = parseFloat(array[qtyField]) || 0
      var this_decimal = array['final_decimal_place']
      if (qty) {
        var per = (qty / finalQtySum) * 100
        per = parseFloat(per) || 0
        temp[key].formula_dosage_column_per = per.toFixed(this_decimal) + '%'
      }
    })
    setData(temp)
  }

  function customSort(search, a, b) {
    if (a.label.startsWith(search) && !b.label.startsWith(search)) {
      return -1
    } else if (!a.label.startsWith(search) && b.label.startsWith(search)) {
      return 1
    }
    return a.label.localeCompare(b.label)
  }

  const convert = () => {
    if (prev_converting) {
      console.log('pre_convert', prev_converting)
    }
    console.log('converting.....', prevBuilderKey, converting['tinctures'])

    const nameArr = formulaIngredients['name_type']
    //console.log('formulaIngredientsIdArr', formulaIngredientsIdArr)
    data
      .filter((v) => v.ingredient_id !== null && v.ingredient_id !== '')
      .map((array, l) => {
        var id = array.id
        var ingredient_id = array.ingredient_id
        var ing_name = array.formula_dosage_column_all_names
        var convertIn = prevBuilderKey
        var allow_convert = ''

        if (ingredient_id != '') {
          var case1 = formulaIngredientsIdArr.includes(ingredient_id)
          var case2 = formulaIngredientsIdArr.indexOf(ingredient_id)
          //console.log('converting ingredient', ingredient_id, case1, array)
          if (case1 == true) {
            console.log(ingredient_id + ' Exact match')
          } else {
            //var similar = []
            var AllSimilar = []
            nameArr.forEach((key) => {
              var arr = formulaIngredients['formula_dosage_column_' + key]
              if (arr) {
                var searchName = array.extra_data[key]

                if (
                  searchName != '' &&
                  searchName != null &&
                  searchName != undefined
                ) {
                  //console.log('serach name ' + searchName)
                  var similarItems = arr.filter((item) => {
                    // Check if ing_name is not null or undefined before calling toLowerCase()
                    if (item.ing_name) {
                      return item.ing_name
                        .toLowerCase()
                        .includes(searchName.toLowerCase())
                    }
                    return false // Return false for items with null ing_name
                  })
                  if (similarItems.length != 0) {
                    AllSimilar = [...AllSimilar, ...similarItems]
                  }
                }
              }
            })

            // Create a Set to keep track of unique items
            const uniqueItemsSet = new Set()
            // Filter out duplicate items and add unique items to the Set
            const similar = AllSimilar.filter((item) => {
              if (!uniqueItemsSet.has(item.value)) {
                uniqueItemsSet.add(item.value)
                return true
              }
              return false
            })

            if (similar.length != 0) {
            } else {
            }

            console.log('similar ' + ingredient_id, similar)
          }
        }
      })
  }
  const addMoreRows = () => {
    const existingIds = new Set(data.map((item) => item.id))
    const newIngredients = []
    for (let i = 0; i < minRows; i++) {
      let newId = uuidv4() // Generate a unique ID
      while (existingIds.has(newId)) {
        newId = uuidv4() // Regenerate ID if it already exists
      }
      newIngredients.push({
        id: newId,
        ingredient_id: '',
        formula_dosage_column_all_names: '',
        formula_dosage_column_supplier: '',
        formula_dosage_column_form: '', // per ingredient display name
        formula_dosage_column_raw_per_day: '',
        formula_dosage_column_ratio: '',
        formula_dosage_column_enter_qty: '',
        formula_dosage_column_day: '',
        formula_dosage_column_per: '',
        formula_dosage_column_qty_received: '',
        formula_dosage_column_price: '',
        formula_dosage_column_subtotal: '',
        formula_dosage_column_action: '',
        formula_dosage_column_g_per_day: '',
        formula_dosage_column_no_day_bag: '',
        formula_dosage_column_herb_add: '',
        extra_data: '',
        is_duplicate: false,
        ratio1: '',
        ratio2: '',
        // Per Ingredient Data
        ing_id: '', // per ingredient id
        ing_key: '', // per ingredient name
        ing_name: '', // per ingredient display name
        default_measurment: '',
        default_measurment_name: '',
        defaultratio: '0', //[1,0] // per ingredient allow ratio
        ratio_type: '', //[information_only,will_be_used_for_calculating_dosages] // per ingredient ratio type
        ratioingredient1: '', // per ingredient ratioingredient1 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
        ratioingredient2: '', // per ingredient ratioingredient2 type when Per ingredient ratio type is *will_be_used_for_calculating_dosages*
        perRatio1: '', // per ingredient ratio
        perRatio2: '', // per ingredient ratio2
        is_loading: false,
      })
      existingIds.add(newId)
    }
    // update main Ingredients
    var temp = [...data, ...newIngredients]
    setData(temp)
    //onIngredientSectionChange([...ingredients, ...newIngredients])
  }
  const AddMoreRowButton = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        <button type='button' onClick={addMoreRows} className='btn btn-primary'>
          Add More Rows
        </button>
      </div>
    )
  }
  const addDecimal = async (
    value,
    id,
    key,
    array,
    conversion_type = 'do_not_convert'
    //dosagesDays
  ) => {
    try {
      const finalArr = await qtyConversion(
        key,
        value,
        array,
        conversion_type
        //array.formula_dosage_column_day
      )
      var this_decimal = finalArr['final_decimal_place']
      if (value != '') {
        // update decimal
        finalArr['formula_dosage_column_enter_qty'] = parseFloat(
          finalArr['formula_dosage_column_enter_qty']
        ).toFixed(this_decimal) //dacimalPlace
        finalArr['formula_dosage_column_raw_per_day'] = parseFloat(
          finalArr['formula_dosage_column_raw_per_day']
        ).toFixed(this_decimal) //dacimalPlace
      } else {
        finalArr['formula_dosage_column_raw_per_day'] = ''
      }
      var thisupdatedIngredients = convert_mesurement(finalArr)
      var final =
        thisupdatedIngredients != undefined && thisupdatedIngredients != ''
          ? thisupdatedIngredients
          : finalArr
      // console.log('mesurementConversion', thisupdatedIngredients)
      //console.log('qtyConversion', finalArr)
      const updatedIngredients = [...data]
      const index = updatedIngredients.findIndex(
        (ingredient) => ingredient.id === id
      )
      if (index !== -1) {
        updatedIngredients[index] = final // finalArr,thisupdatedIngredients
      }
      //console.log('renderTable', updatedIngredients)
      setData(updatedIngredients)
      //handleIngredientPercent()
      //setItemData(data)
    } catch (error) {
      // Handle any error that occurred during qtyConversion()
      console.error('An error occurred during qtyConversion:', error)
    }
  }
  const genrateIngredientArr = (value, id, response = [], formula_id = 0) => {
    // if (response.length === 0) {
    //   return []
    // }
    //console.log('genrateIngredientArr', response)
    const ratio1 = parseFloat(response?.ratio) || 1
    const ratio2 = parseFloat(response?.ratio2) || 1
    const thisIng = {
      id: id,
      ingredient_id: response.length === 0 ? value : parseInt(response?.id),
      formula_dosage_column_all_names: response?.name,
      formula_dosage_column_supplier: response?.suplier_name,
      formula_dosage_column_form: response?.ing_name,
      formula_dosage_column_raw_per_day: '',
      formula_dosage_column_g_per_day: '',
      formula_dosage_column_ratio: ratio1 + ':' + ratio2,
      formula_dosage_column_enter_qty: '',
      formula_dosage_column_day: dosagesDays,
      formula_dosage_column_per: (0).toFixed(dacimalPlace) + '%',
      formula_dosage_column_qty_received: (0).toFixed(dacimalPlace),
      formula_dosage_column_price_original:
        response.length !== 0 ? response?.unit_price : 0,
      formula_dosage_column_price:
        response.length !== 0 ? response?.unit_price : 0,
      formula_dosage_column_subtotal: (0).toFixed(dacimalPlace),
      formula_dosage_column_action: '',
      formula_dosage_column_no_day_bag: dosagesBags,
      formula_dosage_column_herb_add: '',
      is_duplicate: data.some((row) => row.ingredient_id == value),
      ratio1: response?.ratio,
      ratio2: response?.ratio2,
      ing_id: response?.ingredient_id,
      ing_key: response?.ing_key,
      ing_name: response?.ing_name,
      ing_default_measurment_id: response?.default_measurment_id,
      default_measurment: '',
      default_measurment_name: '',
      defaultratio: response?.ing_defaultratio,
      ratio_type: response?.ing_ratio_type,
      ratioingredient1: response?.ratioingredient1,
      ratioingredient2: response?.ratioingredient2,
      perRatio1: response?.ing_ratio1,
      perRatio2: response?.ing_ratio2,
      extra_data: response,
      formula_builder_id: formulaBuilderId,
      formula_id: formula_id,
      ing_decimal_place: countDecimalPlace(response?.ing_decimal_place),
      decimal_place: countDecimalPlace(response?.ing_decimal_place),
      is_loading: response.length !== 0 ? false : true,
    }
    if (ingredient_decimal.length != 0) {
      var ingredient_type = response?.ingredient_id
      var thisIngredientAllowDecimalPlaces = ingredient_decimal[ingredient_type]
      if (
        thisIngredientAllowDecimalPlaces != undefined &&
        thisIngredientAllowDecimalPlaces != ''
      ) {
        thisIng['decimal_place'] = countDecimalPlace(
          thisIngredientAllowDecimalPlaces
        )
      }
    }
    var thisDecimalPlaces = IngredientDecimalPlaces(thisIng)
    var this_decimal = thisDecimalPlaces ? thisDecimalPlaces : dacimalPlace
    thisIng['final_decimal_place'] = this_decimal
    thisIng['formula_dosage_column_per'] = (0).toFixed(this_decimal) + '%'
    thisIng['formula_dosage_column_qty_received'] = (0).toFixed(this_decimal)
    thisIng['formula_dosage_column_subtotal'] = (0).toFixed(this_decimal)
    if (namesType.length != 0) {
      namesType.map((val, kk) => {
        thisIng['formula_dosage_column_' + val] =
          response && response[val] != '' ? response[val] : ''
      })
    }

    if (
      ingredient_selection_type == 'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE'
    ) {
      thisIng.default_measurment = measurementOptions.default_measurement
    } else if (
      ingredient_selection_type ==
        'ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES' &&
      allow_multiple_measurements == true
    ) {
      thisIng.default_measurment = measurementOptions.default_measurement
    } else if (
      ingredient_selection_type ==
        'ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES' &&
      allow_multiple_measurements == false
    ) {
      const getFirstIndex = data[0].default_measurment

      if (getFirstIndex) {
        thisIng.default_measurment = getFirstIndex
      } else {
        thisIng.default_measurment = measurementOptions.default_measurement
      }
    } else if (
      ingredient_selection_type ==
        'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_PER_INGREDIENT' ||
      ingredient_selection_type ==
        'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS'
    ) {
      // const foundOption = Object.values(measurementOptions).find((option) => {
      //   return response?.ingredient_id == option['ingredient_id']
      // })
      // if (foundOption) {
      //   thisIng.default_measurment = foundOption[0].this_measurement[0].id
      // }

      if (measurementOptions) {
        thisIng.default_measurment =
          measurementOptions[0].this_measurement[0].id
      }
    } else if (
      ingredient_selection_type ==
      'MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT'
    ) {
      if (response?.ingredient_id) {
        const foundOption = Object.values(measurementOptions).find((option) => {
          return response?.ingredient_id == option['ingredient_id']
        })
        if (foundOption) {
          thisIng.default_measurment = foundOption.default_measurement
        }
      }
    }

    return thisIng
  }

  const convert_mesurement = (array) => {
    if (
      array.ingredient_id == '' ||
      array.ingredient_id == null ||
      array.ingredient_id == undefined
    ) {
      return array
    }
    // console.log(
    //   'convert_mesurement-start',
    //   array.ing_default_measurment_id,
    //   array.default_measurment,
    //   array
    // )
    var this_decimal = array['final_decimal_place']
    var from_unit = array.ing_default_measurment_id // ingredient type default unit
    var to_unit = array.default_measurment //user pick unit
    var price = parseFloat(array.formula_dosage_column_price_original) || 1
    var final_qty = parseFloat(array.formula_dosage_column_qty_received)
    var case1 = []
    var case2 = []
    var case3 = []
    //array.formula_dosage_column_qty_received_orignal =
    //  array.formula_dosage_column_qty_received
    if (from_unit == to_unit) {
      // console.log('mesurement-conversion - same_unit', from_unit, to_unit)
      array.formula_dosage_column_price = parseFloat(price).toFixed(3)
      array.formula_dosage_column_subtotal = parseFloat(
        final_qty * price
      ).toFixed(3)
      return array
    } else {
      var extra_data = array.extra_data
      //var measurment_conversions = []
      // get all tier conversion array
      if (
        extra_data.measurment_conversions != '' &&
        extra_data.measurment_conversions != null
      ) {
        var measurment_conversions = JSON.parse(
          extra_data.measurment_conversions
        )
        let result = []
        if (measurment_conversions.length != 0) {
          for (const key in measurment_conversions) {
            const conversion = measurment_conversions[key]
            if (
              (conversion.from_unit == from_unit &&
                conversion.to_unit == to_unit) ||
              (conversion.from_unit == to_unit &&
                conversion.to_unit == from_unit)
            ) {
              result = conversion
              break
            }
          }
          case1 = result
        }
      }
      if (
        extra_data.ing_measurment_conversions != '' &&
        extra_data.ing_measurment_conversions != null
      ) {
        var measurment_conversions = JSON.parse(
          extra_data.ing_measurment_conversions
        )
        let result = []
        if (measurment_conversions.length != 0) {
          for (const key in measurment_conversions) {
            const conversion = measurment_conversions[key]
            if (
              (conversion.from_unit == from_unit &&
                conversion.to_unit == to_unit) ||
              (conversion.from_unit == to_unit &&
                conversion.to_unit == from_unit)
            ) {
              result = conversion
              break
            }
          }
        }
        case2 = result
      }
      if (measurmentConversions.length != 0) {
        // var measurment_conversions = measurmentConversions
        let result = []

        if (measurmentConversions.length != 0) {
          for (const key in measurmentConversions) {
            const conversion = measurmentConversions[key]
            if (
              (conversion.from_unit == from_unit &&
                conversion.to_unit == to_unit) ||
              (conversion.from_unit == to_unit &&
                conversion.to_unit == from_unit)
            ) {
              result = conversion
              break
            }
          }
        }
        case3 = result
      }
      // console.log(
      //   'mesurement-conversion - match conversions',
      //   [from_unit, to_unit],
      //   case1,
      //   case2,
      //   case3,
      //   measurmentConversions
      // )
    }
    var finalCon = null
    if (case1 != undefined && case1.length != 0) {
      finalCon = case1
    } else if (case2 != undefined && case2.length != 0) {
      finalCon = case2
    } else if (case3 != undefined && case3.length != 0) {
      finalCon = case3
    } else {
      //alert('Error: Mesurement Conversion has not Matched')
      array.formula_dosage_column_price = parseFloat(price).toFixed(3)
      array.formula_dosage_column_subtotal = parseFloat(
        final_qty * price
      ).toFixed(3)
      return array
    }
    if (
      finalCon != undefined &&
      finalCon != null &&
      finalCon.from_value == undefined &&
      finalCon.to_unit == undefined &&
      finalCon.length > 1
    ) {
      console.log('Error:multiple array')
    }
    if (
      finalCon != null &&
      finalCon.from_unit == from_unit &&
      finalCon.to_unit == to_unit
    ) {
      var value =
        parseFloat(finalCon.from_value) / parseFloat(finalCon.to_value)
      var final_price = price * value
      array.formula_dosage_column_price = parseFloat(final_price).toFixed(3)
      array.formula_dosage_column_subtotal = parseFloat(
        final_qty * final_price
      ).toFixed(3)
    } else if (
      finalCon != null &&
      finalCon.from_unit == to_unit &&
      finalCon.to_unit == from_unit
    ) {
      var value =
        parseFloat(finalCon.to_value) / parseFloat(finalCon.from_value)
      var final_price = price * value
      array.formula_dosage_column_price = parseFloat(final_price).toFixed(3)
      array.formula_dosage_column_subtotal = parseFloat(
        final_qty * final_price
      ).toFixed(3)
    } else {
      array.formula_dosage_column_price = parseFloat(price).toFixed(3)
      array.formula_dosage_column_subtotal = parseFloat(
        final_qty * price
      ).toFixed(3)
    }
    return array
  }

  // const calculateColumnTotal = (data, columnId) => {
  //   const total = data.reduce(
  //     (acc, row) => acc + parseFloat(row[columnId] || 0),
  //     0
  //   )
  //   return parseFloat(total.toFixed(2)) // Round off to two decimal places
  // }
  // Object to store column totals
  // const columnTotals = {}

  // Populate column totals
  columns.forEach((column) => {
    // return false;
    columnTotals[column.id] = calculateColumnTotal(data, column.id)
  })

  const IngredientDecimalPlaces = (array) => {
    //ing_decimal_place,decimal_place,
    if (array.decimal_place != '' && array.decimal_place != undefined) {
      return array.decimal_place
    } else if (
      array.ing_decimal_place != '' &&
      array.ing_decimal_place != undefined
    ) {
      return array.ing_decimal_place
    } else {
      return dacimalPlace
    }
  }

  const formValidation = () => {
    // const filteredIngredients = data.filter((ingredient) => {
    //   return (
    //     ingredient.ingredient_id != null &&
    //     ingredient.ingredient_id != '' &&
    //     ingredient.ingredient_id != undefined
    //   )
    // })
    // const totalQtyReceived = filteredIngredients.reduce(
    //   (accumulator, ingredient) => {
    //     // Convert formula_dosage_column_qty_received to a number using parseFloat
    //     const qtyReceived = parseFloat(
    //       ingredient.formula_dosage_column_qty_received
    //     )
    //     // Check if qtyReceived is a valid number
    //     if (!isNaN(qtyReceived)) {
    //       // Add the valid qtyReceived to the accumulator
    //       return accumulator + qtyReceived
    //     }
    //     // If formula_dosage_column_qty_received is not a valid number, return the current accumulator
    //     return accumulator
    //   },
    //   0
    // )
    // formValue['ingredients'] = filteredIngredients
    // formValue['ingredients_count'] = filteredIngredients.length
    // formValue['ingredients_qty'] = totalQtyReceived
    // var Final_array = []
    // Object.values(requirements).map((i, key) => {
    //   const temp = {
    //     ...i,
    //     value: formValue[i.key],
    //     error: formError[i.key],
    //   }
    //   Final_array[i.key] = temp
    // })
    // thisFormData(Final_array, 'FORMULAS_FORMULA_INGREDIENTS')
  }

  function convertToSingleElementArray(objOrArray) {
    if (typeof objOrArray === 'object' && !Array.isArray(objOrArray)) {
      return [objOrArray]
    }
    return objOrArray
  }

  const TableFooter = ({ column, index, data1 }) => {
    // console.log(data1,"jjjjjjjjjjjj")
    const filteredArray = data1.filter((item) => item.ingredient_id !== '')

    let data = ''
    if (
      typeof columnTotals[column.id] === 'number' &&
      (column.key === 'FORMULA_DOSAGE_COLUMN_RAW_PER_DAY' ||
        column.key === 'FORMULA_DOSAGE_COLUMN_ENTER_QTY' ||
        column.key === 'FORMULA_DOSAGE_COLUMN_SUBTOTAL') &&
      !isNaN(columnTotals[column.id])
    ) {
      if (column.key === 'FORMULA_DOSAGE_COLUMN_SUBTOTAL') {
        data = `${currency}${columnTotals[column.id].toFixed(3)}`
      } else {
        data = columnTotals[column.id]
      }
    }

    if (column.key === 'FORMULA_DOSAGE_COLUMN_ALL_NAMES') {
      data = filteredArray.length
    }

    // if(index === 0){
    //   data = "Total:"
    // }
    const design = (
      <>
        <p>{data}</p>
      </>
    )
    return design
  }

  // ############################
  return (
    <>
      {/* <pre>{JSON.stringify(data[0], null, 2)}</pre> */}

      <div className='FormulaBuilderIngredientsSection'>
        <h2 className='title mt-5'>Formula Ingredients</h2>

        {/* | Mode: {formulaDosagesType} | DefaultConvert : {conversion_type} |
        minRows : {minRows} | DosagesDays :{dosagesDays} | dosagesBags :{' '}
        {dosagesBags} | seperator : "{seperator}" | default_qty_field :{' '}
        {default_qty_field} | allIngredints : "{data.length}" */}
        {/* // ingredient tabs && search */}
        <div className='row' style={{ marginBottom: '1rem' }}>
          {Object.entries(tabs).map(([key, value]) => {
            if (value?.key_value == 'FORMULA_INGREDIENT_TAB_SEARCH') {
              return (
                <div className='col-1' key={value.name}>
                  <Button onClick={() => setPopup(true)} className='col-12'>
                    {value.display_name}
                  </Button>
                  {/* popup */}
                  <Drawer
                    id='IngredientSearchDrawer'
                    size='full'
                    backdrop='static'
                    open={popup}
                    onClose={() => {
                      setIngredientTab('')
                      setPopup(false)
                    }}
                  >
                    <Drawer.Header>
                      <Drawer.Title>Advanced Ingredient Search</Drawer.Title>
                      <Drawer.Actions>
                        <Button onClick={() => setPopup(false)}>Close</Button>
                      </Drawer.Actions>
                    </Drawer.Header>
                    <Drawer.Body>
                      {/* <pre>{JSON.stringify(formulaIngredients, null, 2)}</pre> */}
                      <IngredientSearch
                        ingredients={formulaIngredients['all_ingredients']}
                        nameTypes={nameTypes}
                        filter={formulaIngredients['filter']}
                        selectedIngredients={data}
                        addIngredient={addIngredient}
                        formula_builder_id={formulaBuilderId}
                        ingredient_selection_type={ingredient_selection_type}
                        allowed_ingredients={allow_ingredients_type}
                        pracId={pracId}
                        updateAddedIngredients={updateAddedSearchIngredients}
                        tableColumns={columns}
                        formulaData={formulaData}
                        setFormulaIngredientsSearch={
                          setFormulaIngredientsSearch
                        }
                        formulaIngredientsSearch={formulaIngredientsSearch}
                      />
                    </Drawer.Body>
                  </Drawer>
                </div>
              )
            } else {
              var options =
                ingredientTabOptions &&
                ingredientTabOptions[value.key_value] &&
                ingredientTabOptions[value.key_value] != null
                  ? JSON.parse(ingredientTabOptions[value.key_value])
                  : []
              if (loadingTable) {
              } else {
                return (
                  <div className='col-2' key={value.name}>
                    <TabDropdown
                      //name={selectedTabName}
                      name={value.display_name}
                      options={convertToSingleElementArray(options)}
                      onChange={handleTabFormulas}
                      newTabData={handleTabDropdownData}
                      onClose={handleTabMultipleFormulas}
                      onRemove={handleTabMultipleRemoveFormulas}
                    />
                  </div>
                )
              }
            }
          })}
          <div className='col pull-right' style={{ float: 'right' }}>
            <AddMoreRowButton />
          </div>
        </div>
        <div className='row' style={{ marginBottom: '1rem' }}>
          {/* <div style={{ marginBottom: messages.length > 0 ? '1rem' : '0' }}>
            {messages.map((message, index) => (
              <Message
                className='col-12'
                showIcon
                type={message.status}
                closable
              >
                {message.text}
              </Message>
            ))}
          </div> */}
          <div
            style={{
              marginBottom: Object.keys(messages).length > 0 ? '1rem' : '0',
            }}
          >
            {Object.entries(messages).map(([key, message], index) => (
              <Message
                key={key} // Make sure to include a unique key for each message
                className='col-12'
                showIcon
                type={message.status}
                closable
              >
                {message.text}
              </Message>
            ))}
          </div>

          <DndProvider backend={HTML5Backend}>
            <div id='table-wrapper' style={{ width: '100%', height: 'auto' }}>
              <Table
                //height={520}
                data={tableRowsData}
                bordered
                cellBordered
                //defaultExpandAllRows
                rowKey='id'
                autoHeight //The height of the table will be automatically expanded according to the number of data rows, and no vertical scroll bar will appear.
                //fillHeight //Force the height of the table to be equal to the height of its parent container. Cannot be used together with autoHeight.
                virtualized //effectively render large tabular data.
                headerHeight={60}
                rowHeight={60}
                hover
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loadingTable ? loadingTable : loading}
                renderEmpty={() => (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                      width='6%'
                      src='https://i.pinimg.com/originals/ed/03/a8/ed03a8a3babdf60e1f43fc2d0bc0468a.gif'
                      alt='No Herbs available'
                    />
                    <h5>No Herbs Selected</h5>
                  </div>
                )}
              >
                {columns.map((column) => {
                  // console.log(columns,"columns")
                  return (
                    <Column
                      width={column.width}
                      key={column.id}
                      flexGrow={column.flexGrow}
                      //sortable
                      fullText
                      //fixed='right'
                    >
                      <HeaderCell>{column.name}</HeaderCell>
                      <Cell dataKey={column.id}>
                        {(rowData, index) => {
                          if (tableRowsData.length == index + 1) {
                            // console.log(data.length,index,"indexxx",(data.length == index+1))
                            const filteredArray = data.filter(
                              (item) => item.ingredient_id !== ''
                            )
                            let text = ''
                            if (
                              typeof columnTotals[column.id] === 'number' &&
                              (column.key ===
                                'FORMULA_DOSAGE_COLUMN_RAW_PER_DAY' ||
                                column.key ===
                                  'FORMULA_DOSAGE_COLUMN_ENTER_QTY' ||
                                column.key ===
                                  'FORMULA_DOSAGE_COLUMN_SUBTOTAL') &&
                              !isNaN(columnTotals[column.id])
                            ) {
                              if (
                                column.key === 'FORMULA_DOSAGE_COLUMN_SUBTOTAL'
                              ) {
                                text = `${currency}${columnTotals[
                                  column.id
                                ].toFixed(3)}`
                              } else {
                                text = columnTotals[column.id]
                              }
                            }
                            if (
                              column.key ===
                              'FORMULA_DOSAGE_COLUMN_QTY_RECEIVED'
                            ) {
                              text = Math.round(columnTotals[column.id])
                            }
                            if (column.key === 'FORMULA_DOSAGE_COLUMN_PER') {
                              text = Math.round(columnTotals[column.id])
                            }

                            if (
                              column.key === 'FORMULA_DOSAGE_COLUMN_ALL_NAMES'
                            ) {
                              text = filteredArray.length
                            }
                            return (
                              <div style={{ textAlign: 'left' }}>{text}</div>
                            )
                          }
                          // console.log(rowData, "8888888888888888888888")
                          var dataKey = column.key
                          var default_measurment_name = ''
                          var ing_default_measurment_name = ''

                          if (rowData.default_measurment) {
                            const foundOption = Object.values(
                              measurement_options_arr
                            ).find((option) => {
                              return rowData.default_measurment == option['id']
                            })
                            if (foundOption) {
                              default_measurment_name = foundOption.display_name
                            }
                          }
                          if (rowData.ing_default_measurment_id) {
                            const ingfoundOption = Object.values(
                              measurement_options_arr
                            ).find((option) => {
                              return (
                                rowData.ing_default_measurment_id ==
                                option['id']
                              )
                            })
                            if (ingfoundOption) {
                              ing_default_measurment_name =
                                ingfoundOption.display_name
                            }
                          }

                          if (
                            dataKey.includes('_NAME') ||
                            dataKey.includes('_NAMES')
                          ) {
                            var thisNameType = column.id
                            var option = formulaIngredients[thisNameType]
                              ? formulaIngredients[thisNameType]
                              : []
                            return (
                              <>
                                <IngredientSelect
                                  formulaIngredients={option}
                                  thisNameType={thisNameType}
                                  rowData={rowData}
                                  column={column.id}
                                  onUpdate={handleIngredientChange}
                                />
                              </>
                            )
                          } else if (
                            dataKey == 'FORMULA_DOSAGE_COLUMN_ENTER_QTY' ||
                            dataKey == 'FORMULA_DOSAGE_COLUMN_RAW_PER_DAY'
                          ) {
                            return rowData.ingredient_id &&
                              rowData.is_loading == false ? (
                              <InputNumber
                                defaultValue={rowData[column.id]}
                                value={
                                  decimalLength(rowData[column.id]) >
                                  rowData['decimal_place']
                                    ? Number(rowData[column.id]).toFixed(
                                        rowData['decimal_place']
                                      )
                                    : rowData[column.id]
                                }
                                disabled={
                                  rowData.ingredient_id == '' ? true : false
                                }
                                onChange={(value) => {
                                  var thisVal = value == '' ? 0 : value
                                  if (value != rowData[column.id]) {
                                    //value != '' &&
                                    handleIngredientQty(
                                      // rowData["decimal_place"] ? Number(value).toFixed(rowData["decimal_place"]) : value,
                                      value,
                                      rowData.id,
                                      column.id,
                                      rowData,
                                      conversion_type,
                                      dosagesDays
                                    )
                                  }
                                }}
                                onBlur={(value, e) => {
                                  // value = value.target.value
                                  // if (value != '') {
                                  //   addDecimal(
                                  //     value,
                                  //     rowData.id,
                                  //     column.id,
                                  //     rowData,
                                  //     conversion_type,
                                  //     dosagesDays
                                  //   )

                                  // }
                                  handleIngredientPercent()
                                }}
                              />
                            ) : (
                              <></>
                            )
                          } else if (
                            dataKey == 'FORMULA_DOSAGE_COLUMN_HERB_ADD'
                          ) {
                            return rowData.ingredient_id &&
                              rowData.is_loading == false ? (
                              <SelectPicker
                                size='xl'
                                data={HerbTagOptions}
                                placement='auto'
                                defaultValue={rowData[column.id]}
                                onChange={(e) =>
                                  handleIngredientTags(
                                    e,
                                    rowData.id,
                                    column.id,
                                    rowData
                                  )
                                }
                              />
                            ) : (
                              <span></span>
                            )
                          } else if (
                            dataKey == 'FORMULA_DOSAGE_COLUMN_MEASUREMENT'
                          ) {
                            return (
                              <>
                                {ingredient_selection_type === '' ||
                                rowData.ingredient_id === '' ||
                                rowData.is_loading ==
                                  true ? null : ingredient_selection_type ===
                                  'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE' ? (
                                  measurementOptions.this_measurement.map(
                                    (value1, k) => (
                                      <div key={k}>{value1.display_name}</div>
                                    )
                                  )
                                ) : ingredient_selection_type ===
                                  'ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES' ? (
                                  <>
                                    <SelectPicker
                                      size='xl'
                                      data={
                                        measurementOptions &&
                                        measurementOptions.this_measurement
                                          ? measurementOptions.this_measurement
                                          : []
                                      }
                                      placement='auto'
                                      defaultValue={String(rowData[column.id])}
                                      onChange={(e) =>
                                        handleIngredientMeasurment(
                                          e,
                                          rowData.id,
                                          'default_measurement',
                                          rowData,
                                          ingredient_selection_type
                                        )
                                      }
                                      value={
                                        rowData.default_measurment
                                          ? String(rowData.default_measurment)
                                          : String(
                                              measurementOptions.default_measurement
                                            )
                                      }
                                      labelKey={'label_name'}
                                      valueKey={'id'}
                                      cleanable={false}
                                      onClick={() => setIsDropdownOpen(true)}
                                      onClose={() => setIsDropdownOpen(false)}
                                      searchable={false}
                                      renderValue={(value, item) => {
                                        return item.display_name
                                      }}
                                    />
                                    {/*<select
                                      placeholder='Measurement'
                                      value={
                                        rowData.default_measurment
                                          ? rowData.default_measurment
                                          : measurementOptions.default_measurement
                                      }
                                      onChange={(e) => {
                                        handleIngredientMeasurment(
                                          e.target.value,
                                          rowData.id,
                                          'default_measurement',
                                          rowData,
                                          ingredient_selection_type
                                        )
                                      }}
                                    >
                                      {measurementOptions &&
                                        measurementOptions.this_measurement &&
                                        measurementOptions.this_measurement.map(
                                          (option) => (
                                            <option
                                              key={option.name}
                                              value={option.id}
                                            >
                                              {option.display_name}
                                            </option>
                                          )
                                        )}
                                    </select>*/}
                                  </>
                                ) : ingredient_selection_type ===
                                  'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_PER_INGREDIENT' ? (
                                  ((foundOption, this_measurement) => {
                                    foundOption = measurementOptions.find(
                                      (option) =>
                                        rowData.ing_id === option.ingredient_id
                                    )
                                    if (
                                      rowData.ingredient_id !== '' &&
                                      foundOption
                                    ) {
                                      this_measurement =
                                        foundOption.this_measurement
                                          ? foundOption.this_measurement
                                          : []
                                      if (
                                        this_measurement &&
                                        this_measurement !== 0
                                      ) {
                                        return (
                                          <div>
                                            {this_measurement[0].display_name}
                                          </div>
                                        )
                                      }
                                    }
                                    return null
                                  })()
                                ) : ingredient_selection_type ===
                                  'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS' ? (
                                  ((foundOption, this_measurement) => {
                                    foundOption =
                                      measurementOptions &&
                                      measurementOptions.length > 0
                                        ? measurementOptions[0]
                                        : null
                                    if (
                                      rowData.ingredient_id !== '' &&
                                      foundOption
                                    ) {
                                      this_measurement =
                                        foundOption.this_measurement
                                          ? foundOption.this_measurement
                                          : []
                                      if (
                                        this_measurement &&
                                        this_measurement !== 0
                                      ) {
                                        return (
                                          <div>
                                            {this_measurement[0].display_name}
                                          </div>
                                        )
                                      }
                                    }
                                    return null
                                  })()
                                ) : ingredient_selection_type ===
                                  'MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT' ? (
                                  ((foundOption, this_measurement) => {
                                    foundOption = measurementOptions.find(
                                      (option) =>
                                        rowData.ing_id === option.ingredient_id
                                    )
                                    if (
                                      rowData.ingredient_id !== '' &&
                                      foundOption
                                    ) {
                                      this_measurement =
                                        foundOption.this_measurement
                                          ? foundOption.this_measurement
                                          : []
                                      if (
                                        this_measurement &&
                                        this_measurement !== 0
                                      ) {
                                        return (
                                          <>
                                            <SelectPicker
                                              size='xl'
                                              data={this_measurement}
                                              placement='auto'
                                              defaultValue={String(
                                                rowData[column.id]
                                              )}
                                              onChange={(e) =>
                                                handleIngredientMeasurment(
                                                  e,
                                                  rowData.id,
                                                  'default_measurement',
                                                  rowData,
                                                  ingredient_selection_type
                                                )
                                              }
                                              value={
                                                rowData.default_measurment
                                                  ? String(
                                                      rowData.default_measurment
                                                    )
                                                  : String(
                                                      measurementOptions.default_measurement
                                                    )
                                              }
                                              labelKey={'label_name'}
                                              valueKey={'id'}
                                              cleanable={false}
                                              searchable={false}
                                              renderValue={(value, item) => {
                                                return item.display_name
                                              }}
                                            />
                                            {/* <select
                                              placeholder='Measurement'
                                              value={
                                                rowData.default_measurment
                                                  ? rowData.default_measurment
                                                  : measurementOptions.default_measurement
                                              }
                                              onChange={(e) => {
                                                handleIngredientMeasurment(
                                                  e.target.value,
                                                  rowData.id,
                                                  'default_measurement',
                                                  rowData,
                                                  ingredient_selection_type
                                                )
                                              }}
                                            >
                                              {this_measurement.map(
                                                (option) => (
                                                  <option
                                                    key={option.name}
                                                    value={option.id}
                                                  >
                                                    {option.display_name}
                                                  </option>
                                                )
                                              )}
                                            </select> */}
                                          </>
                                        )
                                      }
                                    }
                                    return null
                                  })()
                                ) : null}
                              </>
                            )
                          } else if (
                            dataKey == 'FORMULA_DOSAGE_COLUMN_ACTION'
                          ) {
                            return (
                              <>
                                <RowDrag
                                  className='drag_formula_dosage_column_action'
                                  key={rowData.id}
                                  rowData={rowData}
                                  id={rowData.id}
                                  onDrag={handleDragRow}
                                />

                                <IngredientView
                                  rowData={rowData}
                                  rowId={rowData.id}
                                  column={column.id}
                                  formulaBuilderId={formulaBuilderId}
                                  pracId={pracId}
                                  nameTypes={nameTypes}
                                  selectedIngredients={data}
                                  updateAddedIngredients={
                                    updateAddedSearchIngredients
                                  }
                                  tableColumns={columns}
                                ></IngredientView>
                                <button
                                  row={rowData.id}
                                  type='button'
                                  style={{ backgroundColor: '#fff0' }}
                                  //className='btn btn-danger'
                                  onClick={() => {
                                    handleIngredientDelete(rowData)
                                  }}
                                  className={'delete_' + column.id}
                                >
                                  <TrashIcon
                                    style={{ fontSize: 20, color: 'red' }}
                                  />
                                </button>
                              </>
                            )
                          } else {
                            if (
                              dataKey == 'FORMULA_DOSAGE_COLUMN_PRICE' ||
                              dataKey == 'FORMULA_DOSAGE_COLUMN_SUBTOTAL'
                            ) {
                              return rowData[column.id] == '' ||
                                rowData.ingredient_id == '' ||
                                rowData.is_loading == true
                                ? ''
                                : currency + rowData[column.id]
                            } else if (
                              dataKey == 'FORMULA_DOSAGE_COLUMN_QTY_RECEIVED'
                            ) {
                              // return rowData.ingredient_id === ''
                              //   ? ''
                              //   : rowData[column.id] +
                              //       ' ' +
                              //       default_measurment_name
                              return rowData.ingredient_id === '' ||
                                rowData.is_loading == true ? (
                                ''
                              ) : (
                                <>
                                  {rowData[column.id] +
                                    ' ' +
                                    default_measurment_name}
                                </>
                              )
                              //rowData['default_measurment_name']
                            } else {
                              return rowData[column.id] == '' ||
                                rowData.ingredient_id == '' ||
                                rowData.is_loading == true
                                ? ''
                                : rowData[column.id]
                            }
                          }
                        }}
                      </Cell>
                    </Column>
                  )
                })}
              </Table>
              {/* <Table
                //height={520}
                data={data[0]}
                // bordered
                // cellBordered
                //defaultExpandAllRows
                rowKey='id'
                //The height of the table will be automatically expanded according to the number of data rows, and no vertical scroll bar will appear.
                //fillHeight //Force the height of the table to be equal to the height of its parent container. Cannot be used together with autoHeight.
                virtualized //effectively render large tabular data.
                headerHeight={60}
                // rowHeight={60}
                hover
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                // loading={loading}
                renderEmpty={() => (
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '20px',
                      display: 'none',
                    }}
                  >
                    <img
                      width='6%'
                      src='https://i.pinimg.com/originals/ed/03/a8/ed03a8a3babdf60e1f43fc2d0bc0468a.gif'
                      alt='No Herbs available'
                    />
                    <h5>No Herbs Selected</h5>
                  </div>
                )}
              >
                {columns.map((column, index) => {
                  // console.log(columns,"columns")
                  return (
                    <Column
                      width={column.width}
                      key={column.id}
                      flexGrow={column.flexGrow}
                      //sortable
                      fullText
                      //fixed='right'
                    >
                      <HeaderCell>
                        <TableFooter
                          column={column}
                          index={index}
                          data1={data}
                        />
                      </HeaderCell>
                      <Cell></Cell>
                    </Column>
                  )
                })}
              </Table> */}
            </div>
          </DndProvider>
        </div>
        <AddMoreRowButton />
      </div>
    </>
  )
}
