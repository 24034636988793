import { el } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import {
  Loader,
  Placeholder,
  Message,
  Button,
  Notification,
  useToaster,
  ButtonToolbar,
} from 'rsuite'

export function FormulaSaveButton({
  formulaId = '',
  onchnage,
  pracDetails,
  is_order,
}) {
  const [online, setOnline] = useState(window.navigator.onLine)

  const [allow_checkout, set_allow_checkout] = useState(false)
  const [payment_method, set_payment_method] = useState([])
  const [prac_user_type, set_prac_user_type] = useState('')

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setOnline(window.navigator.onLine)
    }

    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  useEffect(() => {
    if (pracDetails && pracDetails.PRAC_CHECKOUT_CONTROL_SETTING) {
      if (pracDetails.PRAC_CHECKOUT_CONTROL_SETTING == 1) {
        set_allow_checkout(true)
      } else {
        set_allow_checkout(false)
      }
    } else {
      set_allow_checkout(false)
    }

    if (pracDetails && pracDetails.payment_method) {
      set_payment_method(pracDetails.payment_method)
    } else {
      set_payment_method([])
    }
    set_prac_user_type(pracDetails ? pracDetails.prac_user_type : '')
  }, [pracDetails])
  if (online && is_order == 'true') {
    return (
      <div
        align='right'
        className='FormulaBuilderSaveButton'
        style={{ marginTop: '4rem', marginBottom: '2rem' }}
      >
        <Button
          color='cyan'
          title='Press to save Formula'
          appearance='primary'
          onClick={(e) => {
            onchnage('save_order')
          }}
        >
          Save Order
        </Button>
      </div>
    )
  }
  if (online) {
    return (
      <div
        //align='center'
        className='FormulaBuilderSaveButton'
        style={{ marginTop: '4rem', marginBottom: '2rem' }}
      >
        <ButtonToolbar style={{ float: 'right', marginBottom: '4rem' }}>
          {allow_checkout == true && payment_method.length != 0 ? (
            <>
              <Button
                color='blue'
                title='Press to save Formula'
                appearance='primary'
                onClick={(e) => {
                  onchnage('save')
                }}
              >
                Save And Continue
              </Button>
              <Button
                color='green'
                title='Press to Add to Cart Formula'
                appearance='primary'
                onClick={(e) => {
                  onchnage('cart')
                }}
              >
                Add to Cart
              </Button>
            </>
          ) : (
            <>
              {prac_user_type != 'student' && (
                <Button
                  color='cyan'
                  title='Press to save Formula'
                  appearance='primary'
                  onClick={(e) => {
                    onchnage('order')
                  }}
                >
                  Skip Cart & Submit
                </Button>
              )}

              <Button
                color='blue'
                title='Press to save Formula'
                appearance='primary'
                onClick={(e) => {
                  onchnage('save')
                }}
              >
                Save And Continue
              </Button>
              <Button
                color='green'
                title='Press to Add to Cart Formula'
                appearance='primary'
                onClick={(e) => {
                  onchnage('cart')
                }}
              >
                Add to Cart
              </Button>
            </>
          )}
        </ButtonToolbar>
      </div>
    )
  }

  // if offline
  return (
    <div align='center' className='FormulaBuilderSaveButton'>
      <Button
        color='yellow'
        title='You are currently offline now.'
        disabled
        appearance='primary'
        type='submit'
        style={{ marginTop: '4rem', marginBottom: '2rem' }}
      >
        ⚠ Offline
      </Button>
    </div>
  )
}
