import React from 'react'
import './Footer.module.css'

import { Navbar } from 'rsuite'
import OnlineStatusBar from '../../../helpers/common'

const Footer = () => (
  <div className='Footer' data-testid='Footer'>
    <Navbar>
      <Navbar.Brand href='#'>Footer</Navbar.Brand>
      {/* <OnlineStatusBar /> */}
    </Navbar>
  </div>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
