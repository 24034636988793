import React from 'react'
import {
  Drawer,
  ButtonToolbar,
  Button,
  Placeholder,
  SelectPicker,
  Form,
  Schema,
  Panel,
  Message,
  toaster,
  Input,
} from 'rsuite'

const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: 'red' }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
)

function InputForm({
  value,
  onChange = null, // on change option trigger
  error = '',
}) {
  var visibility = value.is_active
  const is_active = visibility == 1 ? '' : 'none'
  const ballStyle = {
    padding: '6px',
    width: value.width != 0 && value.width != '' ? value.width + 'px' : '100%',
    backgroundColor: value.field_bg_color,
    fontWeight: value.field_bold,
    // color:value.field_font_color || 'white',
    color: 'gray',
    fontSize: value.field_font_size,
    marginBottom: value.field_margin_bottom,
    marginLeft: value.field_margin_left,
    marginRight: value.field_margin_right,
    marginTop: value.field_margin_top,
    textDecoration: value.field_underline,
    position: value.position,
    display: is_active,
  }
  const handleSelectChange = (value, field) => {
    //const value = e.target.value
    onChange(value, field)
  }
  const triggerName = value.triggerName
  const Hidden = { display: is_active }
  var requried = value.is_required
  const is_required = requried == 1 ? 'required' : ''

  if (value.is_active != 1) {
    return null // or return undefined;
  }
  return (
    <>
      <div className='row mb-3'>
        <label htmlFor={value.field_name} className='col-sm-3 col-form-label'>
          {value.display_name}{' '}
          {requried == 1 && <span className='required'>*</span>}
        </label>
        <div className='col-sm-9'>
          <Form.Group
            className={error ? 'has-error' : ''}
            style={{ width: '100%' }}
          >
            <Input
              //style={ballStyle}
              type='text'
              name={value.key_value}
              placeholder={value.field_placeholder}
              value={value.selectedValue}
              onChange={(e) => {
                handleSelectChange(e, triggerName)
              }}
              required={is_required}
            />
            {error ? (
              <>
                <ErrorMessage>{error}</ErrorMessage>
              </>
            ) : null}
          </Form.Group>
        </div>
      </div>
    </>
  )
}

export default InputForm
