import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef,
  useCallback,
} from 'react'
//import Form from 'react-bootstrap/Form'
import he from 'he' // Import the HTML entity decoder library

import {
  ApiFormulaBuilderDetails,
  ApiFormulaBuilderDosagesDetails,
} from '../../../services/api/api_formula_builder'

import {
  Checkbox,
  SelectPicker,
  Tooltip,
  Whisper,
  InputNumber,
  InputPicker,
  InputGroup,
  Dropdown,
  Popover,
  Input,
  Form,
} from 'rsuite'
import InfoOutlineIcon from '@rsuite/icons/InfoOutline'
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine'
import { string } from 'yup'

const JSONView = ({ formValue, formError }) => (
  <div className='row' style={{ marginBottom: 10 }}>
    <div class='col-12 json-tree-wrapper rs-panel'>
      <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
        <p class='rs-panel-title'>formValue</p>
      </div>
      <div role='region' class='rs-panel-body'>
        <pre>{JSON.stringify(formValue, null, 2)}</pre>
      </div>
    </div>
    <div class='col-12 json-tree-wrapper rs-panel'>
      <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
        <p class='rs-panel-title'>formError</p>
      </div>
      <div role='region' class='rs-panel-body'>
        <pre>{JSON.stringify(formError, null, 2)}</pre>
      </div>
    </div>
  </div>
)
const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: 'red' }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
)

const decodeEntities = (html) => {
  const doc = new DOMParser().parseFromString(he.decode(html), 'text/html')
  return doc.documentElement.textContent
  return he.decode(doc.documentElement.textContent)
}

export default function FormulaBuilderDosagesSection({
  formulaBuilderId,
  formulaDosagesType,
  activeDosages = null,
  data,
  dosageSettings = [],
  onDosagesSectionChange = null,
  blockDosagesApi = 0,
  numberDays,
  onNumberDaysChange,
  formulaDosagesSetting,
  onchange,
  formulaDeatils,
  dosageKey,
  pracId,
  display_name,
  ApplyFormValidation,
  thisFormData,
  pracDetails,
  measurmentConversions,
  ingredient_selection_type,
  dosageConverting,
  ingredients,
  dosageId,
}) {
  const prac_formula_setting =
    pracDetails && pracDetails.formula_setting
      ? JSON.parse(pracDetails.formula_setting)
      : []

  const current_formual_prac_setting =
    prac_formula_setting && prac_formula_setting[data.name]
      ? prac_formula_setting[data.name]
      : []
  //const [dosageSettings, setdosageSettings] = useState([])
  const [dosageOptions, setdosageOptions] = useState([])

  const [DosagesInputs, setDosagesInputs] = useState([])
  const [OwnInstructions, setOwnInstructions] = useState([])
  const [DosagesCalculation, setDosagesCalculation] = useState([])

  const [dosagesData, setdosagesData] = useState(dosageSettings)
  const [dosagesText, setdosagesText] = useState('')
  const [dosagesQty, setdosagesQty] = useState(0)
  const [dosagesQtyConText, setDosagesQtyConText] = useState('')

  const [dosagesAmount, setDosagesAmount] = useState('')
  const [dosagesMeasurement, setDosagesMeasurement] = useState('')
  const [dosagesDose, setDosagesDose] = useState('')
  const [dosagesDays, setDosagesDays] = useState('')

  const [allowOwnDosagesInstructions, setAllowOwnDosagesInstructions] =
    useState(true)
  const [pracallowOwnDosagesInstructions, setPracAllowOwnDosagesInstructions] =
    useState(true)
  const [ownDosagesInstructions, setOwnDosagesInstructions] = useState('')

  const [usePresetDosageInstructions, setUsePresetDosageInstructions] =
    useState(true)
  const [automaticallyCalculateDosages, setAutomaticallyCalculateDosages] =
    useState(false)
  const [
    allowUserToChangeAutomaticallyCalculateDosages,
    setAllowUserToChangeAutomaticallyCalculateDosages,
  ] = useState(false)

  const [formulaDosagesInput, setFormulaDosagesInput] = useState({})

  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({})
  var requirements = [
    {
      column: 'DOSAGES_TYPE',
      name: 'Dosages Type',
      is_required: 0,
      key: 'DOSAGES_TYPE',
      allow_zero: false,
    },
    {
      column: 'ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS',
      name: DosagesInputs.display_name,
      is_required: 0, //DosagesInputs.is_required,
      key: 'ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS',
      allow_zero: true,
    },
    {
      column: 'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS',
      name: DosagesInputs.display_name,
      is_required: DosagesInputs.is_required,
      key: 'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS',
      allow_zero: false,
    },
    {
      column: 'ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX',
      name: OwnInstructions.display_name,
      is_required: 0, //OwnInstructions.is_required,
      key: 'ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX',
      allow_zero: true,
    },
    {
      column: 'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX',
      name: OwnInstructions.display_name,
      is_required: OwnInstructions.is_required,
      key: 'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX',
      allow_zero: false,
    },
    {
      column: 'DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT',
      name: 'Automatically Calculate Dosages',
      is_required: 0,
      key: 'DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT',
      allow_zero: false,
    },
    {
      column: 'DOSAGE_ALLOW_USER_TO_CHANGE_AUTO_CALCULATE_DOSAGES_AMOUNT',
      name: 'Alow User to Changes Automatically Calculate Dosages',
      is_required: 0,
      key: 'DOSAGE_ALLOW_USER_TO_CHANGE_AUTO_CALCULATE_DOSAGES_AMOUNT',
      allow_zero: false,
    },
  ]

  const measurement_options_arr = data.measurment_type_list
    ? data.measurment_type_list
    : []
  useEffect(() => {
    var dosage_options = data.dosage_options
      ? JSON.parse(data.dosage_options)
      : []
    if (dosageKey) {
      setdosageOptions(dosage_options[dosageKey])
      setDosagesInputs(
        dosage_options[dosageKey]['DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS']
      )
      if (
        dosage_options[dosageKey]['DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS']
          .is_required === '1'
      ) {
        setUsePresetDosageInstructions(true)
      }
      setOwnInstructions(
        dosage_options[dosageKey][
          'DOSAGE_OPTION_DOSAGE_CALCULATION_INSTRUCTIONS_TEXT_BOX'
        ]
      )
      if (
        dosage_options[dosageKey][
          'DOSAGE_OPTION_DOSAGE_CALCULATION_INSTRUCTIONS_TEXT_BOX'
        ].is_required === '1'
      ) {
        setAllowOwnDosagesInstructions(true)
      }

      setDosagesCalculation(
        dosage_options[dosageKey]['DOSAGE_OPTION_DOSAGE_CALCULATION']
      )
    }

    //onNumberDaysChange(formulaDosagesSetting.no_of_days)
    setdosagesData(dosageSettings)
    console.log('dosageSettings',dosagesData, dosageSettings,dosageKey)
    if (formulaDosagesType == 'daily') {
      if (formulaDosagesSetting) {
        console.log(
          'formulaDosagesType',
          formulaDosagesType,
          formulaDosagesSetting
        )
        if (formulaDosagesSetting.automatically_calculate_dosages == 1) {
          setAutomaticallyCalculateDosages(true)
          if (
            formulaDosagesSetting.allow_to_change_automatically_calculate_dosages !=
            1
          ) {
            setAllowUserToChangeAutomaticallyCalculateDosages(true)
          }
          // else {
          //   setAllowUserToChangeAutomaticallyCalculateDosages(false)
          // }
        }
      } else {
        setAutomaticallyCalculateDosages(false)
        setAllowUserToChangeAutomaticallyCalculateDosages(false)
      }
    } else {
      setAutomaticallyCalculateDosages(false)
      setAllowUserToChangeAutomaticallyCalculateDosages(false)
    }
  }, [formulaBuilderId, formulaDosagesType, activeDosages,dosageKey,dosageSettings])
  useEffect(() => {
    console.log(
      'allowUserToChangeAutomaticallyCalculateDosages',
      allowUserToChangeAutomaticallyCalculateDosages
    )
  }, [allowUserToChangeAutomaticallyCalculateDosages])

  // auto calculate Dosages amount only for daily dosages
  useEffect(() => {
    if (
      automaticallyCalculateDosages == true &&
      allowUserToChangeAutomaticallyCalculateDosages == true &&
      [
        'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE',
        'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS',
      ].includes(data.ingredient_selection_type)
    ) {
      var all_ingredients = []
      if (ingredients && ingredients.length != 0) {
        ingredients.map((item) => {
          if (
            item.ingredient_id != '' &&
            item.formula_dosage_column_qty_received
          ) {
            var temp = {
              id: item.ingredient_id,
              qty: Number(item.formula_dosage_column_qty_received) || 0,
              unit: item.default_measurment,
            }
            all_ingredients.push(temp)
          }
        })
      }

      const sumQty = all_ingredients.reduce(
        (total, currentItem) => Number(total) + Number(currentItem.qty),
        0
      )
      var diviedBy = (Number(dosagesDose) || 1) * (Number(numberDays) || 1)
      var final_amount = Number(sumQty / diviedBy).toFixed(3)

      if (Object.values(dosagesData).length !== 0) {
        Object.values(dosagesData).forEach((i) => {
          var thisVal = Number(i.value)
          if (
            i.key_value == 'DOSAGE_INPUT_DOSAGE_AMOUNT' &&
            thisVal != final_amount
          ) {
            console.log(
              'automaticallyCalculateDosages',
              sumQty,
              diviedBy,
              final_amount
            )
            HandelDosagesSettings(final_amount, i.id)
          }
        })
      }
    }
  }, [
    formulaBuilderId,
    numberDays,
    dosagesDose,
    dosagesData,
    formulaDosagesSetting,
    formulaDosagesType,
    allowUserToChangeAutomaticallyCalculateDosages,
    automaticallyCalculateDosages,
    ingredients,
  ])
  // update formula saved data
  useEffect(() => {
    //setdosagesData(dosageSettings)
    if (
      formulaDeatils &&
      formulaDeatils.id &&
      formulaDeatils.formula_t &&
      formulaDeatils.formula_t == dosageId
    ) {
      // console.log(
      //   'formulaDeatils_formula_t',
      //   formulaDeatils.formula_t,
      //   dosageId
      // )
      var dosage_input = formulaDeatils.dosage_input
        ? JSON.parse(formulaDeatils.dosage_input)
        : []
      const dosagesInput = {}

      dosage_input.forEach((item) => {
        dosagesInput[item.id] = item.value
      })
      var updatedDosagesData = []
      Object.values(dosageSettings).map((array, key) => {
        if (
          dosagesInput.length != 0 &&
          dosagesInput[array.id] != '' &&
          dosagesInput[array.id] != undefined
        ) {
          array.value = dosagesInput[array.id]
          if (array.key_value === 'DOSAGE_INPUT_NUMBER_OF_DAYS') {
            onNumberDaysChange(array.value)
          }
        }
        updatedDosagesData.push(array)
      })
      if (updatedDosagesData.length != 0) {
        //console.log('updatedDosagesData', updatedDosagesData)
        setdosagesData(updatedDosagesData)
      }

      var do_not_use_on_label = formulaDeatils.do_not_use_on_label
        ? formulaDeatils.do_not_use_on_label
        : usePresetDosageInstructions
      setUsePresetDosageInstructions(do_not_use_on_label)

      var allow_additional_info_formula =
        formulaDeatils.allow_additional_info_formula
          ? formulaDeatils.allow_additional_info_formula
          : allowOwnDosagesInstructions
      setAllowOwnDosagesInstructions(allow_additional_info_formula)

      var own_instructions = formulaDeatils.additional_info_formula
        ? formulaDeatils.additional_info_formula
        : ownDosagesInstructions
      setOwnDosagesInstructions(own_instructions)
    }

    //updatePreviewText()
  }, [dosageSettings])
  // update Preview text
  useEffect(() => {
    updatePreviewText(dosagesData)
  }, [dosagesData])
  // recurcive update number of days
  useEffect(() => {
    //setdosagesData(dosageSettings)
    setdosagesData((prevDosagesData) => {
      const updatedDosagesData = Object.values(prevDosagesData).map((array) => {
        if (array.key_value === 'DOSAGE_INPUT_NUMBER_OF_DAYS') {
          console.log('updated-numberDays in dosages', array.value, numberDays)
          return {
            ...array,
            value: numberDays,
          }
        }
        return array
      })
      // Perform any additional logic here if needed
      updatePreviewText(updatedDosagesData)
      return updatedDosagesData
    })

    //onNumberDaysChange(numberDays)
  }, [numberDays])
  // send data to parent component
  useEffect(() => {
    onchange(
      usePresetDosageInstructions,
      dosagesData,
      allowOwnDosagesInstructions,
      ownDosagesInstructions,
      dosagesText,
      automaticallyCalculateDosages,
      allowUserToChangeAutomaticallyCalculateDosages
    )
  }, [
    usePresetDosageInstructions,
    dosagesData,
    allowOwnDosagesInstructions,
    ownDosagesInstructions,
    automaticallyCalculateDosages,
    allowUserToChangeAutomaticallyCalculateDosages,
  ])
  // update formulaDosagesSetting
  useEffect(() => {
    var selected_dosages_mode_key = ''
    var all_own_ins = 0
    var own_ins_text = ''
    const {
      is_own_dosage_instructions,
      text_own_dosage_instructions,
      formula_option,
    } = current_formual_prac_setting || {}
    if (formulaDosagesSetting) {
      selected_dosages_mode_key = formulaDosagesSetting.key_value
    }
    var is_own_dosage_instructions_setting = is_own_dosage_instructions
      ? JSON.parse(is_own_dosage_instructions)
      : []
    var text_own_dosage_instructions_setting = text_own_dosage_instructions
      ? JSON.parse(text_own_dosage_instructions)
      : []

    if (
      is_own_dosage_instructions_setting &&
      is_own_dosage_instructions_setting[selected_dosages_mode_key] != '' &&
      is_own_dosage_instructions_setting[selected_dosages_mode_key] != null &&
      is_own_dosage_instructions_setting[selected_dosages_mode_key] != undefined
    ) {
      all_own_ins =
        is_own_dosage_instructions_setting[selected_dosages_mode_key]
      setPracAllowOwnDosagesInstructions(all_own_ins)
    }
    if (
      text_own_dosage_instructions_setting &&
      text_own_dosage_instructions_setting[selected_dosages_mode_key] != '' &&
      text_own_dosage_instructions_setting[selected_dosages_mode_key] != null &&
      text_own_dosage_instructions_setting[selected_dosages_mode_key] !=
        undefined
    ) {
      own_ins_text =
        text_own_dosage_instructions_setting[selected_dosages_mode_key]
      setOwnDosagesInstructions(own_ins_text)
    }
    var prac_formula_option_setting = formula_option
      ? JSON.parse(formula_option)
      : []
    if (
      prac_formula_option_setting &&
      prac_formula_option_setting[selected_dosages_mode_key] != '' &&
      prac_formula_option_setting[selected_dosages_mode_key] != null &&
      prac_formula_option_setting[selected_dosages_mode_key] != undefined &&
      selected_dosages_mode_key == 'FORMULA_DOSAGE_MODE_DAILY'
    ) {
      var selected_option_setting =
        prac_formula_option_setting[selected_dosages_mode_key]
      console.log(
        'selected_option_setting',
        selected_option_setting,
        selected_dosages_mode_key
      )
      if (
        formulaDosagesSetting.automatically_calculate_dosages == 1 &&
        formulaDosagesSetting.allow_to_change_automatically_calculate_dosages ==
          1
      ) {
        const {
          default_bags,
          default_days,
          prac_allow_to_change_automatic_calculattion,
          default_total_qty_received,
        } = selected_option_setting || {}
        if (prac_allow_to_change_automatic_calculattion == 1) {
          setAllowUserToChangeAutomaticallyCalculateDosages(true)
        } else {
          setAllowUserToChangeAutomaticallyCalculateDosages(false)
        }
      } else if (
        formulaDosagesSetting.automatically_calculate_dosages == 1 &&
        formulaDosagesSetting.allow_to_change_automatically_calculate_dosages !=
          1
      ) {
        setAllowUserToChangeAutomaticallyCalculateDosages(true)
      }

      // setTimeout(() => {
      //   //alert(prac_allow_to_change_automatic_calculattion)
      //   setAllowUserToChangeAutomaticallyCalculateDosages(
      //     prac_allow_to_change_automatic_calculattion
      //   )
      // }, 500)
    }
  }, [formulaBuilderId, formulaDosagesSetting, activeDosages])

  const HandelDosagesSettings = (value, field) => {
    setdosagesData((prevDosagesData) => {
      const updatedDosagesData = Object.values(prevDosagesData).map((array) => {
        if (array.id === field) {
          return {
            ...array,
            value: value,
          }
        }
        return array
      })
      // Perform any additional logic here if needed
      updatePreviewText(updatedDosagesData)
      return updatedDosagesData
    })
    setTimeout(() => {
      //updatePreviewText()
    }, 500)
  }

  var finalDosageType = activeDosages ? activeDosages : formulaDosagesType

  // on-change formula type
  const updatePreviewText = (array) => {
    setdosagesText('')
    let currentDosagesText = ''
    if (Object.values(array).length !== 0) {
      Object.values(array).forEach((i) => {
        if (i.drop_type === 'placer') {
          currentDosagesText += i.name + ' '
        } else if (i.drop_type === 'text' || i.drop_type === 'number') {
          //if (i.value != undefined) {
          currentDosagesText += i?.value + ' '
          //}
        } else if (i.drop_type === 'selectBox') {
          //const dosageValues = JSON.parse(i?.dosageValues)
          var dosageValues = i.dosageValues ? JSON.parse(i.dosageValues) : []
          var allowed_option = dosageValues.map((item) => item.name)
          // if (i.default_value != null && i.default_value != 0) {
          //   allowed_option.push(i.default_value)
          // }

          let exist = 0
          //console.log('allowed_option', allowed_option, i.dosageValues)
          measurement_options_arr.forEach((val) => {
            //console.log('search', allowed_option.includes(val.id), i.value)
            if (allowed_option.includes(val.id)) {
              if (val.id == i.value) {
                exist = 1
                currentDosagesText += val.display_name + ' '
              }
            }
          })
          // dosageValues?.forEach((ii) => {
          //   if (ii.id === i.value) {
          //     exist = 1
          //     currentDosagesText += ii.name + ' '
          //   }
          // })

          /*if (exist === 0 && dosageValues && dosageValues.length != 0) {
            var unit_name = dosageValues[0].name
            currentDosagesText += unit_name + ' '
            //HandelDosagesSettings(dosageValues[0].id, i.value)
          }*/
        }
      })
      setdosagesText(currentDosagesText)
    }
    calculateDosage(array)
  }

  const calculateDosage = (array) => {
    //setdosagesText('')

    let DOSAGE_INPUT_MEASUREMENT = ''
    let DOSAGE_INPUT_DOSAGE_AMOUNT = 1
    let DOSAGE_INPUT_DOSES_PER_DAY = 1
    let DOSAGE_INPUT_NUMBER_OF_DAYS = 1

    let DOSAGE_INPUT_MEASUREMENT_CONVERION = 0
    let DOSAGE_INPUT_DEFAULT_MEASUREMENT = ''
    let DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID = ''
    let DOSAGE_INPUT_MEASUREMENT_ID = ''
    let DOSAGE_CONVERION_TEXT = ''

    if (Object.values(array).length !== 0) {
      Object.values(array).forEach((i) => {
        if (i.key_value == 'DOSAGE_INPUT_MEASUREMENT') {
          var selectedUnit = ''

          //var dosageValues = JSON.parse(i?.dosageValues)
          var dosageValues = i.dosageValues ? JSON.parse(i.dosageValues) : []
          var allowed_option = dosageValues.map((item) => item.name)
          var selected_value = dosageValues.find(
            (dosage) => dosage.name == i.value
          )
          var default_value = dosageValues.find(
            (dosage) => dosage.set_default == 1
          )

          if (i.default_value != null && i.default_value != 0) {
            allowed_option.push(i.default_value)
          }
          measurement_options_arr.forEach((val) => {
            if (allowed_option.includes(val.id)) {
              if (val.id == i.value) {
                selectedUnit = val.display_name
                DOSAGE_INPUT_MEASUREMENT_ID = val.id
              }
              if (default_value && default_value.name == val.id) {
                DOSAGE_INPUT_DEFAULT_MEASUREMENT = val.display_name
                DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID = val.id
              }
            }
          })
          DOSAGE_INPUT_MEASUREMENT = selectedUnit
          if (selected_value && selected_value.equal_to) {
            DOSAGE_INPUT_MEASUREMENT_CONVERION = selected_value.equal_to
          }
        }
        if (i.key_value == 'DOSAGE_INPUT_DOSAGE_AMOUNT') {
          DOSAGE_INPUT_DOSAGE_AMOUNT = Number(i.value) || 1
        }
        if (i.key_value == 'DOSAGE_INPUT_DOSES_PER_DAY') {
          DOSAGE_INPUT_DOSES_PER_DAY = Number(i.value) || 1
        }
        if (i.key_value == 'DOSAGE_INPUT_NUMBER_OF_DAYS') {
          DOSAGE_INPUT_NUMBER_OF_DAYS = Number(i.value) || 1
        }
      })
    }
    var finalQty =
      Number(DOSAGE_INPUT_DOSAGE_AMOUNT) *
      Number(DOSAGE_INPUT_DOSES_PER_DAY) *
      Number(DOSAGE_INPUT_NUMBER_OF_DAYS)
    setDosagesAmount(Number(DOSAGE_INPUT_DOSAGE_AMOUNT))
    setDosagesDose(Number(DOSAGE_INPUT_DOSES_PER_DAY))
    setDosagesDays(Number(DOSAGE_INPUT_NUMBER_OF_DAYS))

    //console.log('ingredient_selection_type', ingredient_selection_type)
    if (
      ingredient_selection_type == 'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE'
    ) {
      setDosagesMeasurement(String(DOSAGE_INPUT_DEFAULT_MEASUREMENT)) // dosages input default measurement in admin
      if (DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID != DOSAGE_INPUT_MEASUREMENT_ID) {
        var this_con = DOSAGE_INPUT_MEASUREMENT_CONVERION
          ? Number(DOSAGE_INPUT_MEASUREMENT_CONVERION)
          : 0
        if (Number(this_con)) {
          //console.log('this_con ' + this_con)
          var total = Number(finalQty) * Number(this_con)
          setdosagesQty(Number(total.toFixed(2)))
          DOSAGE_CONVERION_TEXT =
            '1' +
            DOSAGE_INPUT_MEASUREMENT +
            ' converts to ' +
            this_con +
            DOSAGE_INPUT_DEFAULT_MEASUREMENT
        } else {
          let result = []
          var from_unit = DOSAGE_INPUT_MEASUREMENT_ID
          var to_unit = DOSAGE_INPUT_DEFAULT_MEASUREMENT_ID

          if (measurmentConversions.length != 0) {
            for (const key in measurmentConversions) {
              const conversion = measurmentConversions[key]
              if (
                (conversion.from_unit == from_unit &&
                  conversion.to_unit == to_unit) ||
                (conversion.from_unit == to_unit &&
                  conversion.to_unit == from_unit)
              ) {
                result = conversion
                break
              }
            }
          }
          if (result.length != 0 && result != null) {
            if (
              result != null &&
              result.from_unit == from_unit &&
              result.to_unit == to_unit
            ) {
              var con =
                parseFloat(result.from_value) / parseFloat(result.to_value)
              //console.log('matchCon', con, result)
              var total = Number(finalQty) * Number(con)
              setdosagesQty(Number(total.toFixed(2)))
              DOSAGE_CONVERION_TEXT =
                result.from_value +
                DOSAGE_INPUT_MEASUREMENT +
                ' converts to ' +
                result.to_value +
                DOSAGE_INPUT_DEFAULT_MEASUREMENT
            } else if (
              result != null &&
              result.from_unit == to_unit &&
              result.to_unit == from_unit
            ) {
              var con =
                parseFloat(result.to_value) / parseFloat(result.from_value)
              // console.log('matchCon2', con, result)
              var total = Number(finalQty) * Number(con)
              setdosagesQty(Number(total.toFixed(2)))
              DOSAGE_CONVERION_TEXT =
                result.to_value +
                DOSAGE_INPUT_MEASUREMENT +
                ' converts to ' +
                result.from_value +
                DOSAGE_INPUT_DEFAULT_MEASUREMENT
            }
          } else {
            DOSAGE_CONVERION_TEXT =
              '1' +
              DOSAGE_INPUT_MEASUREMENT +
              ' converts to 1' +
              DOSAGE_INPUT_DEFAULT_MEASUREMENT +
              '<small> ( No Conversion Found )</small>'
            setdosagesQty(Number(finalQty.toFixed(2)))
          }
        }
      } else {
        setdosagesQty(Number(finalQty.toFixed(2)))
      }
      setDosagesQtyConText(DOSAGE_CONVERION_TEXT)
    } else {
      setDosagesMeasurement(String(DOSAGE_INPUT_MEASUREMENT)) // dosages input selected measurement
      setdosagesQty(Number(finalQty.toFixed(2)))
      setDosagesQtyConText('')
    }
  }
  const disableKeys = (event) => {
    // Allow numbers, dots, and specific key codes for control keys
    if (
      !(event.key === '.' || (event.key >= '0' && event.key <= '9')) &&
      ![8, 9, 37, 39, 46].includes(event.keyCode)
    ) {
      event.preventDefault()
    }
  }
  useEffect(() => {
    formValidation()
  }, [
    usePresetDosageInstructions,
    dosagesData,
    allowOwnDosagesInstructions,
    ownDosagesInstructions,
    numberDays,
    dosageSettings,
    formulaBuilderId,
  ])
  // apply form validation action
  useEffect(() => {
    if (ApplyFormValidation == true) {
      //formValidation()
    }
  }, [ApplyFormValidation])
  // dosages convertion
  useEffect(() => {
    if (dosageConverting == true) {
      DosagesConverting()
    }
  }, [dosageConverting])

  const formValidation = () => {
    formValue['DOSAGES_TYPE'] = finalDosageType
    formValue['ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'] =
      usePresetDosageInstructions
    formValue['DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'] = dosagesData
      ? dosagesData
      : []
    formValue['ALLOW_DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX'] =
      allowOwnDosagesInstructions
    formValue['DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX'] =
      ownDosagesInstructions
    formValue['DOSAGE_AUTO_CALCULATE_DOSAGES_AMOUNT'] =
      automaticallyCalculateDosages
    formValue['DOSAGE_ALLOW_USER_TO_CHANGE_AUTO_CALCULATE_DOSAGES_AMOUNT'] =
      allowUserToChangeAutomaticallyCalculateDosages
    //console.log('formValue', formValue)
    var Final_array = []

    Object.values(requirements).forEach((field) => {
      const ikey = field.key
      var i = field
      var isRequired = field.is_required == 1
      var fieldValue = formValue[ikey]
      if (
        (ikey == 'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS' ||
          ikey == 'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX') &&
        formValue['ALLOW_' + ikey] == true
      ) {
        isRequired = 1
      }

      if (isRequired == 1) {
        if (ikey == 'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS') {
          /*console.log(
            'optionSectionValid',
            ikey,
            fieldValue,
            typeof fieldValue,
            isRequired,
            fieldValue.length
          )*/
          if (typeof fieldValue == 'object' && fieldValue.length == 0) {
            if (!formError[ikey]) {
              formError[ikey] = {} // Initialize if not defined
            }
            formError[ikey]['error'] = field.name + ' is required ' // + fieldValue.length // important
          } else if (typeof fieldValue == 'object' && fieldValue.length != 0) {
            if (!formError[ikey]) {
              formError[ikey] = {} // Initialize if not defined
            } else {
              formError[ikey] = {}
            }
            formError[ikey]['error'] = ''
            //console.log('fieldValue', fieldValue)
            fieldValue.forEach((item) => {
              //console.log('dosageItem', item)
              if (item.drop_type != 'placer') {
                const thisVal = String(item.value).trim()
                formError[ikey][item.id] =
                  thisVal == '' ||
                  thisVal == 0 ||
                  thisVal == null ||
                  thisVal == undefined
                    ? 'This field is required'
                    : ''
              }
            })
          }
        } else {
          if (i.allow_zero == true) {
            if (
              String(formValue[ikey]).trim() == '' ||
              formValue[ikey] == null ||
              //formValue[i.key] == 0 ||
              //!formValue[i.key].includes(['0', '1']) ||
              formValue[ikey] == undefined
            ) {
              //console.log(i.key, formValue[i.key], 1)
              formError[ikey] = 'This field is required'
            } else {
              formError[ikey] = ''
            }
          } else {
            if (
              String(formValue[ikey]).trim() == '' ||
              formValue[ikey] == null ||
              formValue[ikey] == 0 ||
              formValue[ikey] == undefined
            ) {
              formError[ikey] = 'This field is required'
            } else {
              formError[ikey] = ''
            }
          }
        }
      } else {
        formError[ikey] = ''
      }

      const temp = {
        ...field,
        value: formValue[ikey],
        error: formError[ikey],
      }
      Final_array.push(temp)
      //Final_array[ikey] = temp
    })

    thisFormData(Final_array, 'FORMULAS_DOSAGES')
  }

  const DosagesConverting = () => {
    console.log(
      'DosagesConverting',
      formulaDosagesType,
      activeDosages,
      formulaDosagesSetting
    )
    //numberDays
  }

  //console.log('dosagesData', dosagesData)
  if (DosagesInputs.is_active == '1' || OwnInstructions.is_active == '1') {
    return (
      <>
        {/* <pre>{JSON.stringify(formulaDosagesSetting, null, 2)}</pre> */}
        <div className='FormulaBuilderDosagesSection'>
          <h2 className='title mt-5'>{display_name}</h2>

          <div className='row' style={{ marginBottom: '1rem' }}>
            <div className='col-8'>
              <div className='row'>
                {DosagesInputs.is_active === '1' && dosagesData.length > 0 && (
                  <>
                    {/*  label  */}
                    <div className='col-12' style={{ paddingBottom: '1rem' }}>
                      <div className='checkBox'>
                        {DosagesInputs.is_required == 0 ? (
                          <input
                            type='checkbox'
                            value={usePresetDosageInstructions}
                            checked={usePresetDosageInstructions}
                            name='usePresetDosageInstructions'
                            onChange={(e) =>
                              setUsePresetDosageInstructions(e.target.checked)
                            }
                          />
                        ) : null}
                        <span>
                          {DosagesInputs.is_required == 0 && <>&nbsp;&nbsp;</>}
                          Use the Preset Dosage Instructions
                        </span>
                        {formError[
                          'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'
                        ] && ApplyFormValidation ? (
                          <>
                            <ErrorMessage>
                              {
                                formError[
                                  'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'
                                ]['error']
                              }
                            </ErrorMessage>
                          </>
                        ) : null}
                      </div>
                    </div>

                    {formulaDosagesType == 'daily' &&
                      formulaDosagesSetting &&
                      formulaDosagesSetting.automatically_calculate_dosages ==
                        1 &&
                      [
                        'ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE',
                        'MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS',
                      ].includes(data.ingredient_selection_type) && (
                        <>
                          {formulaDosagesSetting.allow_to_change_automatically_calculate_dosages ==
                            1 && (
                            <>
                              <div className='checkBox'>
                                <input
                                  type='checkbox'
                                  value={
                                    allowUserToChangeAutomaticallyCalculateDosages
                                  }
                                  checked={
                                    allowUserToChangeAutomaticallyCalculateDosages
                                  }
                                  name='automaticallyCalculateDosages'
                                  onChange={(e) =>
                                    setAllowUserToChangeAutomaticallyCalculateDosages(
                                      e.target.checked
                                    )
                                  }
                                />
                                <span style={{ paddingLeft: '6px' }}>
                                  Automatically Calculate Dosages
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    {/*{formulaDosagesType == 'daily' &&
                      formulaDosagesSetting &&
                      formulaDosagesSetting.automatically_calculate_dosages ==
                        1 &&
                      formulaDosagesSetting.allow_to_change_automatically_calculate_dosages ==
                        1 && (
                        <>
                          <div className='checkBox'>
                            <input
                              type='checkbox'
                              value={
                                allowUserToChangeAutomaticallyCalculateDosages
                              }
                              checked={
                                allowUserToChangeAutomaticallyCalculateDosages
                              }
                              name='automaticallyCalculateDosages'
                              onChange={(e) =>
                                setAllowUserToChangeAutomaticallyCalculateDosages(
                                  e.target.checked
                                )
                              }
                            />
                            <span style={{ paddingLeft: '6px' }}>
                              Automatically Calculate Dosages
                            </span>
                          </div>
                        </>
                      )}*/}
                    {/*'automaticallyCalculateDosages: ' +
                      automaticallyCalculateDosages*/}
                    {/*  Dosages Input  */}
                    {usePresetDosageInstructions &&
                      Object.values(dosagesData).map((i, k) => {
                        //console.log('dosagesInput', i, k)
                        if (i.drop_type === 'placer') {
                          return (
                            <div
                              className='col placer'
                              value={i.values || ''}
                              name={i.name}
                              placeholder={i.name}
                              title={i.name}
                            >
                              <p
                                className='color'
                                style={{ marginTop: '2rem' }}
                              >
                                {' '}
                                {decodeEntities(i.name)}{' '}
                              </p>
                            </div>
                          )
                        }
                        if (i.drop_type === 'text') {
                          return (
                            <>
                              <div className='col '>
                                <span
                                  className='text'
                                  style={{ color: '#b3b3b3' }}
                                >
                                  {i.name}
                                </span>
                                {/* <pre>{JSON.stringify(i, null, 2)}</pre> */}
                                <InputGroup>
                                  {i.drop_type == 'number' ? (
                                    <Input
                                      className={'custom-input-number'}
                                      placeholder={i.name}
                                      value={String(i.value)}
                                      onChange={(e, event) => {
                                        HandelDosagesSettings(e, i.id)
                                      }}
                                      onKeyPress={disableKeys}
                                      step={0.01}
                                    />
                                  ) : (
                                    <Input
                                      className={'custom-input-number'}
                                      placeholder={i.name}
                                      value={String(i.value)}
                                      onChange={(e, event) => {
                                        HandelDosagesSettings(e, i.id)
                                      }}
                                      //onKeyPress={disableKeys}
                                      step={0.01}
                                    />
                                  )}

                                  {dosageValues && dosageValues.length != 0 && (
                                    <InputGroup.Button
                                      style={{ backgroundColor: 'unset' }}
                                    >
                                      <Whisper
                                        className='rs-picker-toggle-caret'
                                        placement='bottomStart'
                                        trigger='click'
                                        //speaker={numberContainerDropDown}
                                        speaker={(props, ref) => {
                                          const {
                                            className,
                                            left,
                                            top,
                                            onClose,
                                          } = props
                                          return (
                                            <Popover
                                              ref={ref}
                                              className={className}
                                              style={{ left, top }}
                                              full
                                            >
                                              <Dropdown.Menu
                                                onSelect={(value, event) => {
                                                  HandelDosagesSettings(
                                                    String(value),
                                                    i.id
                                                  )
                                                }}
                                                activeKey={String(i.value)}
                                              >
                                                {dosageValues.map((option) => (
                                                  <Dropdown.Item
                                                    key={option.position}
                                                    eventKey={option.name}
                                                    // onClick={HandelDosagesSettings(
                                                    //   option.name,
                                                    //   i.id
                                                    // )}
                                                  >
                                                    {option.name}
                                                  </Dropdown.Item>
                                                ))}
                                              </Dropdown.Menu>
                                            </Popover>
                                          )
                                        }}
                                      >
                                        <ArrowDownLineIcon
                                        //style={{ fontSize: '25px' }}
                                        />
                                      </Whisper>
                                    </InputGroup.Button>
                                  )}
                                </InputGroup>
                                {formError[
                                  'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'
                                ] && ApplyFormValidation ? (
                                  <>
                                    <ErrorMessage>
                                      {
                                        formError[
                                          'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'
                                        ][i.id]
                                      }
                                    </ErrorMessage>
                                  </>
                                ) : null}
                              </div>
                            </>
                          )
                        }
                        if (i.drop_type === 'number') {
                          var dosageValues = i.dosageValues
                            ? JSON.parse(i.dosageValues)
                            : []
                          return (
                            <div className='col '>
                              <div className='form-text'>
                                <span
                                  className='text'
                                  style={{ color: '#b3b3b3' }}
                                >
                                  {i.name}
                                </span>
                              </div>
                              {/* <pre>{JSON.stringify(i, null, 2)}</pre> */}
                              <InputGroup>
                                <InputNumber
                                  className={'custom-input-number'}
                                  placeholder={i.name}
                                  value={String(i.value)}
                                  onChange={(e, event) => {
                                    HandelDosagesSettings(e, i.id)
                                    if (
                                      i.key_value ===
                                      'DOSAGE_INPUT_NUMBER_OF_DAYS'
                                    ) {
                                      //onNumberDaysChange(e)
                                    }
                                  }}
                                  onBlur={() => {
                                    if (
                                      i.key_value ===
                                      'DOSAGE_INPUT_NUMBER_OF_DAYS'
                                    ) {
                                      onNumberDaysChange(String(i.value))
                                    }
                                  }}
                                  onKeyPress={disableKeys}
                                  step={0.01}
                                />
                                {dosageValues && dosageValues.length != 0 && (
                                  <InputGroup.Button
                                    style={{ backgroundColor: 'unset' }}
                                  >
                                    <Whisper
                                      className='rs-picker-toggle-caret'
                                      placement='bottomStart'
                                      trigger='click'
                                      //speaker={numberContainerDropDown}
                                      speaker={(props, ref) => {
                                        const {
                                          className,
                                          left,
                                          top,
                                          onClose,
                                        } = props
                                        return (
                                          <Popover
                                            ref={ref}
                                            className={className}
                                            style={{ left, top }}
                                            full
                                          >
                                            <Dropdown.Menu
                                              onSelect={(value, event) => {
                                                HandelDosagesSettings(
                                                  String(value),
                                                  i.id
                                                )
                                              }}
                                              activeKey={String(i.value)}
                                            >
                                              {dosageValues.map((option) => (
                                                <Dropdown.Item
                                                  key={option.position}
                                                  eventKey={option.name}
                                                  // onClick={HandelDosagesSettings(
                                                  //   option.name,
                                                  //   i.id
                                                  // )}
                                                >
                                                  {option.name}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Popover>
                                        )
                                      }}
                                    >
                                      <ArrowDownLineIcon
                                      //style={{ fontSize: '25px' }}
                                      />
                                    </Whisper>
                                  </InputGroup.Button>
                                )}
                              </InputGroup>
                              {formError[
                                'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'
                              ] && ApplyFormValidation ? (
                                <>
                                  <ErrorMessage>
                                    {
                                      formError[
                                        'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'
                                      ][i.id]
                                    }
                                  </ErrorMessage>
                                </>
                              ) : null}
                            </div>
                          )
                        }
                        if (i.drop_type === 'selectBox') {
                          var dosageValues = i.dosageValues
                            ? JSON.parse(i.dosageValues)
                            : []
                          var allowed_option = dosageValues.map(
                            (item) => item.name
                          )
                          if (i.default_value != null && i.default_value != 0) {
                            allowed_option.push(i.default_value)
                          }

                          var option = []
                          measurement_options_arr.forEach((val) => {
                            if (allowed_option.includes(val.id)) {
                              option.push({
                                value: val.id,
                                label: val.display_name,
                              })
                            }
                          })
                          var selected =
                            i.value != '' &&
                            i.value != undefined &&
                            i.value != null
                              ? String(i.value)
                              : String(i.default_value)

                          return (
                            <div className='col'>
                              {/* <pre>{JSON.stringify(i.id, null, 2)}</pre> */}
                              <div className='form-text'>
                                <span
                                  className='text'
                                  style={{ color: '#b3b3b3' }}
                                >
                                  {i.name}
                                </span>
                              </div>
                              {/* {console.log(String(i.value), 'check', option)} */}
                              <SelectPicker
                                placeholder={i.name}
                                defaultValue={String(selected)}
                                searchable={false}
                                onChange={(e, event) => {
                                  HandelDosagesSettings(e, i.id)
                                }}
                                style={{ width: '100%' }}
                                cleanable={false}
                                value={String(selected)}
                                data={option}
                              />
                              {formError[
                                'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'
                              ] && ApplyFormValidation ? (
                                <>
                                  <ErrorMessage>
                                    {
                                      formError[
                                        'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_INPUTS'
                                      ][i.id]
                                    }
                                  </ErrorMessage>
                                </>
                              ) : null}
                            </div>
                          )
                        }
                      })}
                    {/*  Dosages Input preview  */}
                    {usePresetDosageInstructions &&
                      DosagesInputs &&
                      DosagesInputs.is_active == 1 &&
                      Object.values(dosagesData).length !== 0 && (
                        <div
                          className='col-12'
                          style={{
                            // backgroundColor: 'white',
                            // padding: '1rem',
                            // textAlign: 'left',
                            // border: '1px solid black',
                            // marginLeft: '0.8rem',
                            // borderRadius: '6px',
                            marginTop: '1rem',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(dosagesText),
                          }}
                        ></div>
                      )}
                    {usePresetDosageInstructions &&
                      DosagesCalculation &&
                      DosagesCalculation.is_active == 1 &&
                      Object.values(dosagesData).length !== 0 && (
                        <div
                          className='col-12'
                          style={{
                            // backgroundColor: 'white',
                            // padding: '1rem',
                            // textAlign: 'left',
                            // border: '1px solid black',
                            // marginLeft: '0.8rem',
                            // borderRadius: '6px',
                            marginTop: '1rem',
                            marginBottom: '1rem',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(
                              'You require a total of: ' +
                                dosagesQty +
                                ' ' +
                                dosagesMeasurement +
                                '<br/>' +
                                dosagesQtyConText
                            ),
                          }}
                        ></div>
                      )}
                  </>
                )}

                {/* Write your Own Instructions*/}
                {OwnInstructions &&
                  OwnInstructions.is_active == 1 &&
                  pracallowOwnDosagesInstructions == 1 && (
                    <>
                      <div className='col-12' style={{ paddingBottom: '1rem' }}>
                        <div className='checkBox mt-2'>
                          {OwnInstructions.is_required == 0 ? (
                            <input
                              type='checkbox'
                              value={allowOwnDosagesInstructions}
                              checked={allowOwnDosagesInstructions}
                              name='ownDosagesInstructions'
                              onChange={(e) =>
                                setAllowOwnDosagesInstructions(e.target.checked)
                              }
                            />
                          ) : null}

                          <span>
                            {OwnInstructions.is_required == 0 && (
                              <>&nbsp;&nbsp;</>
                            )}
                            Write your Own Instructions
                          </span>
                        </div>
                      </div>

                      {allowOwnDosagesInstructions && (
                        <>
                          <textarea
                            value={decodeEntities(ownDosagesInstructions)}
                            className=''
                            onChange={(e) =>
                              setOwnDosagesInstructions(e.target.value)
                            }
                            style={{
                              marginLeft: '0.8rem',
                              borderRadius: '6px',
                            }}
                            rows={5}
                          ></textarea>
                          {formError[
                            'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX'
                          ] && ApplyFormValidation ? (
                            <>
                              <ErrorMessage>
                                {
                                  formError[
                                    'DOSAGE_OPTION_DOSAGE_INSTRUCTIONS_TEXT_BOX'
                                  ]
                                }
                              </ErrorMessage>
                            </>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                {/* end */}
              </div>
            </div>
            <div className='col-6'>
              {/* <JSONView formValue={formValue} formError={formError} /> */}
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return <></>
  }
}
