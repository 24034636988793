import {
    PERCENTAGE_ISSUE_FAILED,
    PERCENTAGE_ISSUE_SUCCESS,
    PERCENTAGE_ISSUE_REQUEST,
} from "./percentageIssue.state";


const Model = {
    data: []
}

export const PercentageReducer = (state = Model, action) => {
    switch (action.type) {
        case PERCENTAGE_ISSUE_REQUEST: return {
            ...state,
            data: []
        }

        case PERCENTAGE_ISSUE_SUCCESS: return {
            ...state,
            data: action.payload
        }
        case PERCENTAGE_ISSUE_FAILED: return {
            ...state,
            data: []
        }

        default: return state
    }
}
