//import Form from 'react-bootstrap/Form'
import React, { useEffect, useState } from 'react'
import PatientAddBtn from '../components/FormulaBuilder/helpers/PatientAddBtn'

import he from "he";
import {format} from "date-fns";
import { Form, Placeholder, SelectPicker } from 'rsuite'

export function formatPrice(price) {
  return `$${price.toFixed(2)}`
}

export function capitalizeNew(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function Loader() {
  return <div>loading....</div>
}

export const OptionNo = () => {
  return <option value='0'>No</option>
}
export const OptionYes = () => {
  return <option value='1'>Yes</option>
}

export const OptionYesNOHtml = (param = null) => {
  return (
    <>
      <option value='1'>Yes</option>
      <option value='0'>No</option>
    </>
  )
}

export const OptionYesNO = () => {
  return [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },
  ]
}

export const SelectInput = ({
  value, // select box details
  selectedValue, // selected option value
  options, // all options array
  hideEmptyOption, // hide option which we declear to choose label like Chosse Patients
  onChange = null, // on change option trigger
  patientBtn,
}) => {
  var requried = value.is_required
  const is_required = requried == 1 ? 'required' : ''
  var visibility = value.is_active
  const is_active = visibility == 1 ? 'block' : 'none'
  var chooseOption =
    hideEmptyOption == 1 ? '' : "<option value=''>Choose ...</option>"

  const ballStyle = {
    //padding: '6px',
    width: value.width != 0 && value.width != '' ? value.width + 'px' : '100%',
    backgroundColor: value.field_bg_color,
    fontWeight: value.field_bold,
    color: value.field_font_color != '' ? value.field_font_color : 'gray',
    fontSize:
      value.field_font_size != '' && value.field_font_size != 0
        ? value.field_font_size
        : '14px',
    marginBottom: value.field_margin_bottom,
    marginLeft: value.field_margin_left,
    marginRight: value.field_margin_right,
    marginTop: value.field_margin_top,
    textDecoration: value.field_underline,
    position: value.position,
    display: is_active,
    maxWidth: '100%',
    // maxHeight: '0'
  }
  var optionValue = value.optionsValueField
  var optionLabel = value.optionsLabelField

  const handleSelectChange = (e, field) => {
    const value = e //.target.value
    onChange(value, field)
  }

  const triggerName = value.triggerName

  var searchable =
    value.key_value == 'FORMULA_SECTION_PATIENT_NAME' ? true : false
  var thisRowStyle =
    value.key_value == 'FORMULA_SECTION_PATIENT_NAME'
      ? 'display:inline-flex;'
      : ''
  if (value.is_active != 1) {
    return ''
  }
  const data = Object.values(options)
    ? Object.values(options).map((item) => ({
        label: item[optionLabel],
        value: item[optionValue],
      }))
    : []
  // sort patient list data
  if (value.key_value === 'FORMULA_SECTION_PATIENT_NAME') {
    data.sort((a, b) => {
      // Assuming label values are strings
      return a.label.localeCompare(b.label)
    })
  }
  return (
    <>
      <div class='row mb-3'>
        <label for='' class='col-sm-3 col-form-label'>
          {value.display_name}{' '}
          {requried == 1 && <span class='required'>*</span>}
        </label>
        <div class='col-sm-9' style={{ display: 'inline-flex' }}>
          {/* <pre>{JSON.stringify([optionLabel, optionValue], null, 2)}</pre> */}
          <SelectPicker
            //className='form-control'
            data={data}
            //style={ballStyle}
            placeholder={
              value.field_placeholder != ''
                ? value.field_placeholder
                : 'Choose..'
            }
            defaultValue={selectedValue}
            searchable={searchable}
            onChange={(e) => {
              handleSelectChange(e, triggerName)
            }}
            style={{ width: '100%' }}
            name={value.field_name}
            cleanable={false}
          />

          {value.key_value === 'FORMULA_SECTION_PATIENT_NAME'
            ? patientBtn &&
              patientBtn.length !== 0 && <PatientAddBtn value={patientBtn} />
            : ''}
        </div>
      </div>
    </>
  )
}

export default function OnlineStatusBar() {
  const [isOnline, setIsOnline] = useState(true)
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true)
    }
    function handleOffline() {
      setIsOnline(false)
    }
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    // return () => {
    //   window.removeEventListener('online', handleOnline)
    //   window.removeEventListener('offline', handleOffline)
    // }
  }, [])

  return (
    <span className='onlineStatusBar rs-navbar-brand'>
      {isOnline === true ? '✅ Online' : '❌ Offline'}
    </span>
  )
}

export const InputWithOptions = () => {
  const [selectedOption, setSelectedOption] = useState('')
  const [customValue, setCustomValue] = useState('')

  const handleOptionChange = (event) => {
    const value = event.target.value
    setSelectedOption(value)

    // Clear the custom value when an option is selected
    if (value !== 'custom') {
      setCustomValue('')
    }
  }

  const handleInputChange = (event) => {
    setCustomValue(event.target.value)
    setSelectedOption('custom')
  }

  const divStyle = {
    display: 'inline-flex',
    width: '100%',
  }

  return (
    <div style={divStyle}>
      <input
        type='text'
        id='myInput'
        list='options'
        placeholder='Enter a value'
      />
      <datalist id='options'>
        <option value='Option 1' />
        <option value='Option 2' />
        <option value='Option 3' />
        <option value='Option 4' />
      </datalist>
      {/* <input
        id='inputWithOptions'
        type='text'
        className='form-control'
        value={selectedOption === 'custom' ? customValue : selectedOption}
        onChange={handleInputChange}
        // disabled={selectedOption !== 'custom'}
      />

      <select
        className='form-select'
        style={{ width: '10px' }}
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <option value=''>-- Select --</option>
        <option value='option1'>Option 1</option>
        <option value='option2'>Option 2</option>
        <option value='option3'>Option 3</option>
        <option value='custom'>Custom</option>
      </select> */}
    </div>
  )
}


export const strEncode = (html) => {
  const doc = new DOMParser().parseFromString(he.decode(html), 'text/html')
  return doc.documentElement.textContent
  return he.decode(doc.documentElement.textContent)
}

export const capitalizeWords = (str) => {
  if (str === null || str === undefined || str === '' || typeof str === 'number') {
    // Return the original value if it's null, undefined, blank, or numeric
    return str;
  }
  return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
}

export const JSONView = ({ formValue, formError }) => (
    <div className='row' style={{ marginBottom: 10 }}>
      <div class='col-4 json-tree-wrapper rs-panel'>
        <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
          <p class='rs-panel-title'>formValue</p>
        </div>
        <div role='region' class='rs-panel-body'>
          <pre>{JSON.stringify(formValue, null, 2)}</pre>
        </div>
      </div>
      <div class='col-8 json-tree-wrapper rs-panel'>
        <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
          <p class='rs-panel-title'>formError</p>
        </div>
        <div role='region' class='rs-panel-body'>
          <pre>{JSON.stringify(formError, null, 2)}</pre>
        </div>
      </div>
    </div>
)

export const ErrorMessage = ({ children }) => (
    <>
      <Form.HelpText style={{ color: 'red' }}>{children}</Form.HelpText>
      {/* <span style={{ color: 'red' }}>{children}</span> */}
    </>
)

export const formatDate = (pattern) => {
  return format(new Date(), pattern)
}