import React from 'react'
import PropTypes from 'prop-types'
import styles from './Header.module.css'

import { Navbar, Nav } from 'rsuite'
import CogIcon from '@rsuite/icons/legacy/Cog'

const Header = () => (
  <div className={styles.Header} data-testid='Header'>
    <Navbar>
      <Navbar.Brand href='#'>Formula Builder</Navbar.Brand>

      <Nav pullRight>
        <Nav.Item icon={<CogIcon />}>Formula Settings</Nav.Item>
      </Nav>
    </Navbar>
  </div>
)

Header.propTypes = {}

Header.defaultProps = {}

export default Header
